import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router, ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, combineLatestWith } from 'rxjs';
import { IBasin } from 'src/app/models/osdu/basin.model';
import { IField } from 'src/app/models/osdu/field.model';
import { IAppState } from 'src/app/store/reducers';
import { IBasinsCacheState } from 'src/app/store/reducers/fetch-basins.reducer';
import { IFieldsCacheState } from 'src/app/store/reducers/fetch-fields.reducer';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SaoOptionsComponent } from '../shared/sao-options/sao-options.component';
import { SaoRecordService } from 'src/app/services/sao-record.service';
import { GenericDialogService } from '../../generic-dialog/generic-dialog.service';
import { EditServiceEvent } from 'src/app/services/edit-service-event.service';
import { ISubFieldsCacheState } from 'src/app/store/reducers/fetch-sub-fields.reducer';
import { IAssignedSaoActivity, ISurevillanceData, ISurevillanceRecord } from 'src/app/models/osdu/sureveillance-record.model';
import { ISurveillanceDataState } from 'src/app/store/reducers/fetch-surveillance-data-reducer';
import { PayloadDataService } from 'src/app/services/payload-data.service';
import { IUpdateRecordPayload } from 'src/app/models/payloads/update-record-payload.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'sao-edit-record',
  templateUrl: './edit-record.component.html',
  styleUrls: ['./edit-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None

})
export class EditRecordComponent implements OnInit, AfterViewInit {

  private subscriptions: Subscription[] = [];
  private saoRecordsLoading: boolean = false;
  private saoOptionsLoading: boolean = false;
  private basinsLoading: boolean = false;
  private fieldsLoading: boolean = false;
  submitLoading: boolean = false;

  public expanded: boolean = false;

  allSaoRecords: ISurevillanceRecord[] = [];
  private saoRecordsCacheState$: Observable<ISurveillanceDataState>;

  completedActivitiesDataSource: MatTableDataSource<IAssignedSaoActivity> = new MatTableDataSource<IAssignedSaoActivity>();
  plannedActivitiesDataSource: MatTableDataSource<IAssignedSaoActivity> = new MatTableDataSource<IAssignedSaoActivity>();

  basinRecords: IBasin[] = [];
  submitting: boolean = false;
  private basinsCacheState$: Observable<IBasinsCacheState>;

  //field == dev area
  fieldOsduRecords: IField[] = [];
  private fieldsCacheState$: Observable<IFieldsCacheState>;
  private subFieldsCacheState$: Observable<ISubFieldsCacheState>;
  devAreaDropdownList: IField[] = [];

  //sub Field == sub dev area
  subFieldOsduRecords: IField[] = [];
  subDevAreaDropdownList: IField[] = [];
  subFields = new FormControl();

  options = new FormControl();
  saoRecordId!: string;

  editRecordForm: FormGroup = new FormGroup({
    assignedSaoOptions: new FormArray([]),
    assetTeam: new FormControl({ value: '', disabled: true }),
    devArea: new FormControl({ value: '', disabled: true }),
    subDevelopmentArea: new FormControl({ value: '', disabled: true }),
    padName: new FormControl({ value: '', disabled: true }),
    externalWellName: new FormControl({ value: '', disabled: true })
  });

  @ViewChild("assetTeamDropdown") assetTeamDropdownControl!: MatSelect;
  @ViewChild("devAreaDropdown") devAreaDropdownControl!: MatSelect;
  @ViewChild("subDevAreaDropdown") subDevAreaDropdownControl!: MatSelect;
  @ViewChild("tgtFormationsDropdown") tgtFormationsDropdownControl!: MatSelect;
  @ViewChild("saoOptionsDropdown") saoOptionsDropdownControl!: MatSelect;
  @ViewChild("saoOptionsComponent") saoOptionsComponentControl!: SaoOptionsComponent;
  OptionType: any;


  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private saoRecordService: SaoRecordService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: GenericDialogService,
    private editServiceEvent: EditServiceEvent,
    private store: Store<IAppState>,
    private payloadDataService: PayloadDataService,
    private dataService: DataService) {
    this.saoRecordsCacheState$ = store.pipe(select('surveillanceDataState'));
    this.basinsCacheState$ = store.pipe(select('basinsCacheState'));
    this.fieldsCacheState$ = store.pipe(select('fieldsCacheState'));
    this.subFieldsCacheState$ = store.pipe(select('subFieldsCacheState'));
  }

  assetTeamSelected!: IBasin | undefined;
  devAreaSelected!: IField | undefined;
  subDevAreasSelected!: IField | undefined;
  saoRecord!: ISurevillanceRecord;

  ngOnInit() {
    this.payloadDataService.clearPayloadData();
    this.saoRecordService.getSaoRecords(this);
    this.saoRecordId = this.activatedRoute.snapshot.paramMap.get('saoRecordId') ?? "";
    this.saoRecord = this.allSaoRecords.find(record => record.id === this.saoRecordId) ?? <ISurevillanceRecord>{};

    let assignedSaoOptionsArray = this.editRecordForm.get('assignedSaoOptions') as FormArray;

    this.saoRecord.data.assignedSaoActivities.forEach(x => {
      const newFormGroup = new FormGroup({
        activityId: new FormControl(''),
        remark: new FormControl(''),
        datasets: new FormControl(''),
        activityTemplateId: new FormControl(''),
        activityTemplateName: new FormControl(''),
        activityStatusId: new FormControl(''),
        activityStatus: new FormControl(''),
        submitterName: new FormControl(''),
        effectiveDateTime: new FormControl('')
      });

      assignedSaoOptionsArray.push(newFormGroup);
    });

    this.editRecordForm.setValue({
      assetTeam: this.saoRecord.data.assetTeam,
      assignedSaoOptions: this.saoRecord.data.assignedSaoActivities,
      devArea: this.saoRecord.data.developmentArea,
      subDevelopmentArea: this.saoRecord.data.subDevelopmentArea,
      padName: this.saoRecord.data.padName,
      externalWellName: this.saoRecord.data.mudiWellName ?? ''
    });

    this.saoRecordService.getBasins(this);

    this.getFields();

    this.editServiceEvent.editEvent.subscribe((evt: boolean) => {
      if (evt) {
        this.submitEditRecord();
        this.editServiceEvent.editRecord(false);
      }
    });

    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.setFormGroupDefaultValues();
    this.changeDetectorRef.detectChanges();
  }

  reloadPage() {
    window.location.reload();
  }

  getFields() {
    let sub = this.fieldsCacheState$.pipe(
      combineLatestWith(this.subFieldsCacheState$)
    )
      .subscribe(([fieldsCacheContent, subFieldsCacheContent]) => {
        this.fieldsLoading = fieldsCacheContent.fieldsLoading && subFieldsCacheContent.subFieldsLoading;
        this.fieldOsduRecords = [...fieldsCacheContent.fields].sort((a, b) => (a.data.fieldName < b.data.fieldName) ? -1 : 1);
        this.subFieldOsduRecords = [...subFieldsCacheContent.subFields].sort((a, b) => (a.data.fieldName < b.data.fieldName) ? -1 : 1);
      });

    this.subscriptions.push(sub);
  }

  setFormGroupDefaultValues() {
    this.fieldOsduRecords.forEach(field => {
      if (field.data.fieldName == this.editRecordForm.controls['devArea'].value) {
        this.basinRecords.forEach(basin => {
          if (basin.id == field.data.geoContexts[0].basinId) {
            this.editRecordForm.controls['assetTeam'].setValue(basin.data.basinName);
          }
        });
      }
    })
  }

  cancel(event: Event) {
    this.router.navigate(['records']);
  }

  expandEvent(event: MatExpansionPanel): void {
    this.expanded = event.expanded;
  }

  isDataLoading(): boolean {
    return (this.saoRecordsLoading
      || this.saoOptionsLoading
      || this.basinsLoading
      || this.fieldsLoading
      || this.submitLoading);
  }

  async submitEditRecord() {
    let payload = this.generatePayloadForEditRecord();
    this.submitLoading = true;

    let response = await this.dataService.UpdateSurveillanceRecord(payload);
    if (response && response?.runtimeStatus === 'Completed' &&
      response?.customStatus !== 'Failed' && response?.output) {
      console.log(`Record updated with ID: ${response?.output?.saoRecordId}`);
      this.submitLoading = false;
      this.router.navigate(['records']);
    } else {
      let msg = 'Updating the SA&O record failed.';
      let err = new Error(`${msg}`);
      console.error(err);
      this.submitLoading = false;
      this.errorDialog(msg);
      this.router.navigate(['records']);
    }

    this.changeDetectorRef.detectChanges();
  }

  private generatePayloadForEditRecord(): IUpdateRecordPayload {
    this.completedActivitiesDataSource.data = this.saoOptionsComponentControl.completedActivitiesDataSource.data;
    this.plannedActivitiesDataSource.data = this.saoOptionsComponentControl.plannedActivitiesDataSource.data;
    let allActivities = this.completedActivitiesDataSource.data.concat(this.plannedActivitiesDataSource.data);

    let sureveillanceRecord: ISurevillanceRecord = <ISurevillanceRecord>{
      id: this.saoRecordId ?? null,
      data: <ISurevillanceData>{
        assetTeam: this.saoRecord.data.assetTeam,
        developmentArea: this.saoRecord.data.developmentArea,
        subDevelopmentArea: this.saoRecord.data.subDevelopmentArea,
        padName: this.saoRecord.data.padName,
        externalWellId: this.saoRecord.data.externalWellId,
        externalWellName: this.saoRecord.data.externalWellName,
        assignedSaoActivities: allActivities
      }
    }
    this.payloadDataService.setSaoRecordData(sureveillanceRecord);
    this.payloadDataService.setAssignedSaoActivitiesData(allActivities);

    return this.payloadDataService.buildUpdateRecordPayload();
  }

  private errorDialog(msg: string) {
    (async () => {
      await this.dialog.openGenericDialog('Error', msg, 'Ok');
    })();
  }
}
