import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IGenericDialogData } from './generic-dialog-data.model';

/**
* Example how to use correctly generic-dialog component:
* @returns return true if user clicks 'Ok' button or false if user clicks 'Cancel' button
* @example
* ```
* openDialog() {
*    const dialogConfig = new MatDialogConfig();
*    dialogConfig.disableClose = true;
*    dialogConfig.autoFocus = true;
*    dialogConfig.height = '210px';
*    dialogConfig.width = '400px';
*    dialogConfig.data = <IGenericDialogData>{
*      dialogType: 'Warning',
*      title: 'Dialog title',  // <-- this property is not mandatory
*      content: 'Message for user',
*      buttons: 'Cancel,Ok'
*    };
*
*    const dialogRef = this.dialog.open(GenericDialogComponent, dialogConfig);
*
*    dialogRef.afterClosed().subscribe((result: boolean) => {
*      this.myFunction(result);
*    });
*  }
*
*  myFunction(result: boolean){
*    console.log("Dialog output:", result);
*  }
* ```
* or you can use the service GenericDialogService:
* @example
* ```
* export class ExampleComponent {
*  constructor(private dialog: GenericDialogService) { }
*
*  async openDialog(){
*    let bool = await this.dialog.openGenericDialog('Warning', 'Message text!', 'Cancel,Ok');
*    console.log(bool);
*  }
* }
* ```
*/
@Component({
  selector: 'sao-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent implements OnInit {

  isButtonOk: boolean = false;
  isButtonCancel: boolean = false;
  isEditButton: boolean = false;
  iconColor!: 'icon-info' | 'icon-warning' | 'icon-error';
  iconeType!: 'info' | 'warning' | 'error';
  dialogTitle: string | null;

  constructor(private dialogRef: MatDialogRef<GenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IGenericDialogData) {
      this.dialogTitle = data.title;
     }

  ngOnInit(): void {
    this.setButtons();
    this.setDialogType();
  }

  setDialogType(){
    if(this.dialogTitle === null || this.dialogTitle === undefined
      || this.dialogTitle === '') {
      this.dialogTitle = this.data.dialogType;
    }

    switch (this.data.dialogType) {
      case 'Information':
          this.iconColor = 'icon-info';
          this.iconeType = 'info';
          break;
      case 'Warning':
          this.iconColor = 'icon-warning';
          this.iconeType = 'warning';
          break;
      case 'Error':
          this.iconColor = 'icon-error';
          this.iconeType = 'error';
          break;
      default:
          console.error("No such dialog type!");
          break;
    }
  }

  setButtons(){
    if(this.data?.buttons) {
      let buttonArray = this.data.buttons.split(',');
      if(buttonArray.includes('Cancel')){
        this.isButtonCancel = true;
      }
      if(buttonArray.includes('Ok')){
        this.isButtonOk = true;
      }
      if(buttonArray.includes('Edit')){
        this.isEditButton = true;
      }
    }
  }

  clickOk() {
    this.dialogRef.close();
  }

  clickClose() {
      this.dialogRef.close();
  }
}
