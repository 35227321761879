import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { combineLatestWith, debounceTime, Observable, of, Subject, Subscription } from 'rxjs';
import { IBasin } from 'src/app/models/osdu/basin.model';
import { IPadPayload } from 'src/app/models/payloads/pad-payload.model';
import { IField } from 'src/app/models/osdu/field.model';
import { IWellPayload } from 'src/app/models/payloads/well-payload.model';
import { DataService } from 'src/app/services/data.service';
import { IAppState } from 'src/app/store/reducers';
import { IFieldsCacheState } from 'src/app/store/reducers/fetch-fields.reducer';
import { IFindWellPayload, SearchType, SearchTypes, SearchTypesLabels } from 'src/app/models/payloads/find-well-payload.model';
import { IWellSearchQuery } from 'src/app/models/asset-development-hierarchy/well-search-query.model';
import { IAdHierarchyPath } from 'src/app/models/asset-development-hierarchy/ad-hierarchy-path.model';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { SaoOptionsComponent } from '../shared/sao-options/sao-options.component';
import { IWellForPad } from 'src/app/models/asset-development-hierarchy/wells-for-pad-response.model';
import { SaoRecordService } from 'src/app/services/sao-record.service';
import { IGenericDialogData } from '../../generic-dialog/generic-dialog-data.model';
import { GenericDialogComponent } from '../../generic-dialog/generic-dialog.component';
import { AddRecordService } from './add-record.service';
import { GenericDialogService } from '../../generic-dialog/generic-dialog.service';
import { ISubFieldsCacheState } from 'src/app/store/reducers/fetch-sub-fields.reducer';
import { IBasinsCacheState } from 'src/app/store/reducers/fetch-basins.reducer';
import { IAssignedSaoActivity, ISurevillanceData, ISurevillanceRecord } from 'src/app/models/osdu/sureveillance-record.model';
import { ISurveillanceDataState } from 'src/app/store/reducers/fetch-surveillance-data-reducer';
import { IActivityTemplate } from 'src/app/models/osdu/activity-template.model';
import { IActivityTemplatesCacheState } from 'src/app/store/reducers/fetch-activity-templates.reducer';
import { PayloadDataService } from 'src/app/services/payload-data.service';
import { IAddRecordPayload } from 'src/app/models/payloads/add-record-payload.model';

@Component({
  selector: 'sao-add-records',
  templateUrl: './add-records.component.html',
  styleUrls: ['./add-records.component.scss']
})
export class AddRecordsComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  private subForPadsAndWells: Subscription[] = [];
  private basinsLoading: boolean = false;
  private fieldsLoading: boolean = false;
  submitLoading: boolean = false;
  visibleFormWarning: any = {
    assetTeam: false,
    devArea: false,
    subDevelopmentArea: false,
    padName: false,
    wellName: false,
    assignedSaoOptions: false
  };
  component: any;
  searchTypes: string[] = Object.values(SearchTypes);

  constructor(private router: Router,
    private matDialog: MatDialog,
    private store: Store<IAppState>,
    private saoRecordService: SaoRecordService,
    private service: DataService,
    private cd: ChangeDetectorRef,
    private addRecordService: AddRecordService,
    private dialog: GenericDialogService,
    private payloadDataService: PayloadDataService,
    private dataService: DataService) {
    this.saoOptionsCacheState$ = store.pipe(select('activityTemplatesCacheState'));
    this.basinsCacheState$ = store.pipe(select('basinsCacheState'));
    this.fieldsCacheState$ = store.pipe(select('fieldsCacheState'));
    this.subFieldsCacheState$ = store.pipe(select('subFieldsCacheState'));
    this.saoRecordsCacheState$ = store.pipe(select('surveillanceDataState'));
  }

  completedActivitiesDataSource: MatTableDataSource<IAssignedSaoActivity> = new MatTableDataSource<IAssignedSaoActivity>();
  plannedActivitiesDataSource: MatTableDataSource<IAssignedSaoActivity> = new MatTableDataSource<IAssignedSaoActivity>();

  fieldForPads: IPadPayload = <IPadPayload>{ SubFieldName: null };
  padForWells: IWellPayload = <IWellPayload>{ PadName: null };
  private wellFindPayload: IFindWellPayload = <IFindWellPayload>{ SearchPhrase: null, SearchType: SearchTypes.PAD };

  searchTypeTxt: string = SearchTypesLabels.PAD_NAME;
  searchPlaceholderTxt: string = this.setSearchPlaceholderTxt(this.searchTypeTxt.toString());

  allSaoRecords: ISurevillanceRecord[] = [];
  private saoRecordsCacheState$: Observable<ISurveillanceDataState>;

  allSaoOptionsData: IActivityTemplate[] = [];
  private saoOptionsCacheState$: Observable<IActivityTemplatesCacheState>;

  basinRecords: IBasin[] = [];
  private basinsCacheState$: Observable<IBasinsCacheState>;

  //field == dev area
  fieldOsduRecords: IField[] = [];
  private fieldsCacheState$: Observable<IFieldsCacheState>;
  subFieldsCacheState$: Observable<ISubFieldsCacheState>;
  devAreaDropdownList: IField[] = [];

  //sub Field == sub dev area
  subFieldOsduRecords: IField[] = [];
  subDevAreaDropdownList: IField[] = [];
  subFields = new FormControl();

  padsDropdownList: string[] = [];
  padsLoading: boolean = false;

  wellsDropdownList: IWellForPad[] = [];
  wellsLoading: boolean = false;

  options = new FormControl();

  wellListSuggestion: string[] = [];
  wellsFindLoading: boolean = false;
  wellListDebounce$: Observable<string[]> = of([]);
  subject: Subject<string> = new Subject();
  mudiRecords: IAdHierarchyPath[] = [];
  mudiWellFind: IWellSearchQuery = <IWellSearchQuery>{ SearchTypeName: null, SearchType: SearchTypes.PAD };

  addRecordForm: FormGroup = new FormGroup({
    assignedSaoOptions: new FormControl('', Validators.required),
    assetTeam: new FormControl('', Validators.required),
    devArea: new FormControl('', Validators.required),
    subDevelopmentArea: new FormControl('', Validators.required),
    padName: new FormControl('', Validators.required),
    wellName: new FormControl('', Validators.required),
    recordByWell: new FormControl('')
  });

  @ViewChild("assetTeamDropdown") assetTeamDropdownControl!: MatSelect;
  @ViewChild("devAreaDropdown") devAreaDropdownControl!: MatSelect;
  @ViewChild("subDevAreaDropdown") subDevAreaDropdownControl!: MatSelect;
  @ViewChild("padDropdown") padDropdownControl!: MatSelect;
  @ViewChild("wellDropdown") wellDropdownControl!: MatSelect;
  @ViewChild("recordByWellAutocomplete") recordByWellAutocompleteControl!: MatAutocomplete;
  @ViewChild("saoOptionsComponent") saoOptionsComponentControl!: SaoOptionsComponent;

  isDataLoading(): boolean {
    return (this.basinsLoading
      || this.fieldsLoading);
  }

  async ngOnInit() {
    this.payloadDataService.clearPayloadData();
    this.saoRecordService.getBasins(this);
    this.saoRecordService.getSaoRecords(this);
    this.getFields();
  }

  ngAfterViewInit() {
    this.onInitDiableDropdownSelection();
    this.subject.pipe(debounceTime(500)).subscribe((searchQuery: string) => {
      if (searchQuery.length > 2) {
        this.wellsFindLoading = true;
      }
      this.wellFindPayload.SearchPhrase = searchQuery;
      this.getWellSuggestion(this.wellFindPayload)
    });
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getFields() {
    let sub = this.fieldsCacheState$.pipe(
      combineLatestWith(this.subFieldsCacheState$)
    )
      .subscribe(([fieldsCacheContent, subFieldsCacheContent]) => {
        this.fieldsLoading = fieldsCacheContent.fieldsLoading && subFieldsCacheContent.subFieldsLoading;
        this.fieldOsduRecords = [...fieldsCacheContent.fields].sort((a, b) => (a.data.fieldName < b.data.fieldName) ? -1 : 1);
        this.subFieldOsduRecords = [...subFieldsCacheContent.subFields].sort((a, b) => (a.data.fieldName < b.data.fieldName) ? -1 : 1);
      });

    this.subscriptions.push(sub);
  }

  getPads(field: IPadPayload) {
    if (field.SubFieldName !== null) {
      this.padsLoading = true;
      let sub = this.service.getPadsForField(field)
        .subscribe({
          next: (response: string[]) => {
            this.padsDropdownList = response;
          },
          error: (error) => {
            this.padsLoading = false;
            console.error('HTTP Error', error)
          },
          complete: () => {
            this.padsLoading = false;
            console.log('HTTP request Pads for Field completed.')
          }
        });
      this.subForPadsAndWells.push(sub);
    }
  }

  getWells(pad: IWellPayload) {
    if (pad.PadName) {
      this.wellsLoading = true;
      let sub = this.service.getWellsForPad(pad)
        .subscribe({
          next: (response: IWellForPad[]) => {
            this.wellsDropdownList = response;
          },
          error: (error) => {
            this.wellsLoading = false;
            console.error('HTTP Error', error)
          },
          complete: () => {
            this.wellsLoading = false;
            console.log('HTTP request Wells for Pad completed.');
          }
        });
      this.subscriptions.push(sub);
    }
  }

  getWellSuggestion(wellSearchPhrase: IFindWellPayload) {
    if (wellSearchPhrase.SearchPhrase && wellSearchPhrase.SearchPhrase.length > 2) {
      wellSearchPhrase.SearchPhrase = this.getWildcardSearchPhrase(wellSearchPhrase.SearchPhrase);

      let sub = this.service.GetWellSearchSuggestions(wellSearchPhrase)
        .subscribe({
          next: (response) => {
            this.wellListSuggestion = response;
          },
          error: (error) => {
            console.error('HTTP Error', error);
            this.wellsFindLoading = false;
          },
          complete: () => {
            console.log('HTTP request Well Suggestion completed.');
            this.wellsFindLoading = false;
          }
        });
      this.subscriptions.push(sub);
    }
  }

  private getWildcardSearchPhrase(phrase: string): string {
    let wildcardPhrase: string = phrase;
    wildcardPhrase = this.addEscapeCharacter(wildcardPhrase);
    if (!phrase.startsWith('^')) {
      wildcardPhrase = `%${wildcardPhrase}`;
    } else {
      wildcardPhrase = wildcardPhrase.replace('^', '');
    }

    if (!phrase.endsWith('$')) {
      wildcardPhrase = `${wildcardPhrase}%`
    } else {
      wildcardPhrase = wildcardPhrase.replace(/\$$/, '');
    }

    wildcardPhrase = wildcardPhrase.replace(/\*/g, '_').replace(/\?/g, '%');

    wildcardPhrase = wildcardPhrase.replace(/%%%%/g, '%');
    wildcardPhrase = wildcardPhrase.replace(/%%%/g, '%');
    wildcardPhrase = wildcardPhrase.replace(/%%/g, '%');

    return wildcardPhrase;
  }

  private addEscapeCharacter(wildcardPhrase: string): string {
    const escapeCharakter = '!';
    if (wildcardPhrase.indexOf('_') > -1) {
      let wildeArray = wildcardPhrase.split('_');
      wildcardPhrase = wildeArray.join(`${escapeCharakter}_`);
    }

    return wildcardPhrase;
  }

  getPadsPromise(field: IPadPayload): Promise<string[]> {
    this.padsLoading = true;
    return new Promise<string[]>((resolve, reject) => {
      this.service.getPadsForField(field)
        .subscribe((records: string[]) => {
          resolve(records);
          reject(new Error("getPadsForField promise rejected!"));
        });
    }).finally(() => {
      this.padsLoading = false;
    });
  }

  getWellsPromise(pad: IWellPayload): Promise<IWellForPad[]> {
    this.wellsLoading = true;
    return new Promise<IWellForPad[]>((resolve, reject) => {
      this.service.getWellsForPad(pad)
        .subscribe((records: IWellForPad[]) => {
          resolve(records);
          reject(new Error('getWellsForPad promise rejected!'));
        });
    }).finally(() => {
      this.wellsLoading = false;
    });
  }

  getAdHierarchyPath(wellMudiName: IWellSearchQuery): Promise<IAdHierarchyPath[]> {
    return new Promise<IAdHierarchyPath[]>((resolve, reject) => {
      this.service.GetAdHierarchyForWell(wellMudiName)
        .subscribe((records: IAdHierarchyPath[]) => {
          resolve(records);
          reject(new Error("getMudiRecords promise rejected!"));
        });
    });
  }

  selectSearchType(event: any) {
    this.wellFindPayload.SearchType = event.value;
    this.mudiWellFind.SearchType = event.value;
    this.searchTypeTxt = this.setSearchTypeTxt(event.value);
    this.searchPlaceholderTxt = this.setSearchPlaceholderTxt(this.searchTypeTxt.toString());

    this.devAreaDropdownControl.disabled = true;
    this.devAreaDropdownList = [];

    this.subDevAreaDropdownControl.disabled = true;
    this.subDevAreaDropdownList = [];

    this.padDropdownControl.disabled = true;
    this.padsDropdownList = [];

    this.wellDropdownControl.disabled = true;
    this.wellsDropdownList = [];

    this.clearAutocomplete();
    this.clearDropdowns('assetTeam', 'devAreaDropdownControl', 'subDevAreaDropdownControl', 'padDropdownControl', 'wellDropdownControl');
    this.formValidator();
    this.cd.detectChanges();
  }

  setSearchTypeTxt(typeName: SearchType): string {
    switch (typeName) {
      case SearchTypes.PAD: {
        return SearchTypesLabels.PAD_NAME;
      }
      case SearchTypes.WELL: {
        return SearchTypesLabels.WELL_NAME;
      }
      case SearchTypes.API: {
        return SearchTypesLabels.API_NUMBER;
      }
      default: {
        throw new Error(`Search Type '${typeName}' does not exist.`);
      }
    }
  }

  setSearchPlaceholderTxt(searchType: string) {
    return `Please start typing ${searchType}...`;
  }

  assetTeamSelected!: IBasin | undefined;
  onBasinSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.clearDropdowns('devAreaDropdownControl', 'subDevAreaDropdownControl', 'padDropdownControl', 'wellDropdownControl');
      this.assetTeamSelected = event.source.value;

      this.devAreaDropdownControl.disabled = true;
      this.subDevAreaDropdownControl.disabled = true;

      this.padDropdownControl.disabled = true;
      this.padsDropdownList = [];

      this.wellDropdownControl.disabled = true;
      this.wellsDropdownList = [];

      this.saoRecordService.onBasinSelectByAssetTeam(this, event.source.value);

      this.devAreaDropdownControl.disabled = false;
      this.clearAutocomplete();
    }
  }

  devAreaSelected!: IField | undefined;
  onDevAreaSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.clearDropdowns('subDevAreaDropdownControl', 'padDropdownControl', 'wellDropdownControl');

      this.padDropdownControl.disabled = true;
      this.padsDropdownList = [];

      this.wellDropdownControl.disabled = true;
      this.wellsDropdownList = [];

      this.saoRecordService.onDevAreaSelect(this, event.source.value);

      this.clearAutocomplete();
    }
  }

  subDevAreasSelected!: IField | undefined;
  onSubDevAreaSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.clearDropdowns('padDropdownControl', 'wellDropdownControl');

      this.subDevAreasSelected = event.source.value;

      this.padDropdownControl.disabled = true;
      this.padsDropdownList = [];

      this.wellDropdownControl.disabled = true;
      this.wellsDropdownList = [];

      this.fieldForPads.SubFieldName = this.subDevAreasSelected?.data.fieldName;
      this.getPads(this.fieldForPads);

      this.padDropdownControl.disabled = false;
      this.clearAutocomplete();
    }
  }

  padSelected!: string | undefined
  onPadSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.clearDropdowns('wellDropdownControl');
      this.padSelected = event.source.value;

      this.wellDropdownControl.disabled = true;
      this.wellsDropdownList = [];

      this.padForWells.PadName = this.padSelected;
      this.getWells(this.padForWells);

      this.wellDropdownControl.disabled = false;
      this.clearAutocomplete();
    }
  }

  wellSelected!: IWellForPad | undefined
  onWellSelect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.wellSelected = event.source.value;
      this.checkIfAnySaoAlreadyAssigned(this.wellSelected?.propertyId);
      this.clearAutocomplete();
    }
  }

  clearAutocomplete() {
    this.addRecordForm.controls['recordByWell'].setValue('');
    this.wellListSuggestion = [];
  }

  clearDropdowns(...nameOfcontrol: string[]) {
    if (nameOfcontrol.indexOf('assetTeam') > -1) {
      this.assetTeamDropdownControl.options.forEach(option => option.deselect());
    }

    if (nameOfcontrol.indexOf('subDevAreaDropdownControl') > -1) {
      this.subDevAreaDropdownControl.options.forEach(option => option.deselect());
    }

    if (nameOfcontrol.indexOf('padDropdownControl') > -1) {
      this.padDropdownControl.options.forEach(option => option.deselect());
    }

    if (nameOfcontrol.indexOf('wellDropdownControl') > -1) {
      this.wellDropdownControl.options.forEach(option => option.deselect());
    }
    if (nameOfcontrol.indexOf('devAreaDropdownControl') > -1) {
      this.devAreaDropdownControl.options.forEach(option => option.deselect());
    }

    if (nameOfcontrol.indexOf('subDevAreaDropdownControl') > -1) {
      this.subDevAreaDropdownControl.options.forEach(option => option.deselect());
    }

    if (nameOfcontrol.indexOf('padDropdownControl') > -1) {
      this.padDropdownControl.options.forEach(option => option.deselect());
    }

    if (nameOfcontrol.indexOf('wellDropdownControl') > -1) {
      this.wellDropdownControl.options.forEach(option => option.deselect());
    }
  }

  onInitDiableDropdownSelection() {
    this.devAreaDropdownControl.disabled = true;
    this.subDevAreaDropdownControl.disabled = true;
    this.padDropdownControl.disabled = true;
    this.wellDropdownControl.disabled = true;
  }

  onKeyUpWellSearch(event: any) {
    let searchWell = event.target.value;
    this.subject.next(searchWell);
  }

  onSelectAutoCompleteField(event: any) {
    if (event.isUserInput) {
      let searchTypeName = event.source.value;
      this.wellListSuggestion = [searchTypeName];
      this.mudiWellFind.SearchTypeName = searchTypeName;
      this.processWellSearchQuery(this.mudiWellFind);
    }
  }

  openInfoDialog(id: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '210px';
    dialogConfig.width = '400px';
    dialogConfig.data = <IGenericDialogData>{
      dialogType: 'Information',
      content: 'The are already planned/acquired SA&O activities for this well. Would you like to edit?',
      buttons: 'Cancel,Edit'
    };

    const dialogRef = this.matDialog.open(GenericDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.router.navigate(['edit', { saoRecordId: id }]);
      } else {
        this.clearDropdowns('wellDropdownControl');
        this.clearAutocomplete();
      }
    });
  }

  async processWellSearchQuery(wellSearchQuery: IWellSearchQuery) {

    this.clearDropdowns('devAreaDropdownControl', 'subDevAreaDropdownControl', 'padDropdownControl', 'wellDropdownControl');
    let hierarchyPaths = await this.getAdHierarchyPath(wellSearchQuery);

    if (wellSearchQuery.SearchType === SearchTypes.PAD) {
      //check for already assigned activities will occur on well selection
      this.fillDropdowns(hierarchyPaths, wellSearchQuery.SearchType);
      return;
    }

    let propId = hierarchyPaths[0].propertyId;
    let findResult: boolean = this.checkIfAnySaoAlreadyAssigned(propId);
    if (!findResult) {
      this.fillDropdowns(hierarchyPaths, wellSearchQuery.SearchType);
    }
  }

  private checkIfAnySaoAlreadyAssigned(
    propId: string | null | undefined): boolean {
    if (propId !== null && propId !== undefined) {
      let possibleMatch = this.allSaoRecords.find(record => record.data.externalWellId == propId);
      if (possibleMatch) {
        this.openInfoDialog(possibleMatch.id);
        return true;
      }
    }
    return false;
  }

  fillDropdowns(hierarchyPaths: IAdHierarchyPath[], searchedType: SearchType) {
    if (hierarchyPaths && hierarchyPaths.length >= 1) {

      this.devAreaDropdownControl.disabled = true;
      this.devAreaDropdownList = [];

      this.subDevAreaDropdownControl.disabled = true;
      this.subDevAreaDropdownList = [];

      this.padDropdownControl.disabled = true;
      this.padsDropdownList = [];

      this.wellDropdownControl.disabled = true;
      this.wellsDropdownList = [];

      this.addRecordService.fillAsseTeam(hierarchyPaths, this);

      this.addRecordService.fillDevArea(hierarchyPaths, this);

      this.addRecordService.fillSubDevArea(hierarchyPaths, this);

      this.addRecordService.fillPadName(hierarchyPaths, this);

      this.addRecordService.fillWellName(hierarchyPaths, searchedType, this);

      this.formValidator('assetTeam', 'devArea', 'subDevelopmentArea', 'padName', 'wellName');
    }
  }

  cancel() {
    this.router.navigate(['records']);
  }

  async submitNewRecord() {

    if (!this.addRecordForm.valid) {
      this.formValidator('assetTeam', 'devArea', 'subDevelopmentArea', 'padName', 'wellName', 'assignedSaoOptions');
      return;
    }

    this.formValidator();

    let payload = this.generatePayloadForAddRecord();
    this.submitLoading = true;

    let response = await this.dataService.CreateSurveillanceRecord(payload);
    if (response && response?.runtimeStatus === 'Completed' &&
      response?.customStatus !== 'Failed' && response?.output) {
      console.log(`Record added with ID: ${response?.output?.recordIds}`);
      this.submitLoading = false;
      this.router.navigate(['records']);
    } else {
      let msg = 'Adding the SA&O record failed.';
      let err = new Error(`${msg}`);
      console.error(err);
      this.submitLoading = false;
      this.errorDialog(msg);
      this.router.navigate(['records']);
    }
  }

  private generatePayloadForAddRecord(): IAddRecordPayload {
    this.completedActivitiesDataSource.data = this.saoOptionsComponentControl.completedActivitiesDataSource.data;
    this.plannedActivitiesDataSource.data = this.saoOptionsComponentControl.plannedActivitiesDataSource.data;
    let allActivities = this.completedActivitiesDataSource.data.concat(this.plannedActivitiesDataSource.data);

    let sureveillanceRecord: ISurevillanceRecord = <ISurevillanceRecord>{
      id: '',
      data: <ISurevillanceData>{
        assetTeam: this.assetTeamSelected?.data?.basinName,
        developmentArea: this.devAreaSelected?.data?.fieldName,
        subDevelopmentArea: this.subDevAreasSelected?.data?.fieldName,
        padName: this.padSelected,
        externalWellId: this.wellSelected?.propertyId,
        externalWellName: this.wellSelected?.wellName,
        assignedSaoActivities: allActivities
      }
    }
    this.payloadDataService.setSaoRecordData(sureveillanceRecord);
    this.payloadDataService.setAssignedSaoActivitiesData(allActivities);

    return this.payloadDataService.buildAddRecordPayload();
  }

  formValidator(...keyName: string[]): void {
    for (const key of Object.keys(this.visibleFormWarning)) {
      let status = this.addRecordForm.controls[key].status
      if (status === 'INVALID' && keyName.includes(key)) {
        this.visibleFormWarning[key as keyof typeof this.visibleFormWarning] = true;
      } else {
        this.visibleFormWarning[key as keyof typeof this.visibleFormWarning] = false;
      }
    };
  }

  private errorDialog(msg: string) {
    (async () => {
      await this.dialog.openGenericDialog('Error', msg, 'Ok');
    })();
  }
}
