<div class="sao-option-file-version-element upload-files-content">
    <mat-table #innerFileVersionTables mat-table [dataSource]="fileVersionDataSource" class="mat-elevation-z8"
        aria-label="Acquired" matSort (matSortChange)="sortData($event)">
        <ng-container *ngFor="let column of fileVersionsOptColumns; let i = index" [matColumnDef]="column.columnDef">
            <ng-container *ngIf="column.columnDef == 'actions'; else saoOptionFileVersion">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
                    <p>{{column.header}}</p>
                </mat-header-cell>
                <mat-cell id="actions-cell" mat-cell *matCellDef="let row">
                    <a href={{row.signedUrl}} *ngIf="shouldDisplayDownloadButton(row)">
                        <span class="material-symbols-outlined" id="download-button">download</span>
                    </a>
                    <a (click)="openFilePreview($event, row)" *ngIf="shouldDisplayPreviewButton(row)">
                        <span class="material-symbols-outlined prview-btn" id="preview-button">preview</span>
                    </a>
                    <a (click)="deleteFileVersion(row)" *ngIf="shouldDisplayDeleteButton()">
                        <span class="material-symbols-outlined" id="delete-button">delete</span>
                    </a>
                </mat-cell>
            </ng-container>

            <ng-template #saoOptionFileVersion>
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by {{column.header}}">
                    <p>{{column.header}}</p>
                </mat-header-cell>

                <mat-cell *matCellDef="let fileVersionRow" title="{{column.cell(fileVersionRow)}}">
                    <p>{{column.cell(fileVersionRow)}}</p>
                </mat-cell>

            </ng-template>


        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedFileVesionsOptCols"></tr>
        <tr mat-row *matRowDef="let fileVersionRow; columns: displayedFileVesionsOptCols;"></tr>
    </mat-table>
</div>
