import { Action } from "@ngrx/store"
import { IFormationsCacheState } from "../reducers/fetch-formations.reducer";

export enum FetchFormationsActions {
    FORMATIONS_REQUEST = '[FORMATIONS_CACHE] Request Formations',
    FORMATIONS_REQUEST_SUCCESS = '[FORMATIONS_CACHE] Formations request - success',
    FORMATIONS_REQUEST_FAILED = '[FORMATIONS_CACHE] Fromations request - failed' 
}

export class RequestFormations implements Action {
    readonly type: string = FetchFormationsActions.FORMATIONS_REQUEST;
    constructor(public payload: IFormationsCacheState = <IFormationsCacheState>{}) {}
}

export class FormationsRequestSuccess implements Action {
    readonly type: string = FetchFormationsActions.FORMATIONS_REQUEST_SUCCESS;
    constructor(public payload: IFormationsCacheState) {}
}

export class FormationsRequestFailed implements Action {
    readonly type: string = FetchFormationsActions.FORMATIONS_REQUEST_FAILED;
    constructor(public payload: IFormationsCacheState) {}
}

export type FetchFormationsAction 
= RequestFormations | FormationsRequestSuccess | FormationsRequestFailed;