import { SeriesOption } from 'echarts';
import { IOptionOccurence } from '../components/dashboard/charts/charts.component';

type EChartsOption = echarts.EChartsOption;

export class ChartsUtils {

  readonly horizontalStackName: string = 'count';
  readonly horizontalStackedBarChartMaxCategories: number = 10;

  private distinctDevAreas: string[] = [];
  private distinctOptions: string[] = [];

  public getHorizontalStackedChartOption(optionOccurences: IOptionOccurence[]): EChartsOption {
    this.distinctDevAreas = 
      [...new Set(optionOccurences.map(occ => occ.devAreaName))];

    let devAreaCount: IDevAreaOccurenceCount[] = [];
    this.distinctDevAreas.forEach(da => {
      let daCount = optionOccurences.filter(oc => oc.devAreaName === da).length;
      devAreaCount.push(<IDevAreaOccurenceCount>{
        devArea: da,
        count: daCount
      });
    }); 
    devAreaCount.sort((a,b) => (a.count < b.count) ? -1 : 1)
    this.distinctDevAreas = devAreaCount.map(dac => dac.devArea);
    this.distinctOptions = [...new Set(optionOccurences.filter(occ => this.distinctDevAreas.includes(occ.devAreaName)).map(occ => occ.optionName))];
     let xAxisData = this.distinctDevAreas;

    let option: EChartsOption = {
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        },
      },
      grid: {
        left: '3%',
        right: '3%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'value'
        }
      ],
      yAxis: [
        {
          type: 'category',
          data: xAxisData,
          nameRotate: 45,
        }
      ],
      series: this.getStackedBarChartSeries(optionOccurences)
    }

    return option;
  }

  public getSimplePieChartOption(optionOccurences: IOptionOccurence[]): EChartsOption {

    let pieChartData: IPieChartDataItem[] = this.calculateSaoCountForTgtFormations(optionOccurences);

    let option: EChartsOption = {
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: 'Target Formation',
          type: 'pie',
          radius: '50%',
          data: pieChartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    return option;
  }

  private getStackedBarChartSeries(optionsOccurences: IOptionOccurence[]): SeriesOption[] {
    let series: SeriesOption[] = [];

    this.distinctOptions.forEach(opt => {
      let data: any[] = [];
      this.distinctDevAreas.forEach(da => {
        let value = optionsOccurences.filter(occ => occ.optionName === opt && occ.devAreaName === da).length;
        let count = value == 0 ? null : value;
        data.push(count);
      })
      let seriesOption = this.createSeriesOption(opt, data);
      series.push(seriesOption);
    })
    return series;
  }

  private createSeriesOption(optionName: string, data: any[]): SeriesOption {
    let option: SeriesOption = {
      name: optionName,
      type: 'bar',
      stack: this.horizontalStackName,
      emphasis: {
        focus: 'series'
      },
      data: data
    };

    return option;
  }

  private calculateSaoCountForTgtFormations(optionOccurences: IOptionOccurence[]): IPieChartDataItem[] {
    let distinctTgtFormations = [...new Set(optionOccurences.map(occ => occ.tgtFormation))]
    .sort((a, b) => a.localeCompare(b));

    let pieDataItemOptions: IPieChartDataItem[] = [];

    distinctTgtFormations.forEach(formation => {
      let count = optionOccurences.filter(occ => occ.tgtFormation === formation).length;
      pieDataItemOptions.push({name: formation, value: count});
    })

    return pieDataItemOptions;
  }
}

interface IPieChartDataItem {
  value: number,
  name: string
}

interface IDevAreaOccurenceCount {
  devArea: string,
  count: number
}