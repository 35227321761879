import { IBasin } from "src/app/models/osdu/basin.model";
import { FetchBasinsAction, FetchBasinsActions } from "../actions/fetch-basins.actions";

export interface IBasinsCacheState {
    basins: IBasin[],
    basinsLoading: boolean,
    basinsRequestError: any
}

const initialState: IBasinsCacheState = {
    basins: [],
    basinsLoading: false,
    basinsRequestError: null
}

export function basinsReducer(state: IBasinsCacheState = initialState, action: FetchBasinsAction): IBasinsCacheState {
    switch(action.type) {
        case FetchBasinsActions.BASINS_REQUEST: {
            state = <IBasinsCacheState>{
                basins: [], basinsLoading: true, basinsRequestError: null
            };
            return state;
        }
        case FetchBasinsActions.BASINS_REQUEST_SUCCESS: {
            state = action.payload;
            return state;
        }
        case FetchBasinsActions.BASINS_REQUEST_FAILED: {
            return <IBasinsCacheState>{
                basins: [],
                basinsLoading: false,
                basinsRequestError: action.payload.basinsRequestError
            };
        }
        default: {
            return state;
        }
    }
}