import { Component } from '@angular/core';

export interface IMenuItem {
  menuCardTitle: string,
  menuCardSubtitle: string,
  menuCardIconId: string,
  menuCardButtonText: string,
  menuCardRouterLink: string
}

@Component({
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {

  public menuCards: IMenuItem[] = [
    {
      menuCardTitle: "Search Records",
      menuCardSubtitle: "View and search SA&O records",
      menuCardIconId: "query_stats",
      menuCardButtonText: "Search Records",
      menuCardRouterLink: "/records"
    },
    {
      menuCardTitle: "Add Records",
      menuCardSubtitle: "Create SA&O records",
      menuCardIconId: "create_new_folder",
      menuCardButtonText: "Manage Records",
      menuCardRouterLink: "/add"
    },
    {
      menuCardTitle: "Maps & Dashboard",
      menuCardSubtitle: "View dashboard",
      menuCardIconId: "map",
      menuCardButtonText: "Explore map and dashboard",
      menuCardRouterLink: "/dashboard"
    },
  ]
}
