<div class="filters">
    <div class="clear-filters-button">
        <a (click)="resetFiltersClicked()">
            <span class="material-symbols-outlined"> filter_alt_off </span>
            <span class="icons-text"> Reset</span>
        </a>
    </div>
    <div class="download-filters-button">
        <a (click)="exportToCsv()">
            <span class="material-symbols-outlined"> download </span>
            <span class="icons-text"> Download</span>
        </a>
    </div>
    <div class="filter-by-attachment">
        <mat-slide-toggle [(ngModel)]="filterByAttachment" color="primary" matTooltip="{{ filterByAttachmentTooltip }}"
            (change)="filterByAttachedFiles()"></mat-slide-toggle>
    </div>
    <mat-form-field>
        <mat-label>Quick search</mat-label>
        <input #quickSearchInput matInput (keyup)="applyQuickSearchFilter($event)" [(ngModel)]="quickSearchInputValue"
            placeholder="Type to filter all columns..." #input
            matTooltip="Wildcards:&#13;&#10;^ - starts with	'^hello',&#13;&#10;$ - ends with	'hello$',&#13;&#10;* -	represents one, single character 'hel*o',&#13;&#10;? -	represents zero, one, or multiple characters	'h?o'"
            matTooltipClass="multiline-tooltip" matTooltipPosition="below">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Asset Team</mat-label>
        <mat-select #assetTeamDropdown [formControl]="assetTeamForm">
            <mat-option *ngFor="let basin of basinRecords" [value]="basin" (onSelectionChange)="onBasinSelect($event)">
                {{basin.data.basinName}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Dev Area</mat-label>
        <mat-select #devAreaDropdown [formControl]="devAreaForm">
            <mat-option *ngFor="let devArea of devAreaDropdownList" [value]="devArea"
                (onSelectionChange)="onDevAreaSelect($event)">
                {{devArea.data.fieldName}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Sub Dev Area</mat-label>
        <mat-select #subDevAreaDropdown [formControl]="subDevAreaForm" multiple>
            <mat-option *ngFor="let subDevArea of subDevAreaDropdownList" [value]="subDevArea"
                (onSelectionChange)="onSubDevAreaSelect($event)">
                {{subDevArea.data.fieldName}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Tgt Formation</mat-label>
        <mat-select #tgtFormationsDropdown [formControl]="tgtFormationsForm" multiple>
            <mat-option *ngFor="let formation of tgtFormationsDropdownList" [value]="formation"
                (onSelectionChange)="onTargetFormationSelect($event)">
                {{formation.data.Name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>SA&O Options</mat-label>
        <mat-select #activityTemplatesDropdown [formControl]="activityTemplatesForm" multiple>
            <mat-option *ngFor="let activityTemplate of activityTemplateList" [value]="activityTemplate"
                (onSelectionChange)="onSaoOptionsSelect($event)">
                {{activityTemplate.data.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Activity Type</mat-label>
        <mat-select #activityOptionsDropdown [formControl]="activityOptionsForm">
            <mat-option *ngFor="let option of saoActivityOptionsDropdownList" [value]="option"
                (onSelectionChange)="onSaoActivityTypeSelect($event)">
                {{option}}
            </mat-option>
        </mat-select>
      </mat-form-field>
</div>
