import { Action } from '@ngrx/store';
import { LoginActionTypes } from "../actions/login.actions";

export enum LoginState {
    LOGGEDOUT = 0,
    LOGGING = 1, 
    LOGGEDIN = 2
}

export const initialLoginState: LoginState = LoginState.LOGGEDOUT;

export function loginStateReducer(state = initialLoginState, action: Action){

    switch(action.type) {
        case LoginActionTypes.LOGIN: {
            state = LoginState.LOGGING;
            return state;
        }
        case LoginActionTypes.LOGGEDIN: {
            state = LoginState.LOGGEDIN;
            return state;
        }
        case LoginActionTypes.LOGOUT: {
            state = LoginState.LOGGEDOUT;
            return state;
        }
        default: return state;
    }
}