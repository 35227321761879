import { Action } from "@ngrx/store"
import { ITargetFormationsCacheState } from "../reducers/fetch-tgt-formations.reducer";

export enum FetchTargetFormationsActions {
    TARGET_FORMATIONS_REQUEST = '[TARGET_FORMATIONS_CACHE] Request Target Formations',
    TARGET_FORMATIONS_REQUEST_SUCCESS = '[TARGET_FORMATIONS_CACHE] Target Formations request - success',
    TARGET_FORMATIONS_REQUEST_FAILED = '[TARGET_FORMATIONS_CACHE] Target Fromations request - failed' 
}

export class RequestTargetFormations implements Action {
    readonly type: string = FetchTargetFormationsActions.TARGET_FORMATIONS_REQUEST;
    constructor(public payload: ITargetFormationsCacheState = <ITargetFormationsCacheState>{}) {}
}

export class TargetFormationsRequestSuccess implements Action {
    readonly type: string = FetchTargetFormationsActions.TARGET_FORMATIONS_REQUEST_SUCCESS;
    constructor(public payload: ITargetFormationsCacheState) {}
}

export class TargetFormationsRequestFailed implements Action {
    readonly type: string = FetchTargetFormationsActions.TARGET_FORMATIONS_REQUEST_FAILED;
    constructor(public payload: ITargetFormationsCacheState) {}
}

export type FetchTargetFormationsAction 
= RequestTargetFormations | TargetFormationsRequestSuccess | TargetFormationsRequestFailed;