export const popupTemplate: string = `
<style type='text/css'>
#openSaoOptionsButton {
  color: #ffffff;
  background-color: #0b2d71;
}
#closePopupBtn {
  color: #0b2d71;
  background-color: #ffffff;
  font-weight: bolder;
  cursor: pointer;
}
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.item1 {
  flex-grow:1;
  align-self:auto;
}
.item2 {
  flex-shrink:1;
}

table {
  width: 250px;
  background-color: #ffffff;
}
.col1 {
  white-space: nowrap;
  padding-right: 2px;
  font-weight: bold;
}
.col2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.center-cell {
  text-align: center
}
.table-header {
  font-weight: bold;
  color: #ffffff;
  background-color: #0b2d71;
}
</style>
<table>
  <tr>
    <th colspan="2" class="center-cell table-header">
    <div class="flex-container">
      <div class="item1">
        {wellName}
      </div>
      <div class="item2">
        <button type="button" id="closePopupBtn">x</button>
      </div>
    </div>
    </th>
  </tr>
  <tr>
    <td class="col1">Asset Team:</td><td class="col2" title="{assetTeam}">{assetTeam}</td>
  </tr>
  <tr>
    <td class="col1">Dev Area:</td><td class="col2" title="{devArea}">{devArea}</td>
  </tr>
  <tr>
    <td class="col1">Sub Dev Area:</td><td class="col2" title="{subDevArea}">{subDevArea}</td>
  </tr>
  <tr>
    <td class="col1">Pad Name:</td><td class="col2" title="{padName}">{padName}</td>
  </tr>
  <tr>
    <td colspan="2" class="center-cell"><button type="button" id="openSaoOptionsButton" data-sao-well-id="{wellID}">SA&O Options</button></td>
  </tr>
</table>`;