import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import UnitConverter from 'src/app/util/unit-converter.util';
import { IFileUploadBlob } from 'src/app/models/files/file-upload-blob-response.model';
import { GenericDialogService } from '../generic-dialog/generic-dialog.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { IFileInfoUpload } from 'src/app/models/files/file-info-upload';
import { UploadLargeFileService } from 'src/app/services/upload-large-file.service';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { IDatasetsVersion, UploadType } from 'src/app/models/Dto/datasets-version.model';

@Component({
  selector: 'sao-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {

  filesSize: number = 0;
  largeFileLimitUpload: number = 1288490188; // number of bytes = 1.2 GiB
  largeFileLimitUploadLabel: string = '1.2 GiB'
  uploadDisalble: boolean = false;
  fileComment = new FormControl('', [
    Validators.required,
    Validators.minLength(1),
  ]);
  disalbledUpload: boolean = true;
  disalbledAddNewFileUpload: boolean = false;
  currenFileNameList: string[] = []
  @ViewChild('fileComment') fileCommentInput!: MatInput;
  form: any;
  datasetsVersions!: IDatasetsVersion[];
  uploadType!: UploadType;


  constructor(
    public dialogRef: MatDialogRef<FileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: GenericDialogService,
    private formBuilder: FormBuilder,
    private largeFileService: UploadLargeFileService) {

    dialogRef.beforeClosed().subscribe(() => dialogRef.close(this.filesUploaded));

    this.formBuilder = formBuilder;
    this.form = this.formBuilder.group({
      fileComment: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.datasetsVersions = this.data.datasetsVersion;
    this.uploadType = this.data.uploadType;
  }

  ngOnDestroy(): void {
    this.largeFileService.dispose();
    this.filesUploaded = [];
  }

  placeholderText = 'Click or drag & drop a file'
  currentFiles?: File[];
  message = '';
  fileNames = this.placeholderText;
  progress = 0;
  fileInfos: File[] = [];
  fileInfoUploads: IFileInfoUpload[] = [];
  filesUploaded: IFileUploadBlob[] = [];

  async selectFile(event: any) {
    if (event?.target?.files.length > 0) {
      this.filesSize = 0;
      let files: File[] = event.target.files;
      this.currentFiles = files;
      let filesNameArray: string[] = [];
      Array.from(this.currentFiles).forEach(file => {
        filesNameArray.push(file.name);
        this.filesSize += file.size;
      });

      if (filesNameArray.length > 0) {
        this.fileNames = filesNameArray.join(',\n');
      }
    }
    else {
      this.fileNames = 'Select File';
    }

    let checkFilesize = await this.filesSizeAlert();
    if (checkFilesize) {
      this.disalbledAddNewFileUpload = true;
      await this.uploadLargeFile();
    }

    this.changeDetectorRef.detectChanges();
  }

  async filesSizeAlert(): Promise<boolean> {
    if (this.filesSize > this.largeFileLimitUpload) {
      let msg = `The files size limit has been exceeded (${this.largeFileLimitUploadLabel})!`;

      await this.dialog.openGenericDialog('Warning', msg, 'Ok');
      this.filesSize = 0;
      this.fileNames = this.placeholderText;
      this.currentFiles = undefined;
      this.currenFileNameList = [];

      return false;
    }

    return true;
  }

  submitFiles(object: any) {
    this.closePopulatedFilesUploaded();
  }

  closeDialog() {
    this.closePopulatedFilesUploaded(true);
  }

  closePopulatedFilesUploaded(isClose = false) {
    if (isClose) {
      this.largeFileService.dispose();
      this.filesUploaded = [];
    }

    this.filesUploaded = this.filesUploaded.map((fileInfo) => {
      let fileInfoUpload = this.fileInfoUploads.filter(x => x.blobName == fileInfo.blobName)[0];

      let fileUploadBlob: IFileUploadBlob;
      if (this.uploadType === 'NewFile') {
        fileUploadBlob = <IFileUploadBlob>{
          ...fileInfo,
          fileComment: fileInfoUpload?.fileComment
        }
      } else {
        fileUploadBlob = <IFileUploadBlob>{
          ...fileInfo,
          fileComment: fileInfoUpload?.fileComment
        }
      }

      return fileUploadBlob;
    });

    this.dialogRef.close(this.filesUploaded);
  }

  blockAddFile(): boolean {
    if (this.uploadType === 'NewVersion' && this.filesUploaded?.length >= 1) {
      return true;
    }
    return false;
  }

  async uploadLargeFile() {
    if (this.currentFiles) {
      Array.from(this.currentFiles).forEach(file => {
        this.currenFileNameList.push(file.name)
      });

      this.largeFileService.progressEmitter.subscribe(progress => {
        this.progress = progress;
      });
      let response = await this.largeFileService.uploadLargeFileAsync(this.currentFiles[0]);
      this.filesUploaded.push({
        blobName: response.blobName,
        fileName: response.fileName,
        isUploadedToBlob: response.isUploadedToBlob,
      } as IFileUploadBlob);

      if (this.currentFiles != undefined) {

        this.fileInfoUploads.push({
          blobName: response.blobName,
          fileComment: '',
          name: response.fileName
        } as IFileInfoUpload);

        this.filesSize = 0;
        this.disalbledUpload = false;
        this.progress = 0;
      }

      this.currentFiles = undefined;
      this.fileNames = this.placeholderText;
      this.progress = 0;
      this.disalbledAddNewFileUpload = false;
    }
  }

  convertBytes(bytesNumber: number): string {
    return UnitConverter.formatBytes(bytesNumber);
  }

  formatBytesToMb(bytesNumber: number): string {
    return UnitConverter.formatBytes(bytesNumber);
  }
}
