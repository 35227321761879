import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient) { }

  httpGet<Type>(url: string): Observable<Type> {
    return this.httpClient.get<Type>(url).pipe(
      catchError(this.handleError)
    );
  }

  httpPost<Type>(url: string, body: any): Observable<Type> {
    return this.httpClient.post<Type>(url, body).pipe(
        catchError(this.handleError)
      );
  }

  handleError = (err: HttpErrorResponse) => {
    let errMessage = '';
    if (err.error instanceof ErrorEvent) {
      errMessage = `An error occurred: ${err.error.message}}`;
    } else {
      errMessage = `Server returned code: ${err.status}, error message: ${err.message}`;
    }
    
    console.warn(errMessage)
    return throwError(() => errMessage);
  }
}
