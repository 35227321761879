<div class="content-container">
    <form [formGroup]="addRecordForm" id="addRecordComponent" (ngSubmit)="submitNewRecord()">
        <h2 class="edit-title icons-text">Add new SA&O record</h2>
        <div>
            <p class="text">SEARCH BY:</p>
            <div class="search-type-radio-buttons">
                <mat-radio-group aria-label="Search By:" (change)="selectSearchType($event)">
                    <mat-radio-button *ngFor="let searchType of searchTypes; index as i" value="{{searchType}}" [checked]="(i===0)">{{searchType}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <p>
                <mat-spinner [diameter]="30" class="spinner" *ngIf="wellsFindLoading"></mat-spinner>
                <mat-form-field #recordByWellAutocomplete class="autocomlete-width" appearance="fill">
                    <mat-label>{{searchTypeTxt}}</mat-label>
                    <input type="text" placeholder="{{searchPlaceholderTxt}}" aria-label="Well Name Search" matInput
                        formControlName="recordByWell" [matAutocomplete]="auto" (keyup)="onKeyUpWellSearch($event)">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let well of wellListSuggestion" [value]="well"
                            (onSelectionChange)="onSelectAutoCompleteField($event)">
                            {{well}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <span class="material-symbols-outlined"
                    matTooltip="Wildcards:&#13;&#10;^ - starts with	'^hello',&#13;&#10;$ - ends with	'hello$',&#13;&#10;* -	represents one, single character 'hel*o',&#13;&#10;? -	represents zero, one, or multiple characters	'h?o'"
                    matTooltipClass="multiline-tooltip" matTooltipPosition="above">
                    help
                </span>
            </p>
            <p class="text">OR SELECT BY HIERARCHY</p>
        </div>
        <div class="form-grid">
            <p>
                <mat-form-field>
                    <mat-label>Asset Team</mat-label>
                    <mat-select #assetTeamDropdown formControlName="assetTeam"
                        (selectionChange)="formValidator('assetTeam')" id="assetTeamDropdown">
                        <mat-option *ngFor="let basin of basinRecords" [value]="basin"
                            (onSelectionChange)="onBasinSelect($event)">
                            {{basin.data.basinName}}
                        </mat-option>
                    </mat-select>
                    <span class="text-danger" *ngIf="visibleFormWarning.assetTeam">
                        Asset Team value is required
                    </span>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label>Dev Area</mat-label>
                    <mat-select #devAreaDropdown formControlName="devArea" (selectionChange)="formValidator('devArea')">
                        <mat-option *ngFor="let devArea of devAreaDropdownList" [value]="devArea"
                            (onSelectionChange)="onDevAreaSelect($event)">
                            {{devArea.data.fieldName}}
                        </mat-option>
                    </mat-select>
                    <span class="text-danger" *ngIf="visibleFormWarning.devArea">
                        Dev Area value is required
                    </span>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label>Sub Dev Area</mat-label>
                    <mat-select #subDevAreaDropdown formControlName="subDevelopmentArea"
                        (selectionChange)="formValidator('subDevelopmentArea')">
                        <mat-option *ngFor="let subDevArea of subDevAreaDropdownList" [value]="subDevArea"
                            (onSelectionChange)="onSubDevAreaSelect($event)">
                            {{subDevArea.data.fieldName}}
                        </mat-option>
                    </mat-select>
                    <span class="text-danger" *ngIf="visibleFormWarning.subDevelopmentArea">
                        Sub Dev Area value is required
                    </span>
                </mat-form-field>
            </p>
            <p>
                <mat-spinner [diameter]="25" class="spinner" *ngIf="padsLoading"></mat-spinner>
                <mat-form-field>
                    <mat-label>Pad Name</mat-label>
                    <mat-select #padDropdown formControlName="padName" (selectionChange)="formValidator('padName')">
                        <mat-option *ngFor="let pad of padsDropdownList" [value]="pad"
                            (onSelectionChange)="onPadSelect($event)">
                            {{pad}}
                        </mat-option>
                    </mat-select>
                    <span class="text-danger" *ngIf="visibleFormWarning.padName">
                        Pad Name value is required
                    </span>
                </mat-form-field>
            </p>
            <p>
                <mat-spinner [diameter]="25" class="spinner" *ngIf="wellsLoading"></mat-spinner>
                <mat-form-field>
                    <mat-label>Well Name (MUDI)</mat-label>
                    <mat-select #wellDropdown formControlName="wellName" (selectionChange)="formValidator('wellName')">
                        <mat-option *ngFor="let wellForPad of wellsDropdownList" [value]="wellForPad"
                            (onSelectionChange)="onWellSelect($event)">
                            {{wellForPad.wellName}}
                        </mat-option>
                    </mat-select>
                    <span class="text-danger" *ngIf="visibleFormWarning.wellName">
                        Well Name (MUDI) value is required
                    </span>
                </mat-form-field>
            </p>
        </div>
        <div>
            <p class="text-danger" *ngIf="visibleFormWarning.assignedSaoOptions">
                Planned SA&O or Acquired SA&O are required
            </p>
        </div>
        <!-- options component -->
        <sao-sao-options #saoOptionsComponent></sao-sao-options>
        <div *ngIf="submitLoading" class="overlay">
            <div><mat-spinner [diameter]="75"></mat-spinner></div>
            <div class="spinner-txt">Adding record...</div>
        </div>
        <div class="edit-record-buttons">
            <a>
                <button (click)="cancel()" class="mat-raised-button mat-primary" type="button"
                    id="cancel-button">Cancel</button>
            </a>
            <a>
                <button class="mat-raised-button mat-primary" type="submit" id="submit-button">Submit Record</button>
            </a>
        </div>
    </form>
</div>