import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { IFileUploadBlob } from 'src/app/models/files/file-upload-blob-response.model';
import Utils from 'src/app/util/utils';
import { IDatasetsVersion, IVersion, UploadType } from 'src/app/models/Dto/datasets-version.model';
import { IAssignedSaoActivity } from 'src/app/models/osdu/sureveillance-record.model';
import { DataService } from 'src/app/services/data.service';
import { ISaoFlattenedRecordDataSource } from 'src/app/models/sao-record-flat-table.model';
import { IAggregatedDatasetsPayload } from 'src/app/models/payloads/aggregated-datasets-payload.model';
import { CalAngularService } from '@cvx/cal-angular';
import { PayloadDataService } from 'src/app/services/payload-data.service';

@Component({
  selector: 'sao-options-file',
  templateUrl: './sao-options-file.component.html',
  styleUrls: ['./sao-options-file.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class SaoOptionsFileComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatSort)
  private readonly sort!: MatSort;
  @Input()
  assignedSaoActivity!: IAssignedSaoActivity;
  @Input()
  saoFlattenedRecordDataSource!: ISaoFlattenedRecordDataSource;

  private subscriptions: Subscription[] = [];
  previewViewerAllowExt: string[] = ['PPT', 'PPTX', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PDF'];
  fileInfoDataSource: any;
  datasetsDataSource: MatTableDataSource<IDatasetsVersion> = new MatTableDataSource<IDatasetsVersion>();
  sortingColumn: string = "";
  sortingDirection: string = "";
  optionGuid: string = '';
  showPlusIcon: boolean = false;
  aggregatedDatasetsVersions: IDatasetsVersion[] = [];
  aggDatasetsVerLoading: boolean = false;
  userName?: string;

  constructor(private matDialog: MatDialog,
    private service: DataService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private readonly calService: CalAngularService,
    private payloadDataService: PayloadDataService) { }

  ngOnInit() {
    this.getUserName();
    this.setPlusIconVisibility();

    if (this.router.url.includes('/edit')) {
      this.getAggregatedDatasets();
    }
  }

  ngAfterViewInit() {
    this.assignedSaoActivity.activityParentId = crypto.randomUUID();
    this.addSorting();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.changeDetectorRef.detectChanges();
  }

  getUserName() {
    let isUserSignedInSub = this.calService.isUserSignedIn().subscribe((value: boolean) => {
      if (value) {
        let currentUserProfile = this.calService.cvxClaimsPrincipal;
        this.userName = currentUserProfile.name;
      }
    });
    this.subscriptions.push(isUserSignedInSub);
  }

  getAggregatedDatasets() {
    let activityId = this.assignedSaoActivity?.activityId;
    let datasetsIds = this.assignedSaoActivity?.datasets;
    let payload = <IAggregatedDatasetsPayload>{
      activityId: activityId,
      datasets: datasetsIds
    }
    if (datasetsIds !== undefined && datasetsIds?.length > 0) {
      this.aggDatasetsVerLoading = true;
      let sub = this.service.GetAggregatedDatasetsVersions(payload)
        .subscribe({
          next: (response) => {
            this.updateActivityParentIdForDatasets(response);
            this.aggregatedDatasetsVersions = response;
            this.updateDatasetsView();
          },
          error: (error) => {
            this.aggDatasetsVerLoading = false;
            console.error('HTTP Error', error);
          },
          complete: () => {
            this.aggDatasetsVerLoading = false;
            console.log('HTTP request Aggregeted Datasets completed.');
          }
        });
      this.subscriptions.push(sub);
    }
  }

  setPlusIconVisibility() {
    if (this.router.url.includes('/add') || this.router.url.includes('/edit')) {
      this.showPlusIcon = true;
    } else {
      this.showPlusIcon = false;
    }
  }

  updateDatasetsView(): void {
    this.datasetsDataSource = new MatTableDataSource<IDatasetsVersion>(this.aggregatedDatasetsVersions);
    this.payloadDataService.setDatasetsVersionData(this.datasetsDataSource.data);
    this.changeDetectorRef.detectChanges();
  }

  updateActivityParentIdForDatasets(datasets: IDatasetsVersion[]): void {
    datasets.forEach(dataset => {
      dataset.activityParentId = this.assignedSaoActivity.activityParentId;
    });
  }

  clearDatasetsView(): void {
    this.datasetsDataSource = new MatTableDataSource<IDatasetsVersion>([]);
    this.payloadDataService.setDatasetsVersionData(this.datasetsDataSource.data);
    this.changeDetectorRef.detectChanges();
  }

  clearDatasetsAddEditView(): void {
    this.datasetsDataSource = new MatTableDataSource<IDatasetsVersion>([]);
    this.payloadDataService.setDatasetsVersionData(this.datasetsDataSource.data);
    this.changeDetectorRef.detectChanges();
  }

  addNewFileToModel(fileUploadResult: IFileUploadBlob[]) {
    if (fileUploadResult && fileUploadResult.length > 0) {
      let datasetsVersions: IDatasetsVersion[] = [];
      let activityParentId = this.assignedSaoActivity.activityParentId;

      fileUploadResult.forEach((fileUpload: IFileUploadBlob) => {
        let parentId: string = crypto.randomUUID();
        let datasetsVersion = <IDatasetsVersion>{
          activityId: null,
          activityParentId: activityParentId,
          parentId: parentId,
          fileName: fileUpload.fileName,
          versions: [<IVersion>{
            parentId: parentId,
            datasetId: null,
            description: fileUpload.fileComment,
            name: Utils.generateUniqueFileName(fileUpload.fileName),
            blobReference: fileUpload.blobName,
            signedUrl: null,
            createUser: this.userName,
            createTime: new Date(),
            totalSize: 0
          }]
        };

        datasetsVersions.push(datasetsVersion);
      });

      this.datasetsDataSource.data = this.datasetsDataSource.data.concat(datasetsVersions);
      this.payloadDataService.setDatasetsVersionData(this.datasetsDataSource.data);
    }
  }

  addNewFileVersionToModel(fileUploadResult: IFileUploadBlob[], parentId: string) {
    if (fileUploadResult && fileUploadResult.length > 0) {
      fileUploadResult.forEach((fileUpload: IFileUploadBlob) => {

        let index = this.datasetsDataSource.data.findIndex(x => x.parentId === parentId);

        if (index > -1) {
          let version = <IVersion>{
            parentId: parentId,
            datasetId: null,
            description: fileUpload.fileComment,
            name: Utils.generateUniqueFileName(fileUpload.fileName),
            blobReference: fileUpload.blobName,
            signedUrl: null,
            createUser: this.userName,
            createTime: new Date(),
            totalSize: 0
          };
          let arrayToExtend = this.datasetsDataSource.data[index].versions;
          this.datasetsDataSource.data[index].versions = [...arrayToExtend, version];
        }
      });

      this.datasetsDataSource = new MatTableDataSource<IDatasetsVersion>(this.datasetsDataSource.data);
      this.payloadDataService.setDatasetsVersionData(this.datasetsDataSource.data);
      this.changeDetectorRef.detectChanges();
    }
  }

  deleteVersionEventEmit(fileVersionUpdate: IVersion) {
    let versionIndex = this.datasetsDataSource.data.findIndex(x => x.parentId === fileVersionUpdate.parentId);
    let activityId = this.datasetsDataSource.data[versionIndex]?.activityId;
    if (versionIndex > -1) {
      let removedVersion = this.datasetsDataSource.data[versionIndex].versions.filter(x => x.name !== fileVersionUpdate.name);
      this.datasetsDataSource.data[versionIndex].versions = removedVersion;
      if (removedVersion?.length === 0) {
        let newDatasource = this.datasetsDataSource.data.filter(x => x.parentId !== fileVersionUpdate.parentId);
        this.datasetsDataSource = new MatTableDataSource<IDatasetsVersion>(newDatasource);
      } else {
        this.datasetsDataSource = new MatTableDataSource<IDatasetsVersion>(this.datasetsDataSource.data);
      }

      this.payloadDataService.setDatasetsVersionData(this.datasetsDataSource.data);
      this.payloadDataService.setDeleteDatasetsData(activityId, fileVersionUpdate?.datasetId);
    }

    this.changeDetectorRef.detectChanges();
  }

  uploadNewFile(evt: Event) {
    let dialogRef = this.matDialog.open(FileUploadComponent, {
      width: '60%',
      panelClass: 'upload-file',
      data: {
        datasetsVersion: this.datasetsDataSource.data.length === 0 ? [<IDatasetsVersion>{}] : this.datasetsDataSource.data,
        uploadType: 'NewFile' as UploadType
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((fileUploadResult: IFileUploadBlob[]) => {
      this.addNewFileToModel(fileUploadResult);
      this.changeDetectorRef.detectChanges();
    });
  }

  uploadNewFileVersion(evt: Event, datasetsVersion: IDatasetsVersion) {
    let dialogRef = this.matDialog.open(FileUploadComponent, {
      width: '60%',
      panelClass: 'upload-file',
      data: {
        datasetsVersion: [datasetsVersion],
        uploadType: 'NewVersion' as UploadType
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((fileUploadResult: IFileUploadBlob[]) => {
      this.addNewFileVersionToModel(fileUploadResult, datasetsVersion.parentId);
      this.changeDetectorRef.detectChanges();
    });
  }

  addSorting() {
    this.datasetsDataSource.sort = this.sort;
    this.datasetsDataSource.sortingDataAccessor =
      (row: any, columnName: string): string => row.data[columnName];
  }

  getFileVersions(datasetsVersions: IDatasetsVersion): IVersion[] | undefined {
    if (datasetsVersions === null || datasetsVersions === undefined) {
      return undefined;
    }

    return datasetsVersions.versions;
  }

  fileActivityColumns = [
    {
      columnDef: "fileName",
      header: "Attachments",
      cell: (activity: IDatasetsVersion) => `${activity.fileName}`
    }
  ]

  displayedFilesOptCols = this.fileActivityColumns.map(c => c.columnDef);

}



