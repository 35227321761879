import { Action } from "@ngrx/store";

export enum LoginActionTypes {
    LOGIN = '[LOGIN] Login',
    LOGGEDIN = '[LOGIN] LoggedIn',
    LOGOUT = '[LOGIN] LoggedOut'
}

export class Login implements Action {
    readonly type = LoginActionTypes.LOGIN;
}

export class LoggedIn implements Action {
    readonly type = LoginActionTypes.LOGGEDIN;
}

export class LoggedOut implements Action {
    readonly type = LoginActionTypes.LOGOUT;
}