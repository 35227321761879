<div class="main-about-container">
    <mat-card-content class="about-container">
        <div class="full-width-image-container">
            <div class="image-container">
                <img alt="OSDU image" src="../../../assets/OSDU.png" />
            </div>
        </div>
        <p>
            The Open Subsurface Data Universe (OSDU) is an open subsurface reference architecture designed by the Open
            Group
            OSDU Forum. Chevron's implementation of this data foundation will sit in our private Azure tenant and will
            utilize
            common cloud services to ingest, search, discover, deliver and enrich data for subsurface workflows, and
            subsurface
            applications.
        </p>
        <p>
            More details about SA&O SOR to come...
        </p>
        <p>
            For additional information about OSDU, visit
            <a rel="noopener noreferrer" href="https://www.opengroup.org/osdu/forum-homepage" target="_blank">OSDU Forum
            </a>
            and
            <a rel="noopener noreferrer"
                href="https://dev.azure.com/chevron/ETC-SUB-CSDU/_wiki/wikis/ETC-SUB-CSDU.wiki/7597/Home"
                target="_blank">SubSurface Digital Transformation
            </a>
        </p>
    </mat-card-content>
</div>