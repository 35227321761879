import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { MatDateFormats, NativeDateAdapter } from "@angular/material/core";

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: { dateInput: 'yyyy-MM-dd' },
  display: {
    dateInput: 'yyyy-MM-dd',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'yyyy-MMMM-dd',
    monthYearA11yLabel: 'MMMM yyyy'
  }
};

@Injectable()
export class PickDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: Object): string {
    if (typeof displayFormat === 'string' || displayFormat instanceof String) {
      return formatDate(date, displayFormat as string, this.locale);;
    } else {
      return date.toDateString();
    }
  }
}
