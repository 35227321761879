<div class="cvx-light-blue-border sao-option-record-add-dialog">
  <div class="scroll-div">

    <table mat-table [dataSource]="addOptsDataSource" class="mat-elevation-z8" matSort aria-label="SA&O">
      <ng-container *ngFor="let column of availableSaoOptColumns" [matColumnDef]="column.columnDef">
        <ng-container *ngIf="column.columnDef == 'actions'; else saoOption">
          <th mat-header-cell *matHeaderCellDef>
            <p>{{column.header}}</p>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
              (change)="$event ? saoOptionsTableMultiselection.toggle(row) : null"
              [checked]="saoOptionsTableMultiselection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-template #saoOption>
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <mat-form-field>
              <mat-label>Quick search</mat-label>
              <input #quickSearchInput matInput (keyup)="applyQuickSearchFilter($event)" />
            </mat-form-field>
            <div>
              {{column.header}}
            </div>
          </th>
          <td mat-cell *matCellDef="let row">
            {{column.cell(row)}}
          </td>
        </ng-template>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedAvailableSaoOptCols"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedAvailableSaoOptCols;" (click)="onSaoOptionsRowClick(row)"
        [ngClass]="{'highlight-selected' : saoOptionsTableMultiselection.isSelected(row)}">
      </tr>
    </table>
  </div>
  <div class="add-option-buttons">
    <button type="button" class="mat-raised-button mat-primary" [mat-dialog-close] id="cancel-button">Cancel</button>
    <button class="mat-raised-button mat-primary" [mat-dialog-close]="saoOptionsTableMultiselection.selected"
      type="submit" id="submit-button">Add Option</button>
  </div>
</div>
