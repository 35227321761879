<div class="container">
  <mat-card-content>
    <mat-divider inset class="divider-top"></mat-divider>
    <div class="home-page-content">
      <div class="home-page-cardnav-container">
        <sao-sor-menu-card class="card-col" *ngFor="let menuCard of menuCards" [menuCardTitle]="menuCard.menuCardTitle"
          [menuCardSubtitle]="menuCard.menuCardSubtitle" [menuCardIconId]="menuCard.menuCardIconId"
          [menuCardButtonText]="menuCard.menuCardButtonText"
          [menuCardRouterLink]="menuCard.menuCardRouterLink"></sao-sor-menu-card>
      </div>
    </div>
    <mat-divider inset class="divider-bottom"></mat-divider>
  </mat-card-content>
</div>