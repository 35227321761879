import { Store, select } from "@ngrx/store";
import { IAdHierarchyDevArea } from "../models/asset-development-hierarchy/ad-hierarchy-dev-area.model";
import { IAdHierarchySubDevArea } from "../models/asset-development-hierarchy/ad-hierarchy-sub-dev-area.model";
import { IField, IFieldData, IFieldNameAlias } from "../models/osdu/field.model";
import { IGeoContext } from "../models/osdu/geo-context.model";
import { IBasinsCacheState } from "../store/reducers/fetch-basins.reducer";
import { Observable } from "rxjs";
import { IFieldsCacheState } from "../store/reducers/fetch-fields.reducer";
import { IAppState } from "../store/reducers";
import { IBasin } from "../models/osdu/basin.model";

export class OsduFieldAdapter {

    private assetTeamsCacheState$: Observable<IBasinsCacheState>;
    private fieldsCacheState$: Observable<IFieldsCacheState>;

    private basinAssetTeams: IBasin[] = [];
    private fields: IField[] = [];

    constructor(store: Store<IAppState>) {
        this.assetTeamsCacheState$ = store.pipe(select('basinsCacheState'));
        this.fieldsCacheState$ = store.pipe(select('fieldsCacheState'));

        this.getBasinAssetTeams();
        this.getFields();
    }

    private getBasinAssetTeams() {
        this.assetTeamsCacheState$.subscribe(result => {
            this.basinAssetTeams = result.basins;
        })
    }

    private getFields() {
        this.fieldsCacheState$.subscribe(result => {
            this.fields = result.fields;
        })
    }

    convertDevAreasToOsduFields(devAreas: IAdHierarchyDevArea[]): IField[] {

        let fields: IField[] = [];
        devAreas.forEach(devArea => {
            fields.push(
                <IField> {
                    id: devArea.id.toString(),
                    data: <IFieldData> {
                        fieldName: devArea.description,
                        fieldId: devArea.id.toString(),
                        nameAliases: <IFieldNameAlias[]>[
                            <IFieldNameAlias>{
                                aliasName: devArea.code,
                                aliasNameTypeId: "DevelopmentAreaCode"
                            }
                        ],
                        geoContexts: <IGeoContext[]>[
                            <IGeoContext>{
                                basinId: this.basinAssetTeams.find(b=>b.data.basinName == devArea.parentDescription)?.id
                            }
                        ]
                    }
                }
            )
        });

        return fields;
    }

    convertSubDevAreasToOsduFields(subDevAreas: IAdHierarchySubDevArea[]): IField[] {
        let fields: IField[] = [];
        subDevAreas.forEach(subDevArea => {
            fields.push(
                <IField>{
                    data: <IFieldData> {
                        fieldName: subDevArea.name,
                        geoContexts: <IGeoContext[]>[
                            <IGeoContext>{
                                basinId: subDevArea.assetTeamId.toString(),
                                fieldId: subDevArea.developmentAreaId.toString()
                            }
                        ]
                    }
                }
            );
        });

        return fields;
    }

}
