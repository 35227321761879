import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
import { FetchTargetFormationsActions, TargetFormationsRequestFailed, TargetFormationsRequestSuccess } from "../actions/fetch-tgt-formations.actions";
import { IAppState } from "../reducers";

@Injectable()
export class FetchTargetFormationsEffects {
    constructor(private actions$: Actions,
        private dataService: DataService,
        private store: Store<IAppState>) { }

    public fetchTargetFormations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchTargetFormationsActions.TARGET_FORMATIONS_REQUEST),
            exhaustMap(() => {
                return this.dataService.getAllTargetFormations().pipe(
                    map(result => {
                        return new TargetFormationsRequestSuccess({ targetFormations: result, targetFormationsLoading: false, targetFormationsRequestError: null });
                    }))
            }),
            catchError((err: Error) => {
                let error = err.message;
                this.store.dispatch(new TargetFormationsRequestFailed({ targetFormations: [], targetFormationsLoading: false, targetFormationsRequestError: error }));
                throw err;
            })
        )
    }
    );
}