<a [routerLink]="menuCardRouterLink">
    <mat-card>
      <div>
        <mat-card-header>
          <span mat-card-avatar class="material-symbols-outlined menu-card-avatar-size"> biotech </span>
          <mat-card-title>{{ menuCardTitle }}</mat-card-title>
          <mat-card-subtitle>{{ menuCardSubtitle }}</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <div class="body-image-container">
          <span class="material-symbols-outlined body-icon"> {{ menuCardIconId }} </span>
        </div>
      </div>
      <mat-card-actions>
        <a mat-raised-button>
          {{ menuCardButtonText }}
        </a>
      </mat-card-actions>
    </mat-card>
  </a>