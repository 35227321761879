import { Injectable } from "@angular/core"

@Injectable({
  providedIn: 'root'
})

export default class UnitConverter{
  public static formatBytes(bytes: number, decimals = 2): string {
      if (!+bytes) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    public static formatBytesToMb(bytes: number, decimals = 2): string {
      if (!+bytes) return '0 MB';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;

      let mb = bytes / Math.pow(k, 2);

      if(mb < 0.01){
        let kb = bytes / k;
        return `${parseFloat((kb).toFixed(dm))} KiB`
      }

      return `${parseFloat((mb).toFixed(dm))} MiB`
    }
}
