export interface IFindWellPayload {
    SearchPhrase?: string | null;
    SearchType: SearchType;
}

export enum SearchTypes {
    PAD = 'Pad',
    WELL = 'Well',
    API = 'API'
}

export enum SearchTypesLabels {
    PAD_NAME = 'Pad Name',
    WELL_NAME = 'Well Name',
    API_NUMBER = 'API Number'
}

export type SearchType = SearchTypes.PAD | SearchTypes.WELL | SearchTypes.API;
