import { IAssignedSaoActivity, ISurevillanceRecord } from "../models/osdu/sureveillance-record.model";
import { ISaoFlattenedRecordDataSource } from "../models/sao-record-flat-table.model";

export function nameof<T>(key: keyof T, instance?: T): keyof T {
    return key;
}

export function formatTgtFormation(value: string) {
    return value.split(' ')[0];
}

/**
 * @param records array of the OSDU SA&O records
 * @param saoOptions array of the OSDU SA&O activity records
 * @returns Returns flattened representation of the SA&O OSDU records, each element of the array represents single SA&O activity from the AssignedSaoOptionsArray of the original record.
 */
export function flattenSaoRecords(records: ISurevillanceRecord[]): ISaoFlattenedRecordDataSource[] {
    let flattenedData: ISaoFlattenedRecordDataSource[] = [];

    records.forEach(record => record.data.assignedSaoActivities.forEach(assignedActivity => {
        flattenedData.push({
            ...record,
            data: {
                ...record.data,
                assignedSaoActivities: [...record.data.assignedSaoActivities],
                assignedSaoActivity: <IAssignedSaoActivity>{
                  ...assignedActivity,
                  expanded: false
                }, //record.data.assignedSaoOptions, //left for compatibility with base record, allows to associate with other activites for the same well on the map
                activityStatus: assignedActivity.activityStatus,
                activityStatusId: assignedActivity.activityStatusId,
                activityTemplateID: assignedActivity.activityTemplateId,
                activityTemplateName: assignedActivity.activityTemplateName
            }
        })
    }))

    return flattenedData;
}
