import { Injectable, OnDestroy } from "@angular/core";
import { IBasinsCacheState } from "../store/reducers/fetch-basins.reducer";
import { BehaviorSubject, Observable, Subscription, combineLatestWith } from "rxjs";
import { Store, select } from "@ngrx/store";
import { IAppState } from "../store/reducers";
import { IBasin } from "../models/osdu/basin.model";
import { IField } from "../models/osdu/field.model";
import { IFieldsCacheState } from "../store/reducers/fetch-fields.reducer";
import { ITargetFormationsCacheState } from "../store/reducers/fetch-tgt-formations.reducer";
import { ITargetFormation } from "../models/osdu/target-formation.model";
import { ISubFieldsCacheState } from "../store/reducers/fetch-sub-fields.reducer";
import { IActivityTemplatesCacheState } from "../store/reducers/fetch-activity-templates.reducer";
import { IActivityTemplate } from "../models/osdu/activity-template.model";

export interface IBasinState {
  basins: IBasin[];
  loading: boolean
}

export interface IFieldState {
  fields: IField[],
  loading: boolean
}

export interface ITargetFormationState {
  tgtFormations: ITargetFormation[];
  loading: boolean
}

export interface IActivityTemplateState {
  activityTemplates: IActivityTemplate[],
  loading: boolean
}

@Injectable({
  providedIn: 'root'
})
export class FilterService implements OnDestroy {

  private subscriptions: Subscription[] = [];

  private basinsSubject: BehaviorSubject<IBasinState> = new BehaviorSubject<IBasinState>(<IBasinState>{
    basins: [],
    loading: true
  });
  private devAreasSubject: BehaviorSubject<IFieldState> = new BehaviorSubject<IFieldState>(<IFieldState>{
    fields: [],
    loading: true
  });
  private subDevAreasSubject: BehaviorSubject<IFieldState> = new BehaviorSubject<IFieldState>(<IFieldState>{
    fields: [],
    loading: true
  });
  private tgtFormationsSubject: BehaviorSubject<ITargetFormationState> = new BehaviorSubject<ITargetFormationState>(<ITargetFormationState>{
    tgtFormations: [],
    loading: true
  });
  private activityTemplatesSubject: BehaviorSubject<IActivityTemplateState> = new BehaviorSubject<IActivityTemplateState>(<IActivityTemplateState>{
    activityTemplates: [],
    loading: true
  });

  private basinsCacheState$: Observable<IBasinsCacheState>;
  private basinsLoading: boolean = false;

  private fieldsCacheState$: Observable<IFieldsCacheState>;
  private fieldsLoading: boolean = false;
  private fieldOsduRecords: IField[] = [];

  private subFieldsCacheState$: Observable<ISubFieldsCacheState>;

  private subFieldOsduRecords: IField[] = [];

  private tgtFormationsCacheState$: Observable<ITargetFormationsCacheState>;
  private tgtFormationsLoading: boolean = false;

  private activityTemplatesCacheState$: Observable<IActivityTemplatesCacheState>;
  private saoOptionsLoading: boolean = false;

  constructor(private store: Store<IAppState>) {
    this.basinsCacheState$ = store.pipe(select('basinsCacheState'));
    this.fieldsCacheState$ = store.pipe(select('fieldsCacheState'));
    this.tgtFormationsCacheState$ = store.pipe(select('targetFormationsCacheState'));
    this.activityTemplatesCacheState$ = store.pipe(select('activityTemplatesCacheState'));
    this.subFieldsCacheState$ = store.pipe(select('subFieldsCacheState'))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getBasinsObservable(): Observable<IBasinState> {
    this.getBasins();
    return this.basinsSubject.asObservable();
  }

  getDevAreasObservable(): Observable<IFieldState> {
    this.getFields();
    return this.devAreasSubject.asObservable();
  }

  getSubDevAreasObservable(): Observable<IFieldState> {
    return this.subDevAreasSubject.asObservable();
  }

  getTargetFormationsObservable(): Observable<ITargetFormationState> {
    this.getTargetFormations();
    return this.tgtFormationsSubject.asObservable();
  }

  getActivityTemplatesObservable(): Observable<IActivityTemplateState> {
    this.getActivityTemplates();
    return this.activityTemplatesSubject.asObservable();
  }

  private getBasins() {
    let sub = this.basinsCacheState$.subscribe({
      next: (state) => {
        this.basinsLoading = state.basinsLoading;
        if (!state.basinsLoading) {
          this.basinsLoading = state.basinsLoading;
          let stateSorted = [...state.basins];
          stateSorted.sort((a, b) => (a.data.basinName < b.data.basinName) ? -1 : 1);
          this.basinsSubject.next(<IBasinState>{
            basins: stateSorted,
            loading: this.basinsLoading
          });
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        console.log('Basins fetch completed');
      },
    });
    this.subscriptions.push(sub);
  }

  private getFields(){
    let sub = this.fieldsCacheState$.pipe(
      combineLatestWith(this.subFieldsCacheState$)
    )
    .subscribe(([fieldsCacheContent, subFieldsCacheContent]) => {
      this.fieldsLoading = fieldsCacheContent.fieldsLoading && subFieldsCacheContent.subFieldsLoading;
      this.fieldOsduRecords = [...fieldsCacheContent.fields].sort((a, b) => (a.data.fieldName < b.data.fieldName) ? -1 : 1);
      this.subFieldOsduRecords = [...subFieldsCacheContent.subFields].sort((a, b) => (a.data.fieldName < b.data.fieldName) ? -1 : 1);

      this.devAreasSubject.next(<IFieldState>{
        fields: this.fieldOsduRecords,
        loading: this.fieldsLoading
      });
      this.subDevAreasSubject.next(<IFieldState>{
        fields: this.subFieldOsduRecords,
        loading: this.fieldsLoading
      });
    })

    this.subscriptions.push(sub);
  }

  private getTargetFormations(): void {
    let sub = this.tgtFormationsCacheState$.subscribe({
      next: (state) => {
        this.tgtFormationsLoading = state.targetFormationsLoading;
        let formationsSorted = [];
        if (!state.targetFormationsLoading) {
          formationsSorted = [...state.targetFormations];
          formationsSorted.sort((a, b) => (a.data.Name < b.data.Name) ? -1 : 1);
          this.tgtFormationsSubject.next(<ITargetFormationState>{
            tgtFormations: formationsSorted,
            loading: this.tgtFormationsLoading
          });
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        console.log('Target formations fetch completed');
      }
    });
    this.subscriptions.push(sub);
  }

  private getActivityTemplates() {
    let sub = this.activityTemplatesCacheState$.subscribe({
      next: (state) => {
        this.saoOptionsLoading = state.activityTeplatesLoading;
        if (!this.saoOptionsLoading) {
          let toSort = [...state.activityTeplates];
          toSort.sort((a, b) => (a.data.name < b.data.name) ? -1 : 1);
          this.activityTemplatesSubject.next(<IActivityTemplateState>{
            activityTemplates: toSort,
            loading: this.saoOptionsLoading
          });
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        console.log('SAO Activity Templates fetch completed');
      }
    });
    this.subscriptions.push(sub);
  }
}
