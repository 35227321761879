import { Injectable } from "@angular/core";
import { IAdHierarchyPath } from "src/app/models/asset-development-hierarchy/ad-hierarchy-path.model";
import { SearchType, SearchTypes } from "src/app/models/payloads/find-well-payload.model";
import { AddRecordsComponent } from "./add-records.component";

@Injectable({
  providedIn: 'root'
})
export class AddRecordService {

  fillAsseTeam(hierarchyPaths: IAdHierarchyPath[], parent: AddRecordsComponent): void {
    // set seletion on assetTeam
    let basinName = hierarchyPaths[0].assetTeam;
    parent.basinRecords.forEach(basin => {
      if (basin.data.basinName == basinName) {
        parent.addRecordForm.controls['assetTeam'].setValue(basin);
        parent.assetTeamSelected = basin;
        parent.addRecordForm.updateValueAndValidity();
      }
    });
    parent.devAreaDropdownControl.disabled = false;
  }

  fillDevArea(hierarchyPaths: IAdHierarchyPath[], parent: AddRecordsComponent): void {
    // set devArea list for selected assetTeam
    parent.fieldOsduRecords.forEach(field => {
      if (field.data.geoContexts[0]?.basinId == parent.assetTeamSelected?.id) {
        parent.devAreaDropdownList.push(field);
      }
    });

    // set seletion on devArea
    let devAreaName = hierarchyPaths[0].devArea;
    parent.fieldOsduRecords.forEach(devArea => {
      if (devArea.data.fieldName == devAreaName) {
        parent.addRecordForm.controls['devArea'].setValue(devArea);
        parent.devAreaSelected = devArea;
        parent.addRecordForm.updateValueAndValidity();
      }
    });

    parent.subDevAreaDropdownControl.disabled = false;
  }

  fillSubDevArea(hierarchyPaths: IAdHierarchyPath[], parent: AddRecordsComponent): void {
    // set subDevArea list for selected devArea
    parent.subDevAreaDropdownList = parent.subFieldOsduRecords.filter(subField =>
      subField.data.geoContexts[0].basinId == parent.assetTeamSelected?.id
      && subField.data.geoContexts[0]?.fieldId == parent.devAreaSelected?.id
    );

    // set seletion on subDevArea
    let subDevAreaName = hierarchyPaths[0].subDevArea;
    parent.subDevAreaDropdownList.forEach(subDevArea => {
      if (subDevArea.data.fieldName == subDevAreaName) {
        parent.addRecordForm.controls['subDevelopmentArea'].setValue(subDevArea);
        parent.subDevAreasSelected = subDevArea;
        parent.addRecordForm.updateValueAndValidity();
      }
    });

    parent.padDropdownControl.disabled = false;
  }

  async fillPadName(hierarchyPaths: IAdHierarchyPath[], parent: AddRecordsComponent): Promise<void> {
    // set padName list for selected subDevArea
    parent.fieldForPads.SubFieldName = hierarchyPaths[0].subDevArea;
    let padResult = await parent.getPadsPromise(parent.fieldForPads);

    // set seletion on padName
    let padName = hierarchyPaths[0].padName;
    padResult.forEach(pad => {
      parent.padsDropdownList.push(pad);
      if (pad == padName) {
        parent.addRecordForm.controls['padName'].setValue(pad);
        parent.padSelected = pad;
        parent.addRecordForm.updateValueAndValidity();
      }
    });

    parent.formValidator('padName');
    parent.wellDropdownControl.disabled = false;
  }

  async fillWellName(hierarchyPaths: IAdHierarchyPath[], searchType: SearchType, parent: AddRecordsComponent): Promise<void> {
   // set wellName list for selected padName
   parent.padForWells.PadName = hierarchyPaths[0].padName;
   let wellResult = await parent.getWellsPromise(parent.padForWells);

   // set seletion on wellName
   let wellName: string | undefined | null;
   if(searchType !== SearchTypes.PAD){
    wellName = hierarchyPaths[0].wellName;
   }

   wellResult.forEach(wellForPad => {
    parent.wellsDropdownList.push(wellForPad);
     if (wellForPad.wellName === wellName) {
      parent.addRecordForm.controls['wellName'].setValue(wellForPad);
      parent.wellSelected = wellForPad;
      parent.addRecordForm.updateValueAndValidity();
     }
   });

   if(searchType !== SearchTypes.PAD){
    parent.formValidator('wellName');
   } else {
    parent.formValidator('assetTeam', 'devArea', 'subDevelopmentArea', 'padName');
   }

  }
}
