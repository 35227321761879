import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { IAppState } from "../reducers";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { DataService } from "src/app/services/data.service";
import { FetchSurveillanceRecordsActions as FetchSurveillanceDataActions, SurvreillaceDataRequestSuccess as SurveillanceDataRequestSuccess, SurvreillaceDataRequestFailed as SurveillanceDataRequestFailed } from "../actions/fetch-surveillance-data-action";
import { catchError, exhaustMap, map, throwError } from "rxjs";

@Injectable()
export class FetchSurveillanceDataEffects {
  constructor(private actions$: Actions,
    private dataService: DataService,
    private store: Store<IAppState>) { }

  public fetchSurveillanceData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FetchSurveillanceDataActions.SUREVEILLANCE_RECORDS_REQUEST),
      exhaustMap(async () => {
        let response = await this.dataService.GetSurveillanceData();
        if (response && response?.runtimeStatus === 'Completed' &&
        response?.customStatus !== 'Failed' && response?.output) {
          return new SurveillanceDataRequestSuccess({
            data: response.output,
            dataLoading: false,
            dataRequestError: null
          });
        } else {
          let err = new Error(`Getting Surveillance data request failed.`);
          console.error(err);
          return new SurveillanceDataRequestFailed({
            data: [],
            dataLoading: false,
            dataRequestError: err.message
          });
        }
      }),
      catchError((err: Error) => {
        let errMessage = err.message;
        this.store.dispatch(new SurveillanceDataRequestFailed({
          data: [],
          dataLoading: false,
          dataRequestError: errMessage
        }));
        throw err;
      })
    );
  });

}
