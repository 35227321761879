import { ISurevillanceRecord } from "src/app/models/osdu/sureveillance-record.model";
import { FetchSurveillanceDataActions, FetchSurveillanceRecordsActions } from "../actions/fetch-surveillance-data-action";

export interface ISurveillanceDataState {
    data: ISurevillanceRecord[],
    dataLoading: boolean,
    dataRequestError: any
}

const initialState: ISurveillanceDataState = {
    data: [],
    dataLoading: false,
    dataRequestError: null
}

export function surveillanceDataReducer(state: ISurveillanceDataState = initialState, action: FetchSurveillanceDataActions ){
    switch(action.type) {
        case FetchSurveillanceRecordsActions.SUREVEILLANCE_RECORDS_REQUEST: {
            state = <ISurveillanceDataState> {
                data: [], dataLoading: true, dataRequestError: null
            };
            return state;
        }
        case FetchSurveillanceRecordsActions.SUREVEILLANCE_RECORDS_SUCCESS: {
            state = action.payload;
            return state;
        }
        case FetchSurveillanceRecordsActions.SUREVEILLANCE_RECORDS_FAILED: {
            state = <ISurveillanceDataState> {
                data: [],
                dataLoading: false,
                dataRequestError: action.payload.dataRequestError
            };
            return state;
        }
        default: {
            return state;
        }
    }
}
