import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ISaoFlattenedRecordDataSource } from 'src/app/models/sao-record-flat-table.model';
import { ActivityStatus, IAssignedSaoActivity } from 'src/app/models/osdu/sureveillance-record.model';

@Component({
  selector: 'sao-map-options-dialog',
  templateUrl: './map-options-dialog.component.html',
  styleUrls: ['./map-options-dialog.component.scss']
})
export class MapOptionsDialogComponent implements OnInit {

  saoRecord: ISaoFlattenedRecordDataSource;
  plannedActivities: IAssignedSaoActivity[] = [];
  completedActivities: IAssignedSaoActivity[] = [];
  public optionType = ActivityStatus;
  plannedActivitiessDataSource: MatTableDataSource<IAssignedSaoActivity> = new MatTableDataSource<IAssignedSaoActivity>();
  completedActivitiesDataSource: MatTableDataSource<IAssignedSaoActivity> = new MatTableDataSource<IAssignedSaoActivity>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.saoRecord = data.saoRecord;
  }

  ngOnInit(): void {
    this.getSaoActivitiesData();
    this.getWellName();
  }

  getWellName(): string {
    let wellName = `${this.saoRecord.data?.preferredWellName} / ${this.saoRecord.data?.apiPropertyId}`;
    return wellName;
  }

  getSaoActivitiesData(): void {
    this.plannedActivities = this.saoRecord.data.assignedSaoActivities.filter(activity => activity.activityStatus === this.optionType.Planned);
    this.completedActivities = this.saoRecord.data.assignedSaoActivities.filter(activity => activity.activityStatus === this.optionType.Completed);
    this.plannedActivitiessDataSource = new MatTableDataSource<IAssignedSaoActivity>(this.plannedActivities);
    this.completedActivitiesDataSource = new MatTableDataSource<IAssignedSaoActivity>(this.completedActivities);
  }

  getFilesCount(activity: IAssignedSaoActivity): number {
    let counter = 0;
    if (activity?.datasets?.length > 0) {
      counter = activity.datasets.length
      return counter;
    }
    else {
      return counter;
    }
  }

  plannedOptColumns = [
    {
      columnDef: "plannedOptioName",
      header: "Activity"
    },
    {
      columnDef: "plannedOptioDate",
      header: "Date"
    },
    {
      columnDef: "plannedOptioAddedBy",
      header: "Added By"
    }
  ];

  displayedPlannedOptCols = this.plannedOptColumns.map(c => c.columnDef);

  acqOptionsColumns = [
    {
      columnDef: "acquiredOptioName",
      header: "Activity"
    },
    {
      columnDef: "acquiredOptioDate",
      header: "Date"
    },
    {
      columnDef: "acquiredOptioAddedBy",
      header: "Added By"
    },
    {
      columnDef: "acquiredOptioFiles",
      header: ""
    }
  ];

  displayedAcqOptCols = this.acqOptionsColumns.map(c => c.columnDef);
}
