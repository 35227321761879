import { Injectable } from "@angular/core";
import { IFilter } from "../models/filter-models/filter.model";
import { sessionStorageFilterStateKey } from "../util/constants.util";

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    setFilterModelInSessionStorage(filterModel: IFilter) {
        sessionStorage.setItem(sessionStorageFilterStateKey, JSON.stringify(filterModel, (k, v) => v === undefined ? null : v));
    }

    getFilterStateFromSessionStorage(): IFilter {
        let filterModel = sessionStorage.getItem(sessionStorageFilterStateKey);
        let parsedModel: IFilter;
        if (filterModel) {
            parsedModel = JSON.parse(filterModel) as IFilter;
            parsedModel.assetTeam = parsedModel.assetTeam === null ? undefined : parsedModel.assetTeam;
            parsedModel.devArea = parsedModel.devArea === null ? undefined : parsedModel.devArea;
        } else {
            parsedModel = <IFilter>{
                attachedFiles: false,
                quickSearch: '',
                assetTeam: undefined,
                devArea: undefined,
                subDevAreas: [],
                tgtFormations: [],
                activityTemplates: [],
                activityType: ""
            }
            this.setFilterModelInSessionStorage(parsedModel);
        }
        return parsedModel
    }
}
