<h2 mat-dialog-title class="generic-dialog-title">
  {{dialogTitle}}
</h2>
<mat-dialog-content>
  <div class="generic-dialog-content">
    <div>
      <span class="material-symbols-outlined icon-size {{iconColor}}">
        {{iconeType}}
      </span>
    </div>
    <div class="generic-dialog-text" [innerHTML]="data.content">
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button *ngIf="isButtonCancel" type="button" mat-button mat-dialog-close [mat-dialog-close]="false" class="cancel-button">Cancel</button>
  <button *ngIf="isButtonOk" type="button" mat-button [mat-dialog-close]="true" cdkFocusInitial class="ok-button">Ok</button>
  <button *ngIf="isEditButton" type="button" mat-button [mat-dialog-close]="true" cdkFocusInitial class="ok-button">Edit</button>
</mat-dialog-actions>

