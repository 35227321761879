<div *ngIf="fileLoading" class="overlay">
  <div><mat-spinner [diameter]="75"></mat-spinner></div>
  <div class="spinner-txt">File loading...</div>
</div>
<div mat-dialog-title class="dialog-title">
    <div><span class="file-name">{{fileData.fileName}}</span> <a class="button" href="{{fileData.signedUrl}}" rel="noopener">Download file</a></div>
    <button type="button" mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
  <ngx-doc-viewer *ngIf="useOfficeViewer"
  [url]="url"
  viewer="office"
  class="viewer"></ngx-doc-viewer>
  <div class="viewer-pdf" *ngIf="usePdfViewer">
    <ng2-pdfjs-viewer #pdfViewer [externalWindow]="false"></ng2-pdfjs-viewer>
  </div>
</mat-dialog-content>
