import { FetchActivityTemplatesAction, FetchActivityTemplatesActions } from "../actions/fetch-activity-templates.actions";
import { IActivityTemplate } from "src/app/models/osdu/activity-template.model";

export interface IActivityTemplatesCacheState {
    activityTeplates: IActivityTemplate[],
    activityTeplatesLoading: boolean,
    activityTeplatesRequestError: any
}

const initialState: IActivityTemplatesCacheState = {
    activityTeplates: [],
    activityTeplatesLoading: false,
    activityTeplatesRequestError: null
}

export function activityTemplatesReducer(state: IActivityTemplatesCacheState = initialState, action: FetchActivityTemplatesAction): IActivityTemplatesCacheState {

    switch(action.type) {
        case FetchActivityTemplatesActions.ACTIVITY_TEMPLATES_REQUEST: {
            state = <IActivityTemplatesCacheState>{
                activityTeplates: [], activityTeplatesLoading: true, activityTeplatesRequestError: null
            };
            return state;
        }
        case FetchActivityTemplatesActions.ACTIVITY_TEMPLATES_REQUEST_SUCCESS: {
            state = action.payload;
            return state;
        }
        case FetchActivityTemplatesActions.ACTIVITY_TEMPLATES_REQUEST_FAILED: {
            state = <IActivityTemplatesCacheState>{
                activityTeplates: [],
                activityTeplatesLoading: false,
                activityTeplatesRequestError: action.payload.activityTeplatesRequestError
            };
            return state;
        }
        default: {
            return state;
        }
    }
}
