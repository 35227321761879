import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { FilePreviewComponent } from 'src/app/components/file-preview/file-preview.component';
import { IGenericDialogData } from 'src/app/components/generic-dialog/generic-dialog-data.model';
import { GenericDialogComponent } from 'src/app/components/generic-dialog/generic-dialog.component';
import { GenericDialogService } from 'src/app/components/generic-dialog/generic-dialog.service';
import { IAppState } from 'src/app/store/reducers';
import { SaoOptionRecordAddDialogComponent } from '../sao-option-record-add-dialog/sao-option-record-add-dialog.component';
import { CalAngularService } from '@cvx/cal-angular';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS, PickDateAdapter } from 'src/app/util/custom-date-formats.util';
import { ISaoFlattenedRecordDataSource } from 'src/app/models/sao-record-flat-table.model';
import { ActivityStatus, ActivityStatusId, IAssignedSaoActivity, ISurevillanceRecord } from 'src/app/models/osdu/sureveillance-record.model';
import { IActivityTemplatesCacheState } from 'src/app/store/reducers/fetch-activity-templates.reducer';
import { IActivityTemplate } from 'src/app/models/osdu/activity-template.model';
import { PayloadDataService } from 'src/app/services/payload-data.service';

@Component({
  selector: 'sao-sao-options',
  templateUrl: './sao-options.component.html',
  styleUrls: ['./sao-options.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ],
  encapsulation: ViewEncapsulation.None
})

export class SaoOptionsComponent implements OnInit, AfterViewInit, OnDestroy {

  parentForm!: FormGroup;
  assignedSaoOptionsForm!: FormGroup;

  public OT = ActivityStatus;

  @Input() saoRecord!: ISurevillanceRecord;
  completedActivitiesDataSource: MatTableDataSource<IAssignedSaoActivity> = new MatTableDataSource<IAssignedSaoActivity>();
  plannedActivitiesDataSource: MatTableDataSource<IAssignedSaoActivity> = new MatTableDataSource<IAssignedSaoActivity>();

  private subscriptions: Subscription[] = [];
  allactivityTemplatesData: IActivityTemplate[] = [];
  private activityTemplatesCacheState$: Observable<IActivityTemplatesCacheState>;
  private activityTemplatesLoading: boolean = false;
  activityTemplatesDropdownList: IActivityTemplate[] = [];
  assignedSaoOptions!: IAssignedSaoActivity[];
  saoRecordData!: ISaoFlattenedRecordDataSource;
  loadingRecord: boolean = false;
  previewViewerAllowExt: string[] = ['PPT', 'PPTX', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PDF'];
  private userName?: string;
  maxDate: Date = new Date();
  changingRecord: boolean = false;

  assignedSaoOptionsFormArray!: FormArray;

  constructor(private matDialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    store: Store<IAppState>,
    private parent: FormGroupDirective,
    private dialog: MatDialog,
    private genericDialog: GenericDialogService,
    private readonly calService: CalAngularService,
    private payloadDataService: PayloadDataService) {
    this.activityTemplatesCacheState$ = store.pipe(select('activityTemplatesCacheState'));
  }

  ngOnInit(): void {
    this.parentForm = this.parent.form;
    this.assignedSaoOptions = this.parentForm.value.assignedSaoOptions || [];
  }

  async ngAfterViewInit(): Promise<void> {
    this.getActivityTemplates();
    await this.loadActivities(this.assignedSaoOptions);
    this.getUserName();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getUserName() {
    let isUserSignedInSub = this.calService.isUserSignedIn().subscribe((value: boolean) => {
      if (value) {
        let currentUserProfile = this.calService.cvxClaimsPrincipal;
        this.userName = currentUserProfile.name;
      }
    });
    this.subscriptions.push(isUserSignedInSub);
  }

  getActivityTemplates() {
    let sub = this.activityTemplatesCacheState$.subscribe(state => {
      this.allactivityTemplatesData = state.activityTeplates;
      this.activityTemplatesLoading = state.activityTeplatesLoading;
      if (!this.activityTemplatesLoading) {
        this.activityTemplatesDropdownList = this.allactivityTemplatesData;
      }
    });
    this.subscriptions.push(sub);
  }

  addActivity(event: Event, activityType: ActivityStatus = ActivityStatus.Planned) {
    let dialogRef = this.matDialog.open(SaoOptionRecordAddDialogComponent, { disableClose: true, height: '90vh' });

    dialogRef.afterClosed().subscribe((result: IActivityTemplate[]) => {
      if (result !== undefined) {
        this.addSaoActivity(result, activityType);
      }
    });
  }

  openFilePreview(event: Event, fileData: any) {
    this.matDialog.open(FilePreviewComponent, {
      disableClose: true,
      height: '100vh',
      width: '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      data: {
        file: fileData
      }
    });
  }

  addSaoActivity(activityTempalesToAdd: IActivityTemplate[], activityType: ActivityStatus = ActivityStatus.Planned) {
    let alreadyAddedPlannedOptions: string[] = [];

    [alreadyAddedPlannedOptions, activityTempalesToAdd]
      = this.FindAlreadyAddedSaoOptionsAndRemoveThemFromTheListToAdd(activityTempalesToAdd);

    let assignedSaoOptions = this.assignSaoActivities(activityTempalesToAdd, activityType);

    this.setAssignedSaoOptionsTablesDataSources(assignedSaoOptions, activityType);

    this.parentForm.patchValue({
      assignedSaoOptions: this.completedActivitiesDataSource.data.concat(this.plannedActivitiesDataSource.data)
    });

    this.changeDetectorRef.detectChanges();

    if (alreadyAddedPlannedOptions.length > 0) {
      this.openInfoDialog(alreadyAddedPlannedOptions)
    }
  }

  private assignSaoActivities(saoOptionsToAdd: IActivityTemplate[], activityStatus: ActivityStatus): IAssignedSaoActivity[] {
    let assignedSaoActivities: IAssignedSaoActivity[] =
      activityStatus == ActivityStatus.Completed ?
        this.completedActivitiesDataSource.data : this.plannedActivitiesDataSource.data;
    let activityStatusId = activityStatus == ActivityStatus.Completed ? ActivityStatusId.Completed : ActivityStatusId.Planned

    saoOptionsToAdd.forEach(saoOptionToAdd => {
      this.allactivityTemplatesData.forEach(localSaoOptionData => {
        if (localSaoOptionData.id == saoOptionToAdd.id) {
          assignedSaoActivities.push(<IAssignedSaoActivity><unknown>{
            activityParentId: crypto.randomUUID(),
            activityId: '',
            remark: localSaoOptionData.data.description || '',
            activityTemplateId: localSaoOptionData.id,
            activityTemplateName: localSaoOptionData.data.name,
            effectiveDateTime: new Date().toJSON().slice(0, 10),
            datasets: [],
            submitterName: this.userName ?? '',
            activityStatusId: activityStatusId,
            activityStatus: activityStatus
          });
        }
      });
    });

    return assignedSaoActivities;
  }

  private FindAlreadyAddedSaoOptionsAndRemoveThemFromTheListToAdd(saoOptionsToAdd: IActivityTemplate[]): [string[], IActivityTemplate[]] {
    let allAssignedSaoOptions: IAssignedSaoActivity[] = this.plannedActivitiesDataSource.data;
    let alreadyAddedPlannedOptions: string[] = [];

    allAssignedSaoOptions.forEach(assignedOption => {
      saoOptionsToAdd.forEach((activityTemplateToAdd, index) => {
        if (assignedOption.activityTemplateId === activityTemplateToAdd.id) {
          if (assignedOption.activityStatus === ActivityStatus.Planned) {
            alreadyAddedPlannedOptions.push(assignedOption.activityTemplateName);
          }
          saoOptionsToAdd.splice(index, 1);
        }
      });
    });

    return [alreadyAddedPlannedOptions, saoOptionsToAdd]
  }

  private setAssignedSaoOptionsTablesDataSources(assignedSaoOptions: IAssignedSaoActivity[], optionType: ActivityStatus) {
    if (optionType == ActivityStatus.Planned) {
      this.plannedActivitiesDataSource = new MatTableDataSource<IAssignedSaoActivity>(assignedSaoOptions);
    } else {
      this.completedActivitiesDataSource = new MatTableDataSource<IAssignedSaoActivity>(assignedSaoOptions);
    }
  }

  openInfoDialog(optionsPlanned: string[]) {
    let msg = this.getMessageForPlannedOpts(optionsPlanned);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '210px';
    dialogConfig.width = '500px';
    dialogConfig.data = <IGenericDialogData>{
      dialogType: 'Warning',
      content: `${msg}`,
      buttons: 'Ok'
    };

    this.dialog.open(GenericDialogComponent, dialogConfig);
  }

  private getMessageForPlannedOpts(notAddedOptions: string[]): string {
    let msg = '';
    let options: string = notAddedOptions.map(el => `"${el}"`).join(', ');
    if (notAddedOptions.length > 1) {
      msg = `SA&O activities ${options} have not been added - they are already planned for this well.`
    } else {
      msg = `SA&O activity ${options} has not been added - it is already planned for this well.`
    }
    return msg;
  }

  async loadActivities(data: IAssignedSaoActivity[]) {

    let completedAssignedSaoOptions: IAssignedSaoActivity[] = [];
    let plannedAssignedSaoOptions: IAssignedSaoActivity[] = [];

    this.fillAssignedSaoActivities(data, completedAssignedSaoOptions, plannedAssignedSaoOptions);

    this.completedActivitiesDataSource = new MatTableDataSource<IAssignedSaoActivity>(completedAssignedSaoOptions);
    this.plannedActivitiesDataSource = new MatTableDataSource<IAssignedSaoActivity>(plannedAssignedSaoOptions);

    this.changeDetectorRef.detectChanges();
  }

  fillAssignedSaoActivities(dataAssignedSaoActivities: IAssignedSaoActivity[],
    completedAssignedSaoOptions: IAssignedSaoActivity[],
    plannedAssignedSaoOptions: IAssignedSaoActivity[]) {
    if (dataAssignedSaoActivities) {
      dataAssignedSaoActivities.forEach(activity => {
        if (activity.activityStatus == ActivityStatus.Completed) {
          activity.activityParentId = crypto.randomUUID();
          completedAssignedSaoOptions.push(activity);
        }
        else if (activity.activityStatus == ActivityStatus.Planned) {
          activity.activityParentId = crypto.randomUUID();
          plannedAssignedSaoOptions.push(activity);
        }
      });

    }
  }

  checkFileExtension(fileType: string): boolean {
    if (this.previewViewerAllowExt.includes(fileType.toUpperCase())) {
      return true;
    }
    return false;
  }

  async moveActivityToCompleted(row: IAssignedSaoActivity) {
    this.removeActivity(row, ActivityStatus.Planned, false);
    this.addOptionRow(row);
    this.payloadDataService.setChangeActivitiesData(row?.activityId);
  }

  addOptionRow(row: IAssignedSaoActivity) {
     let assignedSaoActivities = this.completedActivitiesDataSource.data;
    row.activityStatus = ActivityStatus.Completed;
    row.activityStatusId = ActivityStatusId.Completed;
    assignedSaoActivities.push(row);

    this.completedActivitiesDataSource = new MatTableDataSource<IAssignedSaoActivity>(assignedSaoActivities);

    this.changeDetectorRef.detectChanges();
  }

  async removeActivity(row: IAssignedSaoActivity, optionType: ActivityStatus = ActivityStatus.Planned, removeFlag: boolean = true) {
    let userResponse = false;

    if (removeFlag) {
      let msg = '';
      if (optionType === ActivityStatus.Planned) {
        msg = 'You are about to remove the planned SA&O activity. Continue?';
      } else {
        msg = 'You are about to remove the SA&O activity along with all submitted comments and attachments. Continue?';
      }
      userResponse = await this.genericDialog.openGenericDialog('Warning', msg, 'Cancel,Ok');
    }

    if (userResponse || removeFlag === false) {
      if (optionType === ActivityStatus.Planned) {
        let optionTypesLocal = this.plannedActivitiesDataSource.data;
        let optionTypesLocalWithRemovedRecord = this.removeItemWithSlice(optionTypesLocal, optionTypesLocal.findIndex(x => x.activityParentId == row.activityParentId));
        this.plannedActivitiesDataSource = new MatTableDataSource<IAssignedSaoActivity>(optionTypesLocalWithRemovedRecord);
      }
      else {
        let optionTypesLocal = this.completedActivitiesDataSource.data;
        let optionTypesLocalWithRemovedRecord = this.removeItemWithSlice(optionTypesLocal, optionTypesLocal.findIndex(x => x.activityParentId == row.activityParentId));
        this.completedActivitiesDataSource = new MatTableDataSource<IAssignedSaoActivity>(optionTypesLocalWithRemovedRecord);
        this.payloadDataService.setDeleteActivitiesData(row?.activityId);
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  updateActivityComment = (row: IAssignedSaoActivity, remark: string) => {
    let index = this.completedActivitiesDataSource.data.findIndex(x => x.activityParentId === row.activityParentId);
    this.completedActivitiesDataSource.data[index].remark = remark;
    this.payloadDataService.setChangeActivitiesData(row?.activityId);
  }

  updateEffectiveDate(row: IAssignedSaoActivity, effectiveDete: string) {
    let index = this.completedActivitiesDataSource.data.findIndex(x => x.activityParentId === row.activityParentId);
    this.completedActivitiesDataSource.data[index].effectiveDateTime = new Date(effectiveDete);
    this.payloadDataService.setChangeActivitiesData(row?.activityId);
  }

  removeItemWithSlice(arr: IAssignedSaoActivity[], index: number) {
    const firstArr = arr.slice(0, index);
    const secondArr = arr.slice(index + 1);
    return [...firstArr, ...secondArr];
  }

  plannedOptColumns = [
    {
      columnDef: "saoOptioName",
      header: "Planned SA&O",
      cell: (activity: IAssignedSaoActivity) => `${activity.activityTemplateName}`
    }
  ];

  displayedPlannedOptCols = this.plannedOptColumns.map(c => c.columnDef);

  acqOptionsColumns = [
    {
      columnDef: "saoOptioName",
      header: "Acquired SA&O",
      cell: (activity: IAssignedSaoActivity) => `${activity.activityTemplateName}`
    }
  ];
  displayedAcqOptCols = this.acqOptionsColumns.map(c => c.columnDef);
}
