import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
import { FetchFieldsActions, FieldsRequestFailed, FieldsRequestSuccess } from "../actions/fetch-field.actions";
import { IAppState } from "../reducers";
import { IField } from "src/app/models/osdu/field.model";
import { OsduFieldAdapter } from "src/app/util/osdu-field-adapter.util";

@Injectable()
export class FetchFieldsEffects {
    constructor(private actions$: Actions,
        private dataService: DataService,
        private store: Store<IAppState>) { }

    public fetchFields$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchFieldsActions.FIELDS_REQUEST),
            exhaustMap(() => {
                return this.dataService.getDevAreas().pipe(
                    map(result => {
                        let adapter = new OsduFieldAdapter(this.store);
                        let resultMapped: IField[] = adapter.convertDevAreasToOsduFields(result);
                        return new FieldsRequestSuccess({ fields: resultMapped, fieldsLoading: false, fieldsRequesterror: null });
                    }))
            }),
            catchError((err: Error) => {
                let error = err.message;
                this.store.dispatch(new FieldsRequestFailed({ fields: [], fieldsLoading: false, fieldsRequesterror: error }));
                throw err;
            })
        )
    }
    );
}