import { EventEmitter, Injectable, Output } from "@angular/core";
import { IUploadLargeFilePayload } from "../models/payloads/upload-large-file-payload.model";
import { IUploadLargrFileResponse } from "../models/upload-large-file-response.model";
import { getSaoIngestionEndpointUrl, SaoIngestionEndpoints } from "../util/api-definitions.util";
import { GenericDialogService } from "../components/generic-dialog/generic-dialog.service";
import { Observable, retry } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

/**
* There's a 4-MB limit for each call to the Azure Storage service.
*/
@Injectable({
  providedIn: 'root'
})
export class UploadLargeFileService {

  @Output()
  progressEmitter: EventEmitter<number> = new EventEmitter<number>();

  private progress: number = 0;
  private disposeUpload: boolean = false;

  constructor(private dialog: GenericDialogService,
              private httpClient: HttpClient) {
              }

  public async uploadLargeFileAsync(file: File): Promise<IUploadLargrFileResponse> {
    if(file.name.includes('.')) {
      this.disposeUpload = false;
      let extension = file.name.split('.').slice(-1);
      let blobName = `${this.generateGuid()}.${extension}`;
      let response: IUploadLargrFileResponse = <IUploadLargrFileResponse>{};
      let uploadedToBlob: boolean = false;

      const chunkSize = 3670016; // 3,5 MB chunk - do not change this
      let chunkNo = Math.ceil(file.size / chunkSize);
      let startProgres = 0;
      let progressSlice = 100 / chunkNo;

      const retryNo = 5; // number of attempts to re-add the chunk
      let retryCounter = 0;
      let commiteCounter = 1;
      let start = 0;
      let sealBlob: boolean = false;

      while(start < file.size && !this.disposeUpload) {
        let chunkBuffer = await file.slice(start, start + chunkSize).arrayBuffer();
        let chunkUnit8 = new Uint8Array(chunkBuffer);
        let chnkBase64 = this.unit8ArrayToBase64(chunkUnit8);

        sealBlob = this.setSealFlag(commiteCounter, chunkNo);

        let payload = <IUploadLargeFilePayload>{
          fileName: file.name,
          blobName: blobName,
          blockData: chnkBase64,
          isLastBlock: sealBlob
        };

        response = await this.uploadFileAsync(payload);

        if(commiteCounter === response?.blobCommittedBlockCount && response?.blobCommittedBlockCount > 0) {
          commiteCounter++;
          start += chunkSize;
          retryCounter = 0;
          startProgres += progressSlice;
          this.progress = Math.round(startProgres);
          this.progressEmitter.emit(this.progress);
        } else {
          retryCounter++;

          if(retryCounter > retryNo) {
            let msg = 'There was an error in adding the file!';
            await this.dialog.openGenericDialog('Error', msg, 'Ok');
            break;
          }
        }
      }

      uploadedToBlob = this.setUploadedToBlob();

      return <IUploadLargrFileResponse>{
        fileName: response.fileName,
        blobName: response.blobName,
        isUploadedToBlob: uploadedToBlob,
        lastModified: response.lastModified,
        blobAppendOffset: response.blobAppendOffset,
        blobCommittedBlockCount: response.blobCommittedBlockCount,
        contentCrc64: response.contentCrc64
      };
    } else {
      let msg = 'Invalid file selected!';
      await this.dialog.openGenericDialog('Error', msg, 'Ok');

      return <IUploadLargrFileResponse>{
        lastModified: new Date('0001-01-01'),
        blobAppendOffset: '-1',
        blobCommittedBlockCount: -1,
        error: msg
      };
    }
  }

  setUploadedToBlob(): boolean {
    if(!this.disposeUpload) {
      return true;
    } else {
      this.disposeUpload = false;
      return false;
    }
  }

  public dispose() {
    this.disposeUpload = true;
  }

  private setSealFlag(commiteCounter: number, chunkNo: number): boolean {
    if(commiteCounter === chunkNo) {
      return true;
    }
    return false;
  }

  private async uploadFileAsync(largeFilePayload: IUploadLargeFilePayload): Promise<IUploadLargrFileResponse> {
    return await new Promise<IUploadLargrFileResponse>((resolve) => {
      this.postUploadLargeFile(largeFilePayload)
        .subscribe({
          next: (largeFileResponse: IUploadLargrFileResponse) => {
            resolve(largeFileResponse);
          },
          error: (err: HttpErrorResponse) => {
            console.error(err.error.error);
            let msg: string = 'There was an error in adding the file!';
            (async () => {
              await this.dialog.openGenericDialog('Error', msg, 'Ok');
            })();
          },
          complete: () => {
            console.log('The part of the large file was uploaded with success.');
          }
      });
    });
  }

  private postUploadLargeFile(payload: IUploadLargeFilePayload): Observable<IUploadLargrFileResponse> {
    let url = getSaoIngestionEndpointUrl(SaoIngestionEndpoints.UPLOAD_LARGE_FILE);
    return this.httpClient.post<IUploadLargrFileResponse>(url, payload)
    .pipe(retry(5));
  }

  private generateGuid() : string {
    return crypto.randomUUID();
  }

  private unit8ArrayToBase64(uintArray: Uint8Array){
    let binary = '';
    let len = uintArray.byteLength;
    for (let i =0; i < len; i++){
        binary += String.fromCharCode(uintArray[i]);
    }
    return window.btoa(binary);
  }
}
