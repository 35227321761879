import { Component, Input } from '@angular/core';

@Component({
  selector: 'sao-sor-menu-card',
  templateUrl: './sor-menu-card.component.html',
  styleUrls: ['./sor-menu-card.component.scss']
})
export class SorMenuCardComponent {

  @Input() public menuCardTitle: string = "";
  @Input() public menuCardSubtitle: string = "";
  @Input() public menuCardIconId: string = "";
  @Input() public menuCardRouterLink: string = "";
  @Input() public menuCardButtonText = 'Search';
  @Input() public menuCardAvatarImageUrl = '/assets/images/search.png';
}
