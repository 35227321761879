import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
import { BasinsRequestFailed, BasinsRequestSuccess, FetchBasinsActions } from "../actions/fetch-basins.actions";
import { IAppState } from "../reducers";
import { IBasin } from "src/app/models/osdu/basin.model";
import { RequestFields } from "../actions/fetch-field.actions";
import { RequestSubFields } from "../actions/fetch-sub-field.action";

@Injectable()
export class FetchBasinsEffects {
    constructor(private actions$: Actions,
        private dataService: DataService,
        private store: Store<IAppState>) { }

    public fetchBasins$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchBasinsActions.BASINS_REQUEST),
            exhaustMap(() => {
                return this.dataService.getAssetTeams().pipe(
                    map(result => {
                        let resultMapped: IBasin[] = [];
                        result.forEach(r => {
                            resultMapped.push(<IBasin>{
                                id: r.Id.toString(),
                                data: {
                                    basinName: r.description,
                                    basinId: r.Id.toString(),
                                    nameAliases: [
                                        {
                                            aliasName: r.code,
                                            aliasNameTypeId: 'AssetTeamCode'
                                        }
                                    ]
                                }
                            })
                        });

                        this.store.dispatch(new RequestFields());
                        this.store.dispatch(new RequestSubFields());
                        return new BasinsRequestSuccess({ basins: resultMapped, basinsLoading: false, basinsRequestError: null });
                    }))
            }),
            catchError((err: Error) => {
                let error = err.message;
                this.store.dispatch(new BasinsRequestFailed({ basins: [], basinsLoading: false, basinsRequestError: error }));
                throw err;
            })
        )
    }
    );
}
