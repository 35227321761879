import { Action } from "@ngrx/store";
import { IBasinsCacheState } from "../reducers/fetch-basins.reducer";

export enum FetchBasinsActions {
    BASINS_REQUEST = '[BASINS_CACHE] Request Basins',
    BASINS_REQUEST_SUCCESS = '[BASINS_CACHE] Basins request - success',
    BASINS_REQUEST_FAILED = '[BASINS_CACHE] Basins request - failed' 
}

export class RequestBasins implements Action {
    readonly type: string = FetchBasinsActions.BASINS_REQUEST;
    constructor(public payload: IBasinsCacheState = <IBasinsCacheState>{}) {}
}

export class BasinsRequestSuccess implements Action {
    readonly type: string = FetchBasinsActions.BASINS_REQUEST_SUCCESS;
    constructor(public payload: IBasinsCacheState) {}
}

export class BasinsRequestFailed implements Action {
    readonly type: string = FetchBasinsActions.BASINS_REQUEST_FAILED;
    constructor(public payload: IBasinsCacheState) {}
}

export type FetchBasinsAction 
= RequestBasins | BasinsRequestSuccess | BasinsRequestFailed;