<div class="footer-container">
    <ul class="inline-list">
        <li>© {{ updateDate | date: 'yyyy' }} Chevron Corporation</li>
        <li>Company Confidential</li>
        <li>
            Owner Organization:
            <a href="{{ departmentUrl }}" target="_self">{{ departmentName }}</a>
        </li>
        <li *ngIf="isUserLoggedIn">
            <a routerLink="/access">Request access</a>
        </li>
    </ul>
    <ul class="inline-list">
        <li>
            Content contact:
            <a href="{{ contentContactUrl }}">{{ contentContactName }}</a>
        </li>
        <li>
            Technical Contact:
            <a href="{{ technicalContactUrl }}">{{ technicalContactName }}</a>
        </li>
        <li>Last Update: {{ updateDate | date: 'MM/dd/yyyy' }}</li>
        <li>Version: {{ version }} </li>
        <li>Environment: {{ environmentName }} </li>
    </ul>
</div>