import { Action } from "@ngrx/store"
import { IFieldsCacheState } from "../reducers/fetch-fields.reducer";

export enum FetchFieldsActions {
    FIELDS_REQUEST = '[FIELDS_CACHE] Request Fields',
    FIELDS_REQUEST_SUCCESS = '[FIELDS_CACHE] Fields request - success',
    FIELDS_REQUEST_FAILED = '[FIELDS_CACHE] Fields request - failed' 
}

export class RequestFields implements Action {
    readonly type: string = FetchFieldsActions.FIELDS_REQUEST;
    constructor(public payload: IFieldsCacheState = <IFieldsCacheState>{}) {}
}

export class FieldsRequestSuccess implements Action {
    readonly type: string = FetchFieldsActions.FIELDS_REQUEST_SUCCESS;
    constructor(public payload: IFieldsCacheState) {}
}

export class FieldsRequestFailed implements Action {
    readonly type: string = FetchFieldsActions.FIELDS_REQUEST_FAILED;
    constructor(public payload: IFieldsCacheState) {}
}

export type FetchFieldsAction 
= RequestFields | FieldsRequestSuccess | FieldsRequestFailed;