import { ActionReducerMap } from "@ngrx/store";
import { LoginState, loginStateReducer } from "./login.reducer";
import { basinsReducer, IBasinsCacheState } from "./fetch-basins.reducer";
import { fieldsReducer, IFieldsCacheState } from "./fetch-fields.reducer";
import { fromationsReducer, IFormationsCacheState } from "./fetch-formations.reducer";
import { ITargetFormationsCacheState, targetFormationsReducer } from "./fetch-tgt-formations.reducer";
import { IAzureMapsSettingsCacheState, azureMapsSettingsReducer } from "./fetch-azure-maps-settings.reducer";
import { ISubFieldsCacheState, subFieldsReducer } from "./fetch-sub-fields.reducer";
import { ISurveillanceDataState, surveillanceDataReducer } from "./fetch-surveillance-data-reducer";
import { IActivityTemplatesCacheState, activityTemplatesReducer } from "./fetch-activity-templates.reducer";

export interface IAppState {
  azureMapsSettingsCacheState: IAzureMapsSettingsCacheState;
  basinsCacheState: IBasinsCacheState;
  fieldsCacheState: IFieldsCacheState;
  subFieldsCacheState: ISubFieldsCacheState
  formationsCacheState: IFormationsCacheState;
  targetFormationsCacheState: ITargetFormationsCacheState;
  surveillanceDataState: ISurveillanceDataState;
  activityTemplatesCacheState: IActivityTemplatesCacheState;
  loginState: LoginState;
}

export const reducers: ActionReducerMap<IAppState, any> = {
  azureMapsSettingsCacheState: azureMapsSettingsReducer,
  basinsCacheState: basinsReducer,
  fieldsCacheState: fieldsReducer,
  subFieldsCacheState: subFieldsReducer,
  formationsCacheState: fromationsReducer,
  targetFormationsCacheState: targetFormationsReducer,
  surveillanceDataState: surveillanceDataReducer,
  activityTemplatesCacheState: activityTemplatesReducer,
  loginState: loginStateReducer
}
