import { Action } from "@ngrx/store";
import { IActivityTemplatesCacheState } from "../reducers/fetch-activity-templates.reducer";

export enum FetchActivityTemplatesActions {
    ACTIVITY_TEMPLATES_REQUEST = '[ACTIVITY_TEMPLATES_CACHE] SAO Request Activity Templates',
    ACTIVITY_TEMPLATES_REQUEST_SUCCESS = '[ACTIVITY_TEMPLATES_CACHE] SAO Activity Templates request - success',
    ACTIVITY_TEMPLATES_REQUEST_FAILED = '[ACTIVITY_TEMPLATES_CACHE] SAO Activity Templates request - failed'
}

export class RequestActivityTemplates implements Action {
    readonly type: string = FetchActivityTemplatesActions.ACTIVITY_TEMPLATES_REQUEST;
    constructor(public payload: IActivityTemplatesCacheState = <IActivityTemplatesCacheState>{}) {}
}

export class ActivityTemplatesRequestSuccess implements Action {
    readonly type: string = FetchActivityTemplatesActions.ACTIVITY_TEMPLATES_REQUEST_SUCCESS;
    constructor(public payload: IActivityTemplatesCacheState) {}
}

export class ActivityTemplatesRequestFailed implements Action {
    readonly type: string = FetchActivityTemplatesActions.ACTIVITY_TEMPLATES_REQUEST_FAILED;
    constructor(public payload: IActivityTemplatesCacheState) {}
}

export type FetchActivityTemplatesAction
= RequestActivityTemplates | ActivityTemplatesRequestSuccess | ActivityTemplatesRequestFailed;
