import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { IOsduSignedUrl } from '../models/osdu/osdu-signed-url.model';
import { DataManagerSearchServiceEndpoints, getDataManagerEndpointUrl } from '../util/api-definitions.util';

@Injectable({
  providedIn: 'root'
})
export class PdfViewerService {
  constructor(private httpClient: HttpClient) { }

  downloadPdfFile(osduSignedUrl: IOsduSignedUrl): any {
    let url = getDataManagerEndpointUrl(DataManagerSearchServiceEndpoints.DOWNLOAD_FILE);
    return this.httpClient.post(url, osduSignedUrl, {responseType: 'blob' as 'json'})
        .pipe(
            map((result: any) => {
                return result;
            }),
            catchError(this.handleError)
        );
    }

    handleError = (err: HttpErrorResponse) => {
        let errMessage = '';
        if (err.error instanceof ErrorEvent) {
          errMessage = `An error occurred: ${err.error.message}}`;
        } else {
          errMessage = `Server returned code: ${err.status}, error message: ${err.message}`;
        }

        console.warn(errMessage)
        return throwError(() => errMessage);
      }
}
