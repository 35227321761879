import { IField } from "src/app/models/osdu/field.model";
import { FetchSubFieldsAction, FetchSubFieldsActions } from "../actions/fetch-sub-field.action";

export interface ISubFieldsCacheState {
    subFields: IField[],
    subFieldsLoading: boolean,
    subFieldsRequestError: any
}

const initialState: ISubFieldsCacheState = {
    subFields: [],
    subFieldsLoading: false,
    subFieldsRequestError: null
}

export function subFieldsReducer(state: ISubFieldsCacheState = initialState, action: FetchSubFieldsAction): ISubFieldsCacheState {
    switch(action.type) {
        case FetchSubFieldsActions.SUBFIELDS_REQUEST: {
            state = <ISubFieldsCacheState> {
                subFields: [], subFieldsLoading: true, subFieldsRequestError: null
            };
            return state;
        }
        case FetchSubFieldsActions.SUBFIELDS_REQUEST_SUCCESS: {
            state = action.payload;
            return state;
        }
        case FetchSubFieldsActions.SUBFIELDS_REQUEST_FAILED: {
            state = <ISubFieldsCacheState> {
                subFields: [],
                subFieldsLoading: false,
                subFieldsRequestError: action.payload.subFieldsRequestError
            }
            return state;
        }
        default: {
            return state;
        }
    }
}