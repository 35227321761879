import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EditServiceEvent {
  private detectEvent = new BehaviorSubject<boolean>(false);
  public editEvent = this.detectEvent.asObservable();

  editRecord(value: boolean) {
    this.detectEvent.next(value);
  }
}
