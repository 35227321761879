import { IAzureMapsSettings } from "src/app/models/maps/azure-maps-settings.model";
import { FetchAzureMapsSettingsAction, FetchAzureMapsSettingsActions } from "../actions/fetch-azure-maps-settings.actions";

export interface IAzureMapsSettingsCacheState {
  azureMapsSettings: IAzureMapsSettings,
  azureMapsSettingsLoading: boolean,
  azureMapsSettingsRequestError: any
}

const initialState: IAzureMapsSettingsCacheState = {
  azureMapsSettings: <IAzureMapsSettings>{},
  azureMapsSettingsLoading: false,
  azureMapsSettingsRequestError: null
}

export function azureMapsSettingsReducer(
  state: IAzureMapsSettingsCacheState = initialState, action: FetchAzureMapsSettingsAction): IAzureMapsSettingsCacheState {
  switch (action.type) {
    case FetchAzureMapsSettingsActions.AZURE_MAPS_SETTINGS_REQUEST: {
      state = <IAzureMapsSettingsCacheState>{
        azureMapsSettings: <IAzureMapsSettings>{},
        azureMapsSettingsLoading: true,
        azureMapsSettingsRequestError: null
      };
      return state;
    }
    case FetchAzureMapsSettingsActions.AZURE_MAPS_SETTINGS_REQUEST_SUCCESS: {
      state = action.payload;
      return state;
    }
    case FetchAzureMapsSettingsActions.AZURE_MAPS_SETTINGS_REQUEST_FAILED: {
      return <IAzureMapsSettingsCacheState>{
        azureMapsSettings: <IAzureMapsSettings>{},
        azureMapsSettingsLoading: false,
        azureMapsSettingsRequestError: action.payload.azureMapsSettingsRequestError
      };
    }
    default: {
      return state;
    }
  }
}
