<div class="body-container">
  <div class="app-container">
    <div>
      <sao-navigation [siteTitle]="siteTitle" [tabs]="tabs"></sao-navigation>
      <main class="content-container">
        <mat-card-content class="router-content-container">
          <router-outlet></router-outlet>
        </mat-card-content>
      </main>
    </div>
  </div>
</div>
<footer>
  <sao-footer></sao-footer>
</footer>