<div class="sao-option-file" id="sao-option-file">
  <div class="upload-files-content">
    <mat-spinner *ngIf="aggDatasetsVerLoading" [diameter]="25"></mat-spinner>
    <mat-table #innerTables mat-table [dataSource]="datasetsDataSource" class="mat-elevation-z8" aria-label="Acquired"
      matSort>
      <ng-container *ngFor="let column of fileActivityColumns; let i = index" [matColumnDef]="column.columnDef">
        <mat-header-cell *matHeaderCellDef class="header-container">
          <div>{{column.header}}</div>
          <div *ngIf="showPlusIcon"><span class="material-symbols-outlined icon-add" matTooltip="Upload new file"
              matTooltipPosition="above" (click)="uploadNewFile($event)">add</span></div>
        </mat-header-cell>
        <mat-expansion-panel class="files-expansion-panel" mat-cell *matCellDef="let fileRow" hideToggle="true"
          (opened)="fileRow.expanded = true" (closed)="fileRow.expanded = false" mat-cell>
          <mat-expansion-panel-header>
            <td class="sao-options-file-expansion-panel">
              <div>
                {{column.cell(fileRow)}}
              </div>
              <div class="icons-container">
                <span class="material-symbols-outlined">
                  {{ fileRow.expanded ? 'expand_less' : 'expand_more' }}
                </span>
                <span *ngIf="showPlusIcon" class="material-symbols-outlined icon-add" matTooltip="Upload new file version"
                  matTooltipPosition="above"
                  (click)="uploadNewFileVersion($event, fileRow); $event.stopPropagation()">add</span>
              </div>
            </td>
          </mat-expansion-panel-header>
          <sao-options-file-version [fileVersionInput]="getFileVersions(fileRow)"
            (deleteVersion)="deleteVersionEventEmit($event)">
          </sao-options-file-version>
        </mat-expansion-panel>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedFilesOptCols"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedFilesOptCols;"></tr>
    </mat-table>

  </div>
</div>
