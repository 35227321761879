import { Action } from "@ngrx/store";
import { IAzureMapsSettingsCacheState } from "../reducers/fetch-azure-maps-settings.reducer";

export enum FetchAzureMapsSettingsActions {
  AZURE_MAPS_SETTINGS_REQUEST = '[AZURE_MAPS_SETTINGS_CACHE] Request Azure Maps Settings',
  AZURE_MAPS_SETTINGS_REQUEST_SUCCESS = '[AZURE_MAPS_SETTINGS_CACHE] Azure Maps Settings request - success',
  AZURE_MAPS_SETTINGS_REQUEST_FAILED = '[AZURE_MAPS_SETTINGS_CACHE] Azure Maps Settings request - failed'
}

export class RequestAzureMapsSettings implements Action {
  readonly type: string = FetchAzureMapsSettingsActions.AZURE_MAPS_SETTINGS_REQUEST;
  constructor(public payload: IAzureMapsSettingsCacheState = <IAzureMapsSettingsCacheState>{}) { }
}

export class AzureMapsSettingsRequestSuccess implements Action {
  readonly type: string = FetchAzureMapsSettingsActions.AZURE_MAPS_SETTINGS_REQUEST_SUCCESS;
  constructor(public payload: IAzureMapsSettingsCacheState) { }
}

export class AzureMapsSettingsRequestFailed implements Action {
  readonly type: string = FetchAzureMapsSettingsActions.AZURE_MAPS_SETTINGS_REQUEST_FAILED;
  constructor(public payload: IAzureMapsSettingsCacheState) { }
}

export type FetchAzureMapsSettingsAction
  = RequestAzureMapsSettings | AzureMapsSettingsRequestSuccess | AzureMapsSettingsRequestFailed;
