import { Injectable, OnDestroy } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { firstValueFrom, Observable, Subject, Subscription } from "rxjs";
import { ButtonsType, DialogType, IGenericDialogData } from "./generic-dialog-data.model";
import { GenericDialogComponent } from "./generic-dialog.component";
import { dialogMinHeight, dialogDefaultWidth } from "../../util/constants.util"

@Injectable({
  providedIn: 'root'
})
export class GenericDialogService implements OnDestroy {

  private subscriptions: Subscription[] = [];

  constructor(private dialog: MatDialog) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private getUserRsponse(dialogType: DialogType,
    content: string,
    buttons: ButtonsType,
    title: string | null = null,
    disableClose: boolean = true,
    autoFocus: boolean = true): Observable<boolean> {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = disableClose;
    dialogConfig.autoFocus = autoFocus;
    dialogConfig.minHeight = dialogMinHeight;
    dialogConfig.width = dialogDefaultWidth;
    dialogConfig.data = <IGenericDialogData>{
      dialogType: dialogType,
      title: title,
      content: content,
      buttons: buttons
    };

    const dialogRef = this.dialog.open(GenericDialogComponent, dialogConfig);

    let userResponse: boolean;
    let subject = new Subject<boolean>();

    let sub = dialogRef.afterClosed().subscribe((result: boolean) => {
      userResponse = result;
      subject.next(userResponse);
    });

    this.subscriptions.push(sub);

    return subject.asObservable();
  }

  public async openGenericDialog(dialogType: DialogType, content: string, buttons: ButtonsType, title: string | null = null,
    disableClose: boolean = true, autoFocus: boolean = true): Promise<boolean> {
    return await firstValueFrom(this.getUserRsponse(dialogType, content, buttons, title, disableClose,
      autoFocus));
  }
}
