import { IGeologicalFormation } from "src/app/models/osdu/geological-formation.model";
import { FetchFormationsAction, FetchFormationsActions } from "../actions/fetch-formations.actions";

export interface IFormationsCacheState {
    formations: IGeologicalFormation[],
    formationsLoading: boolean,
    formationsRequestError: any
}

const initialState: IFormationsCacheState = {
    formations: [],
    formationsLoading: false,
    formationsRequestError: null
}

export function fromationsReducer(state: IFormationsCacheState = initialState, action: FetchFormationsAction): IFormationsCacheState
{
    switch(action.type) {
        case FetchFormationsActions.FORMATIONS_REQUEST: {
            state = <IFormationsCacheState>{
                formations: [], formationsLoading: true, formationsRequestError: null
            };
            return state;
        }
        case FetchFormationsActions.FORMATIONS_REQUEST_SUCCESS: {
            state = action.payload;
            return state;
        }
        case FetchFormationsActions.FORMATIONS_REQUEST_FAILED: {
            state = <IFormationsCacheState>{
                formations: [],
                formationsLoading: false, 
                formationsRequestError: action.payload.formationsRequestError
            };
            return state;
        }
        default: {
            return state;
        }
    }
}

