import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription, debounceTime, fromEvent, throwError } from 'rxjs';
import { AzureMapsService, Basins, DevAreas, GeoJsonAreasFile, GeoJsonAreasFilesDefinition, NojvDevAreas, PdevAreas, SubDevAreas } from './azue-maps.service';
import { IGeoJSON } from 'src/app/models/maps/geo-json.model';
import { IDashboardFilterSaoRecordDataSource } from '../../filter/filter.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MapOptionsDialogComponent } from '../map-options-dialog/map-options-dialog.component';
import { IDashboardTableSaoRecordSource } from '../table/table.component';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { IPolygonColorSchema } from 'src/app/models/maps/polygon-color-schema.model';
import { ISaoFlattenedRecordDataSource } from 'src/app/models/sao-record-flat-table.model';

@Component({
  selector: 'sao-azure-maps',
  templateUrl: './azure-maps.component.html',
  styleUrls: ['./azure-maps.component.scss']
})
export class AzureMapsComponent implements OnInit, OnDestroy {

  @Input()
  recordsState!: Observable<IDashboardFilterSaoRecordDataSource>;

  @Input()
  recordData!: Observable<IDashboardTableSaoRecordSource>;

  @Input()
  reloadMapData!: Observable<boolean>;

  @Output()
  clickedWell: EventEmitter<ISaoFlattenedRecordDataSource> = new EventEmitter<ISaoFlattenedRecordDataSource>();

  wells: ISaoFlattenedRecordDataSource[] = [];

  wellsLoading: boolean = true;
  private subscriptions: Subscription[] = [];
  map: any;
  mapIsLoading: boolean = false;
  geoJson!: IGeoJSON[];
  positions: number[][] = [];

  basinsGeoJson: any;
  devAreasGeoJson: any;
  subDevAreasGeoJson: any;
  novjDevAreasGeoJson: any;
  pdevAreasGeoJson: any;

  basinsLayers!: [string, string];
  devAreasLayers!: [string, string];
  subDevAreasLayers!: [string, string];
  novjDevAreasLayers!: [string, string];
  pdevAreasLayers!: [string, string];
  allPolygonLayersIds: string[] = [];

  basinsPolygonColorSchema: IPolygonColorSchema = <IPolygonColorSchema>{
    strokeColor: "#1a8cff",
    strokeWidth: 1,
    fillColor: "#1a8cff",
    fillOpacity: 0.1
  };

  devAreasPolygonColorSchema: IPolygonColorSchema = <IPolygonColorSchema>{
    strokeColor: "#006600",
    strokeWidth: 1,
    fillColor: "#006600",
    fillOpacity: 0.1
  };

  subDevAreasPolygonColorSchema: IPolygonColorSchema = <IPolygonColorSchema>{
    strokeColor: "#cc0000",
    strokeWidth: 1,
    fillColor: "#cc0000",
    fillOpacity: 0.1
  };

  novjAreasPolygonColorSchema: IPolygonColorSchema = <IPolygonColorSchema>{
    strokeColor: "#5900b3",
    strokeWidth: 1,
    fillColor: "#5900b3",
    fillOpacity: 0.1
  };

  pdevAreasPolygonColorSchema: IPolygonColorSchema = <IPolygonColorSchema>{
    strokeColor: "#804000",
    strokeWidth: 1,
    fillColor: "#804000",
    fillOpacity: 0.1
  };

  constructor(
    private azureMapsService: AzureMapsService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.initMap();
    this.openOptionDialog();
    this.centerViewOnRowClick();
    this.getClickedWell();
    this.reloadMap();
    this.windowResize();
    this.getGeoJsonAreasData(new Basins());
    this.getGeoJsonAreasData(new DevAreas());
    this.getGeoJsonAreasData(new SubDevAreas());
    this.getGeoJsonAreasData(new NojvDevAreas());
    this.getGeoJsonAreasData(new PdevAreas());
  }

  reloadMap() {
    let sub = this.reloadMapData
      .pipe(debounceTime(100))
      .subscribe(reload => {
        if (reload) {
          this.resizeMap();
        }
      });
    this.subscriptions.push(sub);
  }

  private windowResize() {
    let sub = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.resizeMap();
      });
    this.subscriptions.push(sub);
  }

  private resizeMap() {
    let el = document.getElementById('mapContainer');
    if (el !== null && this.map) {
      el.style.height = '100%';
      el.style.width = '100%';
      let elHeight = el.clientHeight;
      let elWidth = el.clientWidth;
      this.map.resize(elHeight, elWidth);
      this.azureMapsService.setFocusOnCenter(this.positions);
    }
  }

  initMap(): void {
    this.mapIsLoading = true;
    let sub = this.azureMapsService.getMap().subscribe({
      next: (azureMap: any) => {
        this.map = azureMap;
      },
      error: (err) => {
        this.mapIsLoading = false;
        console.error(err);
      },
      complete: () => {
        this.mapIsLoading = false;
        console.log('Map generating completed');
        this.renderWellsLayer();
      }

    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  renderWellsLayer(): void {
    let sub = this.recordsState.subscribe({
      next: (recordsState) => {
        this.wells = recordsState.filteringResult.filteredFlattenedRecords;
        this.wellsLoading = recordsState.loading;

        if (this.wellsLoading === false) {
          let wellObjs = this.azureMapsService.creatWellGeoJsonData(this.wells);
          this.positions = this.azureMapsService.setPositionForFocus(this.wells);
          let dataSource = this.azureMapsService.initializeDataSource();
          this.map.sources.add(dataSource);
          dataSource.add(wellObjs);
          this.azureMapsService.addBubbleLayer(dataSource, this.allPolygonLayersIds);
          this.azureMapsService.setFocusOnCenter(this.positions);
        }
      },
      error: (err) => {
        this.wellsLoading = false;
        console.error(err);
      },
      complete: () => {
        this.wellsLoading = false;
        console.log('Well data loading complete');
      }
    });
    this.subscriptions.push(sub);
  }

  centerViewOnRowClick() {
    let sub = this.recordData.subscribe(recordSource => {
      if (recordSource.saoRecordTableDataSource.data !== null) {
        this.azureMapsService.centerViewOnWellAndOpenPopup(recordSource);
      }
    });
    this.subscriptions.push(sub);
  }

  getClickedWell() {
    let sub = this.azureMapsService.clickedWellId.subscribe(wellId => {
      if (wellId && !this.wellsLoading) {
        let clickedWellData = this.wells.find(well => well.id === wellId);
        this.clickedWell.emit(clickedWellData);
      }
    });
    this.subscriptions.push(sub);
  }

  openOptionDialog() {
    let sub = this.azureMapsService.openSaoOptionDialog.subscribe(wellId => {
      let record = this.wells.find(well => well.id === wellId);
      this.openDialog(record);
    });
    this.subscriptions.push(sub);
  }

  private openDialog(saoRecordTableDataSource: ISaoFlattenedRecordDataSource | undefined) {
    if (saoRecordTableDataSource) {
      this.dialog.open(MapOptionsDialogComponent,
        {
          disableClose: true,
          width: '100vw',
          maxWidth: '100vw',
          height: '100vh',
          maxHeight: '100vh',
          data: {
            saoRecord: saoRecordTableDataSource
          }
        });
    }
  }

  private getGeoJsonAreasData(geoJsonFileName: GeoJsonAreasFile) {
    let sub = this.azureMapsService.readGeoJsonFileFromAssets(geoJsonFileName.fileName).subscribe({
      next: (geoJson: any) => {
        this.getVariableArea(geoJsonFileName.fileName, geoJson);
      },
      error: (e) => {
        console.error(`Error: ${e}`)
      },
      complete: () => {
        console.info(`Read file ${geoJsonFileName.fileName} complete.`)
      }
    });
    this.subscriptions.push(sub);
  }

  onChangeBasin(event: MatSlideToggleChange) {
    if (event.checked) {
      let basinDataSource = this.azureMapsService.initializeDataSource();
      this.map.sources.add(basinDataSource);
      basinDataSource.add(this.basinsGeoJson);
      let layerIds = this.azureMapsService.addPolygonLayer(basinDataSource, this.basinsPolygonColorSchema);
      this.basinsLayers = layerIds;
      this.allPolygonLayersIds.push(layerIds[0], layerIds[1]);
    } else {
      this.azureMapsService.cleanUserLayerByIds(this.basinsLayers);
      this.removePolygonLayerId(this.basinsLayers);
      this.basinsLayers.splice(0, 2);
    }
  }

  onChangeDevAreas(event: MatSlideToggleChange) {
    if (event.checked) {
      let devAreasDataSource = this.azureMapsService.initializeDataSource();
      this.map.sources.add(devAreasDataSource);
      devAreasDataSource.add(this.devAreasGeoJson);
      let layerIds = this.azureMapsService.addPolygonLayer(devAreasDataSource, this.devAreasPolygonColorSchema);
      this.devAreasLayers = layerIds;
      this.allPolygonLayersIds.push(layerIds[0], layerIds[1]);
    } else {
      this.azureMapsService.cleanUserLayerByIds(this.devAreasLayers);
      this.removePolygonLayerId(this.devAreasLayers);
      this.devAreasLayers.splice(0, 2);
    }
  }

  onChangeSubDevAreas(event: MatSlideToggleChange) {
    if (event.checked) {
      let subDevAreasDataSource = this.azureMapsService.initializeDataSource();
      this.map.sources.add(subDevAreasDataSource);
      subDevAreasDataSource.add(this.subDevAreasGeoJson);
      let layerIds = this.azureMapsService.addPolygonLayer(subDevAreasDataSource, this.subDevAreasPolygonColorSchema);
      this.subDevAreasLayers = layerIds;
      this.allPolygonLayersIds.push(layerIds[0], layerIds[1]);
    } else {
      this.azureMapsService.cleanUserLayerByIds(this.subDevAreasLayers);
      this.removePolygonLayerId(this.subDevAreasLayers);
      this.subDevAreasLayers.splice(0, 2);
    }
  }

  onChangeNojvAreas(event: MatSlideToggleChange) {
    if (event.checked) {
      let nojvDevAreasDataSource = this.azureMapsService.initializeDataSource();
      this.map.sources.add(nojvDevAreasDataSource);
      nojvDevAreasDataSource.add(this.novjDevAreasGeoJson);
      let layerIds = this.azureMapsService.addPolygonLayer(nojvDevAreasDataSource, this.novjAreasPolygonColorSchema);
      this.novjDevAreasLayers = layerIds;
      this.allPolygonLayersIds.push(layerIds[0], layerIds[1]);
    } else {
      this.azureMapsService.cleanUserLayerByIds(this.novjDevAreasLayers);
      this.removePolygonLayerId(this.novjDevAreasLayers);
      this.novjDevAreasLayers.splice(0, 2);
    }
  }

  onChangePdevAreas(event: MatSlideToggleChange) {
    if (event.checked) {
      let pdevDevAreasDataSource = this.azureMapsService.initializeDataSource();
      this.map.sources.add(pdevDevAreasDataSource);
      pdevDevAreasDataSource.add(this.pdevAreasGeoJson);
      let layerIds = this.azureMapsService.addPolygonLayer(pdevDevAreasDataSource, this.pdevAreasPolygonColorSchema);
      this.pdevAreasLayers = layerIds;
      this.allPolygonLayersIds.push(layerIds[0], layerIds[1]);
    } else {
      this.azureMapsService.cleanUserLayerByIds(this.pdevAreasLayers);
      this.removePolygonLayerId(this.pdevAreasLayers);
      this.pdevAreasLayers.splice(0, 2);
    }
  }

  private removePolygonLayerId(layerIds: [string, string]) {
    layerIds.forEach(layer => {
      let index = this.allPolygonLayersIds.indexOf(layer);
      if (index > -1) {
        this.allPolygonLayersIds.splice(index, 1);
      }
    });
  }

  private getVariableArea(geoJsonFileName: string, geoJson: any): void {
    switch (geoJsonFileName) {
      case GeoJsonAreasFilesDefinition.BASINS:
        this.basinsGeoJson = geoJson;
        break;
      case GeoJsonAreasFilesDefinition.DEV_AREAS:
        this.devAreasGeoJson = geoJson;
        break;
      case GeoJsonAreasFilesDefinition.SUB_DEV_AREAS:
        this.subDevAreasGeoJson = geoJson;
        break;
      case GeoJsonAreasFilesDefinition.NOJV_DEV_AREAS:
        this.novjDevAreasGeoJson = geoJson;
        break;
      case GeoJsonAreasFilesDefinition.PDEV_DEV_AREAS:
        this.pdevAreasGeoJson = geoJson;
        break;
      default:
        throwError(() => new Error("No such file exists."));
    }
  }
}

