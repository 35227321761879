import { ITargetFormation } from "src/app/models/osdu/target-formation.model";
import { FetchTargetFormationsAction, FetchTargetFormationsActions } from "../actions/fetch-tgt-formations.actions";

export interface ITargetFormationsCacheState {
    targetFormations: ITargetFormation[],
    targetFormationsLoading: boolean,
    targetFormationsRequestError: any
}

const initialState: ITargetFormationsCacheState = {
    targetFormations: [],
    targetFormationsLoading: false,
    targetFormationsRequestError: null
}

export function targetFormationsReducer(state: ITargetFormationsCacheState = initialState, action: FetchTargetFormationsAction): ITargetFormationsCacheState
{
    switch(action.type) {
        case FetchTargetFormationsActions.TARGET_FORMATIONS_REQUEST: {
            state = <ITargetFormationsCacheState>{
                targetFormations: [], 
                targetFormationsLoading: true, 
                targetFormationsRequestError: null
            };
            return state;
        }
        case FetchTargetFormationsActions.TARGET_FORMATIONS_REQUEST_SUCCESS: {
            state = action.payload;
            return state;
        }
        case FetchTargetFormationsActions.TARGET_FORMATIONS_REQUEST_FAILED: {
            state = <ITargetFormationsCacheState>{
                targetFormations: [],
                targetFormationsLoading: false,
                targetFormationsRequestError: action.payload.targetFormationsRequestError
            };
            return state;
        }
        default: {
            return state;
        }
    }
}

