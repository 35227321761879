import { Action } from "@ngrx/store";
import { ISubFieldsCacheState } from "../reducers/fetch-sub-fields.reducer";

export enum FetchSubFieldsActions {
    SUBFIELDS_REQUEST = '[SUBFIELDS_CACHE] Request Sub Fields (Sub Dev Area)',
    SUBFIELDS_REQUEST_SUCCESS = '[SUBFIELDS_CACHE] Sub Fields (Sub Dev Area) request - success',
    SUBFIELDS_REQUEST_FAILED = '[SUBFIELDS_CACHE] Sub Fields (Sub Dev Area) request - failed'
}

export class RequestSubFields implements Action {
    readonly type: string = FetchSubFieldsActions.SUBFIELDS_REQUEST;
    constructor(public payload: ISubFieldsCacheState = <ISubFieldsCacheState>{}) { }
}

export class SubFieldsRequestSuccess implements Action {
    readonly type: string = FetchSubFieldsActions.SUBFIELDS_REQUEST_SUCCESS;
    constructor(public payload: ISubFieldsCacheState) { }
}
export class SubFieldsRequestFailed implements Action {
    readonly type: string = FetchSubFieldsActions.SUBFIELDS_REQUEST_FAILED;
    constructor(public payload: ISubFieldsCacheState) { }
}

export type FetchSubFieldsAction 
= RequestSubFields | SubFieldsRequestSuccess | SubFieldsRequestFailed;