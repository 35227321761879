import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export default class Utils {
  public static generateTimestamp(): string {
    let salt = crypto.randomUUID().slice(-4);
    return `${new Date().toISOString().replace(/:/g, ".")}-${salt}`;
  }

  public static generateUniqueFileName(fileName: string): string {
    let fileExtension = fileName.split('.').slice(-1).join('');
    let fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    return `${fileNameWithoutExtension}_${Utils.generateTimestamp()}.${fileExtension}`;
  }
}
