import { IRecord } from "./record.model";

export interface ISurevillanceRecord extends IRecord<ISurevillanceData> { }

export interface ISurevillanceData {
  padName: string;
  assetTeam: string;
  developmentArea: string;
  subDevelopmentArea: string;
  description: string;
  externalWellId: string;
  externalWellName: string;
  source: string;
  name: string;
  wellId: string;
  permittedLegalWellName: string;
  apiNumber: string;
  apiPropertyId: string;
  preferredWellName: string;
  mudiWellName: string;
  fracStartDate: string;
  targetFormation: string;
  spudDate: string;
  forecastPopDate: string;
  wellLongitude: string;
  wellLatitude: string;
  assignedSaoActivities: IAssignedSaoActivity[];
}

export interface IAssignedSaoActivity {
  activityParentId: string;
  activityId: string;
  remark: string;
  activityTemplateId: string;
  activityTemplateName: string;
  effectiveDateTime: Date;
  datasets: string[];
  submitterName: string;
  activityStatusId: string;
  activityStatus: string;
}

export enum ActivityStatus {
  Planned = "Planned",
  Completed = "Completed"
}

export enum ActivityStatusId {
  Planned = "int-chevron-corporation:reference-data--ActivityStatus:Planned",
  Completed = "int-chevron-corporation:reference-data--ActivityStatus:Completed"
}
