import { Component } from '@angular/core';

@Component({
  selector: 'sao-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent {

  public readonly tableSummary: string = "Data Stewards for Business Units";

  public readonly mcbuSubject: string = "OSDU MCBU data groups access request.";
  public readonly mcbuMessageContentMatt: string = "Hi Matt! Could you please add me to the MCBU data groups in OSDU: data.mcbu.owners, data.mcbu.viewers?";
  public readonly mcbuMessageContentLeslie: string = "Hi Leslie! Could you please add me to the MCBU data groups in OSDU: data.mcbu.owners, data.mcbu.viewers?";
  public readonly mcbuCcLeslie: string = "Jeremy.Prouhet@chevron.com;LeslieMoore@chevron.com";
  public readonly mcbuCcMatt: string = "Jeremy.Prouhet@chevron.com;Matt.Worthy@chevron.com"
}
