<mat-toolbar class="navigation-container">
  <mat-toolbar-row class="toolbar-row-top" [ngClass]="hide ? 'hideTopToolbar' : 'showTopToolbar'">
    <div class="navigation-top-container">
      <div class="account-icon-left">
        <div class="account-icon-align-left" *ngIf="(loginState$ | async) == 2">
          <span class="material-symbols-outlined"> account_circle </span>
          <span class="icons-text"> {{ userName }}</span>
        </div>
      </div>
      <div class="branding-and-logo">
        <a class="logo" routerLink="/home">
          <img src="../../../assets/cvx-logo.png" alt="chevron logo" />
        </a>
        <a routerLink="/home" class="site-title">
          {{ siteTitle }}
        </a>
      </div>
      <div class="logout-icon-right">
        <div class="logout-icon-align-right" *ngIf="(loginState$ | async) == 2">
          <a (click)="onLogoutClick()">
            <span class="material-symbols-outlined"> logout </span>
            <span class="icons-text"> Log out</span>
          </a>
        </div>
      </div>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="links-toolbar">
    <nav mat-tab-nav-bar class="navigation-container" color="primary">
      <ng-container *ngFor="let tab of tabs">
        <ng-container *ngIf="tab.children.length; else simpleTab">
          <a mat-tab-link [active]="activeLink === tab.activeLink" [mat-menu-trigger-for]="menu">
            {{ tab.displayText }}
          </a>
          <mat-menu #menu="matMenu">
            <a *ngFor="let child of tab.children" mat-menu-item class="nav-menu-item"
              (click)="activeLink = child.activeLink" [routerLink]="child.routerLink">
              {{ child.displayText }}
            </a>
            <div class="hidden-width-only"></div>
          </mat-menu>
        </ng-container>
        <ng-template #simpleTab>
          <a mat-tab-link (click)="activeLink = tab.activeLink" [active]="activeLink === tab.activeLink"
            [routerLink]="tab.activeLink">
            {{ tab.displayText }}
          </a>
        </ng-template>
      </ng-container>
      <div class="add-record-nav-icon-container" *ngIf="activeLink == 'records'">
        <a routerLink="/add">
          <span class="material-symbols-outlined" id="add-record-icon"> docs_add_on </span>
        </a>
      </div>
    </nav>
    <div class="top-bar-visibility" (click)="showHideTopBar()">
      <span class="material-symbols-outlined">{{chevronType}}</span>
    </div>
  </mat-toolbar-row>
  <div class="navigation-drawer"></div>
</mat-toolbar>
