<div *ngIf="changingRecord" class="overlay">
  <div><mat-spinner [diameter]="75"></mat-spinner></div>
  <div class="spinner-txt">Changing data record...</div>
</div>
<!-- planned table -->
<div class="options-tables cvx-light-blue-border-without-top">
  <div *ngIf="loadingRecord" class="spinner-options">
    <div><mat-spinner [diameter]="40"></mat-spinner></div>
    <div class="spinner-txt">Loading record...</div>
  </div>
  <div class="planned">
    <table mat-table [dataSource]="plannedActivitiesDataSource" class="mat-elevation-z8" aria-label="Planned">

      <ng-container *ngFor="let column of plannedOptColumns" [matColumnDef]="column.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <p>{{column.header}}</p>
          <span class="material-symbols-outlined" (click)="addActivity($event, OT.Planned)"
            title="Add new option">add</span>
        </th>
        <td class="planned-details" mat-cell *matCellDef="let row">
          <div class="planned-item">{{column.cell(row)}}</div>
          <div class="planned-item">{{row.effectiveDateTime | date: "YYYY-MM-dd"}}</div>
          <div class="planned-item" matTooltip="{{row.submitterName}}" matTooltipPosition="above">{{row.submitterName}}
          </div>
          <div class="planned-item icons">
            <span class="material-symbols-outlined move-right" (click)="moveActivityToCompleted(row)"
              title="Move option to Acquired">double_arrow</span>
            <span class="material-symbols-outlined delete"
              (click)="removeActivity(row, OT.Planned); $event.stopPropagation();" title="Remove option">delete</span>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedPlannedOptCols"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedPlannedOptCols;"></tr>
    </table>

  </div>
  <!-- acquired table -->
  <div class="acquired">
    <table mat-table [dataSource]="completedActivitiesDataSource" class="mat-elevation-z8" aria-label="Acquired">
      <ng-container *ngFor="let column of acqOptionsColumns; let i = index" [matColumnDef]="column.columnDef">
        <th mat-header-cell *matHeaderCellDef>
          <p>{{column.header}}</p>
          <span *ngIf="false" class="material-symbols-outlined" (click)="addActivity($event, OT.Completed)"
            title="Add new option">add</span>
        </th>
        <mat-accordion [multi]="true">
          <mat-expansion-panel
          hideToggle="true"
          (opened)="row.expanded=true"
          (closed)="row.expanded=false"
            mat-cell *matCellDef="let row">
            <mat-expansion-panel-header>
              <td class="expandable-header">
                <div class="acquired-item">{{column.cell(row)}} </div>
                <div class="acquired-item">
                  <mat-form-field>
                    <input matInput [matDatepicker]="dpEffectiveDete" [value]="row.effectiveDateTime" disabled
                      aria-label="deteAcquisition" [max]="maxDate"
                      (dateChange)="updateEffectiveDate(row, effectiveDete.value)" #effectiveDete>
                    <mat-datepicker-toggle matSuffix [for]="dpEffectiveDete"></mat-datepicker-toggle>
                    <mat-datepicker #dpEffectiveDete disabled="false"
                      [startAt]="row.effectiveDateTime"></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="acquired-item" matTooltip="{{row.submitterName}}" matTooltipPosition="above">
                  {{row.submitterName}}
                </div>
                <div class="acquired-item arrow">
                  <span class="material-symbols-outlined">
                    {{ row.expanded ? 'expand_less' : 'expand_more' }}
                  </span>
                </div>
                <div class="acquired-item material-symbols-outlined delete"
                  (click)="removeActivity(row, OT.Completed); $event.stopPropagation();">delete</div>
              </td>
            </mat-expansion-panel-header>
            <div class="upload-files-content">
              <mat-form-field appearance="fill">
                <mat-label>Comments</mat-label>
                <div>
                  <input matInput (change)="updateActivityComment(row, comment.value)"
                    [value]="row.remark" placeholder="Write comment..." #comment>
                </div>
              </mat-form-field>
              <mat-list>
                <sao-options-file [assignedSaoActivity]="row" [saoFlattenedRecordDataSource]="saoRecordData">
                </sao-options-file>
              </mat-list>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedAcqOptCols"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedAcqOptCols;"></tr>
    </table>
  </div>
</div>
