import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { distinctUntilChanged, filter, map, Observable, Subscription } from 'rxjs';
import { IMenuTab, INavigation } from './navigation.model';
import { Store, select } from '@ngrx/store';
import { LoggedIn, LoggedOut } from 'src/app/store/actions/login.actions';
import { LoginState } from 'src/app/store/reducers/login.reducer';

@Component({
  selector: 'sao-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy, INavigation, AfterContentChecked {

  @Input() public tabs: IMenuTab[] = [];
  @Input() public siteTitle: string = "";

  private subscriptions: Subscription[] = [];
  private currentUserProfile?: ICvxClaimsPrincipal;
  public userName: string = "";
  loginState$: Observable<LoginState>;
  hide: boolean = false;
  chevronType: string = 'expand_less';

  public activeLink: string = "home";
  private readonly subscription: Subscription = new Subscription();
  private readonly activeLink$: Observable<string> = this.router.events.pipe(
    filter((route: any) => !!route && !!route.url && !!route.url.length),
    map(({ url }) => url.split('/')[1]),
    distinctUntilChanged()
  )

  constructor(private readonly calService: CalAngularService,
    private readonly router: Router,
    private cd: ChangeDetectorRef,
    private readonly store: Store<{ loginState: LoginState }>) {
    this.loginState$ = this.store.pipe(select('loginState'));
  }

  ngOnInit(): void {
    this.subscription.add(this.activeLink$.subscribe(activeLink => {
      this.activeLink = activeLink;
      this.setTopBarVisibility(activeLink);
    }));
    let isUserSignedInSub = this.calService.isUserSignedIn().subscribe((value: boolean) => {
      if (value) {
        this.currentUserProfile = this.calService.cvxClaimsPrincipal;
        this.userName = this.currentUserProfile.name;
        this.store.dispatch(new LoggedIn());
      } else {
        this.store.dispatch(new LoggedOut());
      }
    })
    this.subscriptions.push(isUserSignedInSub);
    this.loginState$ = this.store.pipe(select('loginState'));
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  onLogoutClick() {
    this.store.dispatch(new LoggedOut());
    this.calService.userInitiatedSignOut();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  showHideTopBar() {
    this.hide = !this.hide;
    if (this.hide) {
      this.chevronType = 'expand_more';
    } else {
      this.chevronType = 'expand_less';
    }
  }

  private setTopBarVisibility(activeLink: string) {
    if (activeLink === 'home' || activeLink === 'login') {
      this.hide = false;
      this.chevronType = 'expand_less';
    } else {
      this.hide = true;
      this.chevronType = 'expand_more';
    }
  }
}
