import { HttpClient, HttpRequest } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { FilePreviewComponent } from 'src/app/components/file-preview/file-preview.component';
import { GenericDialogService } from 'src/app/components/generic-dialog/generic-dialog.service';
import { IVersion } from 'src/app/models/Dto/datasets-version.model';
import { IOsduSignedUrl } from 'src/app/models/osdu/osdu-signed-url.model';
import { EditServiceEvent } from 'src/app/services/edit-service-event.service';
import { PdfViewerService } from 'src/app/services/pdf-viewer.service';

@Component({
  selector: 'sao-options-file-version',
  templateUrl: './sao-options-file-version.component.html',
  styleUrls: ['./sao-options-file-version.component.scss']
})
export class SaoOptionsFileVersionComponent {
  @Input()
  fileVersionInput!: IVersion[] | undefined;
  @Output()
  deleteVersion: EventEmitter<IVersion> = new EventEmitter<IVersion>();
  fileVersionDataSource!: MatTableDataSource<IVersion>;
  sortingColumn!: string;
  sortingDirection!: string;
  previewViewerAllowExt: string[] = ['PPT', 'PPTX', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PDF'];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private pdfViewerService: PdfViewerService,
    private dialog: GenericDialogService,
    private dialogService: GenericDialogService,
    public router: Router,
    private matDialog: MatDialog,
    private httpClient: HttpClient,
    private editServiceEvent: EditServiceEvent) {
    this.fileVersionDataSource = new MatTableDataSource<IVersion>(this.fileVersionInput);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (JSON.stringify(changes["fileVersionInput"].previousValue) !== JSON.stringify(changes["fileVersionInput"].currentValue)) {
      this.fileVersionDataSource = new MatTableDataSource<IVersion>(this.fileVersionInput);
      this.changeDetectorRef.detectChanges();
    }
  }

  shouldDisplayPreviewButton(row: IVersion): boolean {
    let extension = row?.name?.split('.')?.pop();
    if (extension !== null && extension !== undefined) {
      let matchingExtensions = this.previewViewerAllowExt.filter(x => x.toLowerCase() == extension?.toLowerCase());
      return matchingExtensions.length > 0
    }
    return false;
  }

  shouldDisplayDownloadButton(row: IVersion): boolean {
    if (row?.hasOwnProperty('signedUrl') && row?.signedUrl !== null) {
      return true;
    }
    return false;
  }

  shouldDisplayDeleteButton() {
    if (this.router.url === '/records') {
      return false;
    }
    return true;
  }

  sortData(event: Sort) {
    const { active, direction } = event;

    // Update the sorting column and direction
    this.sortingColumn = active;
    this.sortingDirection = direction;

    let sorted = this.sortfileVersion(this.fileVersionDataSource.data);
    // Sort the data using the custom sorting function
    this.fileVersionDataSource = new MatTableDataSource<IVersion>(sorted);
    this.changeDetectorRef.detectChanges();
  }

  private sortfileVersion(data: IVersion[]): IVersion[] {
    return [...data]?.sort((a, b) => this.customSort(a, b, this.sortingColumn));
  }

  customSort(a: any, b: any, sortingColumn: string): number {
    const sortingColumns = sortingColumn.split('.');
    const valueA = this.getPropertyValue(a, sortingColumns.length > 0 ? sortingColumns : [sortingColumn]);
    const valueB = this.getPropertyValue(b, sortingColumns.length > 0 ? sortingColumns : [sortingColumn]);

    if (valueA < valueB) {
      return this.sortingDirection === 'asc' ? -1 : 1;
    } else if (valueA > valueB) {
      return this.sortingDirection === 'asc' ? 1 : -1;
    } else {
      return 0;
    }
  }

  private compare(a: string | null, b: string | null, isAsc: boolean): number {
    let sortDirection = isAsc ? 1 : -1;
    let valueA = a === null ? '' : a.toLowerCase();
    let valueB = b === null ? '' : b.toLowerCase();

    let compareResult = valueA?.localeCompare(valueB);

    return compareResult * sortDirection;
  }

  getPropertyValue(obj: any, properties: string[]): any {
    const property = properties.shift();

    if (property !== undefined && obj.hasOwnProperty(property)) {
      const value = obj[property];

      if (properties.length === 0) {
        return value;
      } else {
        return this.getPropertyValue(value, properties);
      }
    }

    return null;
  }

  openFilePreview(event: Event, fileData: IVersion) {
    this.matDialog.open(FilePreviewComponent, {
      disableClose: true,
      height: '100vh',
      width: '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      data: {
        file: {
          fileName: fileData?.name,
          fileType: fileData?.name?.split('.')?.pop(),
          signedUrl: fileData?.signedUrl
        }
      }
    });
  }

  async deleteFileVersion(row: IVersion) {
    this.deleteFileVersionFromModel(row);
  }

  private deleteFileVersionFromModel(row: IVersion): void {
    let updatedFileVersions = this.fileVersionDataSource.data.filter(x => x.name !== row.name);
    this.deleteFileVersionEventEmit(row);
    this.fileVersionDataSource = new MatTableDataSource<IVersion>(updatedFileVersions);
    this.changeDetectorRef.detectChanges();
  }

  private deleteFileVersionEventEmit(fileInfoResponse: IVersion) {
    this.deleteVersion.emit(fileInfoResponse);
  }

  fileVersionsOptColumns = [
    {
      columnDef: "versionComment",
      header: "Comment",
      cell: (version: IVersion) => `${version.description}`
    },
    {
      columnDef: "uploadedTime",
      header: "Uploaded On",
      cell: (version: IVersion) => {
        if (version.createTime == undefined || version.createTime == null)
          return '';
        const date = new Date(version.createTime);
        const formattedDate = date.toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        });
        return `${formattedDate}`;
      }
    },
    {
      columnDef: "uploadedUser",
      header: "Uploaded By",
      cell: (version: IVersion) => `${version.createUser}`
    },
    {
      columnDef: "actions",
      header: "Operations",
      cell: (version: IVersion) => { },
    },
  ];

  displayedFileVesionsOptCols = this.fileVersionsOptColumns.map(c => c.columnDef);

}
