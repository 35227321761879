<form [formGroup]="editRecordForm" id="editRecordComponent" (ngSubmit)="submitEditRecord()">
    <h2 class="edit-title icons-text">Edit SA&O Record</h2>

    <div class="form-grid">
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Asset Team</mat-label>
                <input matInput [readonly]="true" formControlName="assetTeam" id="assetTeam"
                    placeholder="Placeholder for the asset team">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Dev Area</mat-label>
                <input matInput formControlName="devArea" id="devArea" placeholder="Placeholder for the Dev Area">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Sub Dev Area</mat-label>
                <input matInput formControlName="subDevelopmentArea" id="subDevelopmentArea"
                    placeholder="Placeholder for the Sub Development Area">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Pad Name</mat-label>
                <input matInput formControlName="padName" id="padName" placeholder="Placeholder for the Pad Name">
            </mat-form-field>
        </p>
        <p>
            <mat-form-field appearance="outline">
                <mat-label>Well Name (MUDI)</mat-label>
                <input matInput formControlName="externalWellName" id="externalWellName"
                    placeholder="Placeholder for the Well Name (MUDI)">
            </mat-form-field>
        </p>
    </div>
    <sao-sao-options #saoOptionsComponent [saoRecord]="saoRecord"></sao-sao-options>
    <div *ngIf="submitLoading" class="overlay">
        <mat-spinner class="spinner"></mat-spinner>
        <div class="spinner-txt">Editing record...</div>
    </div>
    <div class="edit-record-buttons">
        <a (click)="cancel($event)">
            <button class="mat-raised-button mat-primary" type="button" id="cancel-button">Cancel</button>
        </a>
        <a>
            <button class="mat-raised-button mat-primary" type="submit" id="submit-button">Submit Record Edit</button>
        </a>
    </div>
</form>
