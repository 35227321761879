import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import { IOsduSignedUrl } from 'src/app/models/osdu/osdu-signed-url.model';
import { PdfViewerService } from 'src/app/services/pdf-viewer.service';
import { GenericDialogService } from '../generic-dialog/generic-dialog.service';

@Component({
  selector: 'sao-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})

export class FilePreviewComponent implements OnInit {

  fileData!: any;
  url!: string;
  useOfficeViewer: boolean = false;
  usePdfViewer: boolean = false;
  ngxDocViewerExt: string[] = ['PPT', 'PPTX', 'DOC', 'DOCX', 'XLS', 'XLSX'];
  pdfVieverExt: string[] = ['PDF'];
  fileLoading: boolean = false;

  @ViewChild('pdfViewer') public pdfViewer: any;
  pdfFile: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private pdfViewerService: PdfViewerService,
              private dialog: GenericDialogService) { }

  ngOnInit(): void {
    this.fileData = this.data.file;
    this.url = this.fileData.signedUrl;
    this.chooseViewer(this.fileData.fileType);
  }

  chooseViewer(fileExtension: string): void {
    if(this.ngxDocViewerExt.includes(fileExtension.toUpperCase())){
      this.useOfficeViewer = true;
    }

    if(this.pdfVieverExt.includes(fileExtension.toUpperCase())){
      this.usePdfViewer = true;
      this.fileLoading = true;
      this.openPdf(this.url);
    }
  }

  public openPdf(src: string) {
    let payload = <IOsduSignedUrl>{
      OsduSignedUrl: src
    };
    this.pdfViewerService.downloadPdfFile(payload).subscribe({
      next: (res: any) => {
        let file = new Blob([res], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        this.pdfViewer.pdfSrc = fileURL
        this.pdfViewer.refresh();
      },
      error: async (err: any) => {
        console.log('HTTP Error', err)
        this.fileLoading = false;
        let msg = 'Download file failed!';
        await this.dialog.openGenericDialog('Error', msg, 'Ok');
      },
      complete: () => {
        console.log('Download file completed.');
        this.fileLoading = false;
      }
    });
  }
}

