import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { DataService } from "src/app/services/data.service";
import { IAppState } from "../reducers";
import { FetchSubFieldsActions, SubFieldsRequestFailed, SubFieldsRequestSuccess } from "../actions/fetch-sub-field.action";
import { catchError, exhaustMap, map } from "rxjs";
import { IField } from "src/app/models/osdu/field.model";
import { OsduFieldAdapter } from "src/app/util/osdu-field-adapter.util";

@Injectable()
export class FetchSubFieldsEffects {
    constructor(private actions$: Actions,
        private dataService: DataService,
        private store: Store<IAppState>) {}

    public fetchSubFields$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchSubFieldsActions.SUBFIELDS_REQUEST),
            exhaustMap(() => {
                return this.dataService.getSubDevAreas().pipe(
                    map(result => {
                        let adapter = new OsduFieldAdapter(this.store);
                        let resultMapped: IField[] = adapter.convertSubDevAreasToOsduFields(result);
                        return new SubFieldsRequestSuccess({ subFields: resultMapped, subFieldsLoading: false, subFieldsRequestError: null});  
                    })
                )
            }),
            catchError((err: Error) => {
                let error = err.message;
                this.store.dispatch(new SubFieldsRequestFailed({ subFields: [], subFieldsLoading: false, subFieldsRequestError: error}));
                throw err;
            })
        )
    });
}