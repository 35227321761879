import { Injectable } from '@angular/core';
import { IAssignedSaoActivity, ISurevillanceRecord } from '../models/osdu/sureveillance-record.model';
import { IDatasetsVersion } from '../models/Dto/datasets-version.model';
import { IActivity, IDatasetDef, IAddRecordPayload } from '../models/payloads/add-record-payload.model';
import { IDelDataset, IUpdateRecordPayload } from '../models/payloads/update-record-payload.model';

@Injectable({
  providedIn: 'root'
})
export class PayloadDataService {

  private saoRecordData: ISurevillanceRecord = <ISurevillanceRecord>{};
  private assignedSaoActivitiesData: IAssignedSaoActivity[] = [];
  private datasetsVersionData: IDatasetsVersion[] = [];
  private changeActivities: string[] = [];
  private deleteActivities: string[] = [];
  private deleteDatasets: IDelDataset[] = [];

  public clearPayloadData() {
    this.saoRecordData = <ISurevillanceRecord>{};
    this.assignedSaoActivitiesData = [];
    this.datasetsVersionData = [];
    this.changeActivities = [];
    this.deleteActivities = [];
    this.deleteDatasets = [];
  }

  public getSaoRecordData(): ISurevillanceRecord {
    return this.saoRecordData;
  }

  public setSaoRecordData(record: ISurevillanceRecord): void {
    this.saoRecordData = record;
  }

  public getChangeActivitiesData(): string[] {
    return this.changeActivities;
  }

  public setChangeActivitiesData(activityId: string): void {
    if (activityId && !this.changeActivities.includes(activityId)) {
      this.changeActivities.push(activityId);
    }
  }

  public getDeleteDatasetsData(): IDelDataset[] {
    return this.deleteDatasets;
  }

  public setDeleteDatasetsData(activityId: string | null, datasetId: string | null): void {
    if (activityId && datasetId) {
      this.deleteDatasets.push(<IDelDataset>{
        activityOsduId: activityId,
        datasetOsduId: datasetId
      })
    }
  }

  public getDeleteActivitiesData(): string[] {
    return this.deleteActivities;
  }

  public setDeleteActivitiesData(activityId: string): void {
    if (activityId && this.changeActivities.includes(activityId)) {
      let index = this.changeActivities.indexOf(activityId);
      if (index > -1) {
        this.changeActivities.splice(index, 1);
      }
    }

    if (activityId) {
      this.deleteActivities.push(activityId);
      let removeDatasets = this.deleteDatasets.filter(x => x.activityOsduId !== activityId);
      this.deleteDatasets = removeDatasets;
    }
  }

  public setAssignedSaoActivitiesData(assignedSaoActivities: IAssignedSaoActivity[]): void {
    this.datasetsVersionData.forEach(x => {
      let existsDatasetsForActivity = assignedSaoActivities.some(activity => activity.activityParentId === x.activityParentId);
      if (!existsDatasetsForActivity) {
        let newDatasets = this.datasetsVersionData.filter(dataset => dataset.activityParentId !== x.activityParentId);
        this.datasetsVersionData = [...newDatasets];
      }
    });
    this.assignedSaoActivitiesData = [...assignedSaoActivities];
  }

  public getAssignedSaoActivitiesData(): IAssignedSaoActivity[] {
    return this.assignedSaoActivitiesData;
  }

  public setDatasetsVersionData(datasetsVersion: IDatasetsVersion[]): void {
    let datasets: IDatasetsVersion[] = [];
    let index = this.datasetsVersionData.findIndex(x => datasetsVersion.some(y => y.parentId === x.parentId));
    if (index === -1) {
      datasets = this.datasetsVersionData.concat(datasetsVersion);
    } else {
      datasetsVersion.forEach(el => {
        let elIndex = this.datasetsVersionData.findIndex(x => x.parentId === el.parentId);
        if (elIndex > -1) {
          this.datasetsVersionData.splice(elIndex, 1);
        }
      });
      datasets = this.datasetsVersionData.concat(datasetsVersion);
    }
    this.removeEmptyVersions(datasets);
    this.datasetsVersionData = datasets;
  }

  public getDatasetsVersionData(): IDatasetsVersion[] {
    return this.datasetsVersionData;
  }

  public buildAddRecordPayload(): IAddRecordPayload {
    let activities: IActivity[] = [];
    this.assignedSaoActivitiesData.forEach(saoActivity => {
      let datasetsVersions = this.datasetsVersionData.filter(dataset => dataset.activityParentId === saoActivity.activityParentId);
      let datasets: IDatasetDef[] = [];
      datasetsVersions?.forEach(dataset => {
        dataset.versions.forEach(ver => {
          let newDataset = <IDatasetDef>{
            blobReference: ver.blobReference ?? null,
            fileName: ver.name,
            parentId: ver.parentId,
            comment: ver.description,
            principalName: ver.createUser,
            datasetId: ver?.datasetId
          }
          datasets.push(newDataset);
        });
      });

      let activity = <IActivity>{
        activityOsduId: '',
        activityTemplateId: saoActivity.activityTemplateId,
        submitterName: saoActivity.submitterName,
        lastActivityStatusId: saoActivity.activityStatusId,
        effectiveDateTime: saoActivity.effectiveDateTime,
        remark: saoActivity.remark,
        datasets: datasets ?? []
      };

      activities.push(activity);
    });

    let payload = <IAddRecordPayload>{
      recordOsduId: this.saoRecordData.id ?? null,
      padName: this.saoRecordData.data.padName,
      extWellId: this.saoRecordData.data.externalWellId,
      activities: activities
    };

    return payload;
  }

  public buildUpdateRecordPayload(): IUpdateRecordPayload {
    let activities: IActivity[] = [];
    this.assignedSaoActivitiesData.forEach(saoActivity => {
      let datasetsVersions = this.datasetsVersionData.filter(dataset => dataset.activityParentId === saoActivity.activityParentId);
      let datasets: IDatasetDef[] = [];
      datasetsVersions?.forEach(dataset => {
        dataset.versions.forEach(ver => {
          let newDataset = <IDatasetDef>{
            blobReference: ver.blobReference ?? null,
            fileName: ver.name,
            parentId: ver.parentId,
            comment: ver.description,
            principalName: ver.createUser,
            datasetId: ver?.datasetId
          }
          datasets.push(newDataset);
        });
      });

      let activity = <IActivity>{
        activityOsduId: saoActivity?.activityId,
        activityTemplateId: saoActivity.activityTemplateId,
        submitterName: saoActivity.submitterName,
        lastActivityStatusId: saoActivity.activityStatusId,
        effectiveDateTime: saoActivity.effectiveDateTime,
        remark: saoActivity.remark,
        datasets: datasets ?? []
      };

      activities.push(activity);
    });

    let payload = <IUpdateRecordPayload>{
      recordOsduId: this.saoRecordData.id ?? null,
      padName: this.saoRecordData.data.padName,
      extWellId: this.saoRecordData.data.externalWellId,
      activities: activities,
      changeActivities: this.changeActivities,
      deleteActivities: this.deleteActivities,
      deleteDatasets: this.deleteDatasets
    };

    return payload;
  }

  private removeEmptyVersions(datasets: IDatasetsVersion[]): void {
    let emptyVersions = datasets.filter(x => x.versions.length === 0);
    if (emptyVersions?.length > 0) {
      emptyVersions.forEach(el => {
        let emptyIndex = datasets.findIndex(x => x.parentId === el.parentId);
        if (emptyIndex > -1) {
          datasets.splice(emptyIndex, 1);
        }
      });
    }
  }
}
