import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
import { IAppState } from "../reducers";
import { ActivityTemplatesRequestSuccess, FetchActivityTemplatesActions } from "../actions/fetch-activity-templates.actions";

@Injectable()
export class FetchActivityTemplatesEffects {
    constructor(private actions$: Actions,
        private dataService: DataService,
        private store: Store<IAppState>) { }

    public fetchSaoOptions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchActivityTemplatesActions.ACTIVITY_TEMPLATES_REQUEST),
            exhaustMap(() => {
                return this.dataService.getAllActivityTemplates().pipe(
                    map(result => {
                        return new ActivityTemplatesRequestSuccess({ activityTeplates: result, activityTeplatesLoading: false, activityTeplatesRequestError: null });
                    }))
            }),
            catchError((err: Error) => {
                let error = err.message;
                this.store.dispatch(new ActivityTemplatesRequestSuccess({ activityTeplates: [], activityTeplatesLoading: false, activityTeplatesRequestError: error }));
                throw err;
            })
        )
    }
    );
}
