import { IField } from "src/app/models/osdu/field.model";
import { FetchFieldsAction, FetchFieldsActions } from "../actions/fetch-field.actions";

export interface IFieldsCacheState {
    fields: IField[],
    fieldsLoading: boolean,
    fieldsRequesterror: any
}

const initialState: IFieldsCacheState = {
    fields: [],
    fieldsLoading: false,
    fieldsRequesterror: null
}

export function fieldsReducer(state: IFieldsCacheState = initialState, action: FetchFieldsAction): IFieldsCacheState
{
    switch(action.type) {
        case FetchFieldsActions.FIELDS_REQUEST: {
            state = <IFieldsCacheState> {
                fields: [], fieldsLoading: true, fieldsRequesterror: null
            };
            return state;
        }
        case FetchFieldsActions.FIELDS_REQUEST_SUCCESS: {
            state = action.payload;
            return state;
        }
        case FetchFieldsActions.FIELDS_REQUEST_FAILED: {
            state = <IFieldsCacheState>{
                fields: [], 
                fieldsLoading: false, 
                fieldsRequesterror: action.payload.fieldsRequesterror
            };
            return state;
        }
        default: {
            return state;
        }
    }
}

