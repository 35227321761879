import { Action } from "@ngrx/store"
import { ISurveillanceDataState } from "../reducers/fetch-surveillance-data-reducer";

export enum FetchSurveillanceRecordsActions {
    SUREVEILLANCE_RECORDS_REQUEST = '[SURVEILLANCE_RECORDS] Request Surveillance Data',
    SUREVEILLANCE_RECORDS_SUCCESS = '[SURVEILLANCE_RECORDS] Surveillance Data Request - SUCCESS',
    SUREVEILLANCE_RECORDS_FAILED = '[SURVEILLANCE_RECORDS] Surveillance Data Request - FAILED'
}

export class RequestSurveillanceData implements Action {
    readonly type: string = FetchSurveillanceRecordsActions.SUREVEILLANCE_RECORDS_REQUEST;
    constructor(public payload: ISurveillanceDataState = <ISurveillanceDataState>{}) {}
}

export class SurvreillaceDataRequestSuccess implements Action {
    readonly type: string = FetchSurveillanceRecordsActions.SUREVEILLANCE_RECORDS_SUCCESS;
    constructor(public payload: ISurveillanceDataState) {}
}

export class SurvreillaceDataRequestFailed implements Action {
    readonly type: string = FetchSurveillanceRecordsActions.SUREVEILLANCE_RECORDS_FAILED;
    constructor(public payload: ISurveillanceDataState) {}
}

export type FetchSurveillanceDataActions 
= RequestSurveillanceData | SurvreillaceDataRequestSuccess | SurvreillaceDataRequestFailed