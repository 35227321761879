<div class="overlay" *ngIf="wellsLoading">
  <div>
    <mat-spinner [diameter]="75"></mat-spinner>
  </div>
  <div class="spinner-txt">Loading data...</div>
</div>
<div id="mapContainer">
  <div class="shapes-menu">
    <div class="shape-menu-inside">
      <div class="basin">
        <mat-slide-toggle (change)="onChangeBasin($event)" class="basin-toggle">Basins</mat-slide-toggle>
      </div>
      <div class="dev-area">
        <mat-slide-toggle (change)="onChangeDevAreas($event)" class="dev-area-toggle">Dev</mat-slide-toggle>
      </div>
      <div class="sub-dev-area">
        <mat-slide-toggle (change)="onChangeSubDevAreas($event)" class="sub-dev-area-toggle">SubDev</mat-slide-toggle>
      </div>
      <div class="nojv-area">
        <mat-slide-toggle (change)="onChangeNojvAreas($event)" class="nojv-toggle">NOJV</mat-slide-toggle>
      </div>
      <div class="pdev-area">
        <mat-slide-toggle (change)="onChangePdevAreas($event)" class="pdev-toggle">PDEV</mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="well-legend">
    <div class="legend-grid">
      <div class="cell1"><div class="circle-planned">&nbsp;</div></div>
      <div class="cell2">Planned</div>
      <div class="cell3"><div class="circle-acquired">&nbsp;</div></div>
      <div class="cell4">Acquired</div>
      <div class="cell5"><div class="circle-planned-acquired">&nbsp;</div></div>
      <div class="cell6">Planned and Acquired</div>
      </div>
  </div>
  <div class="spinner" *ngIf="mapIsLoading">
    <div>
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div class="spinner-txt">Loading map...</div>
  </div>
</div>
