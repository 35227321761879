import { ISurevillanceRecord } from "../models/osdu/sureveillance-record.model";
import { IBasin } from "../models/osdu/basin.model";
import { IField } from "../models/osdu/field.model";
import { ITargetFormation } from "../models/osdu/target-formation.model";
import { ISaoFlattenedRecordDataSource } from "../models/sao-record-flat-table.model";
import { flattenSaoRecords, formatTgtFormation } from "./helpers.util";
import { IActivityTemplate } from "../models/osdu/activity-template.model";

export class FilterParams {
  constructor(public selectedBasin: IBasin | undefined,
    public allFieldsOsduRecords: IField[],
    public selectedField: IField | undefined,
    public selectedSubFields: IField[],
    public selectedTargetFormations: ITargetFormation[],
    public selectedActivityTemplates: IActivityTemplate[],
    public filterByFiles: boolean,
    public filterByActivityType: string) {}
}

export class FilteringResult {
  constructor(
    public filteredRecords: ISurevillanceRecord[],
    public filteredFlattenedRecords: ISaoFlattenedRecordDataSource[]) {}
}

export class RecordsFilter {
  private saoRecordsData: ISurevillanceRecord[];
  private saoFlatttenedRecordsData: ISaoFlattenedRecordDataSource[];
  private activityTemplates: IActivityTemplate[];

  constructor(saoRecords: ISurevillanceRecord[],
              activityTemplates: IActivityTemplate[]) {
    this.saoRecordsData = saoRecords;
    this.activityTemplates = activityTemplates;
    this.saoFlatttenedRecordsData = [];
  }

  private getFilteredRecords(): FilteringResult {
    return new FilteringResult(this.saoRecordsData, this.saoFlatttenedRecordsData);
  }

  filter(filterParams: FilterParams
  ): FilteringResult {

    return this.filterByBasin(filterParams.selectedBasin, filterParams.allFieldsOsduRecords)
      .filterByDevArea(filterParams.selectedField)
      .filterBySubDevAreas(filterParams.selectedSubFields)
      .filterByTargetFormations(filterParams.selectedTargetFormations)
      .flattenToFilterActivities()
      .filterByActivityTemplates(filterParams.selectedActivityTemplates)
      .filterByAttachedFiles(filterParams.filterByFiles)
      .filterByActivityTypes(filterParams.filterByActivityType)
      .getFilteredRecords();
  }

  private filterByBasin(selectedBasin: IBasin | undefined, allFieldsOsduRecords: IField[]): this {
    if (selectedBasin === undefined) {
      return this;
    }
    else {
      let fields = allFieldsOsduRecords.filter(field => field.data.geoContexts[0]?.basinId == selectedBasin.id);
      let filteredData: ISurevillanceRecord[] = [];

      this.saoRecordsData.forEach(record => {
        if (fields.findIndex(devArea => devArea.data?.fieldName == record.data.developmentArea) > -1) {
          filteredData.push(record);
        }
      })

      this.saoRecordsData = filteredData;
    }
    return this;
  }

  private filterByDevArea(selectedField: IField | undefined): this {
    if (selectedField === undefined) {
      return this;
    }
    else {
      let filteredFieldOsduRecords = this.saoRecordsData.filter(record => record.data.developmentArea == selectedField.data?.fieldName);
      this.saoRecordsData = filteredFieldOsduRecords;
    }
    return this;
  }

  private filterBySubDevAreas(selectedSubFields: IField[]): this {
    if (selectedSubFields === undefined) {
      return this;
    }
    if (selectedSubFields.length > 0) {
      let selectedSubFieldsNames = selectedSubFields.map(field => field.data?.fieldName);
      let filteredSubFieldOsduRecords = this.saoRecordsData.filter((record) => selectedSubFieldsNames.indexOf(record.data.subDevelopmentArea) > -1);
      this.saoRecordsData = filteredSubFieldOsduRecords;
    }

    return this;
  }

  private filterByTargetFormations(selectedTargetFormations: ITargetFormation[]): this {
    if (selectedTargetFormations === undefined) {
      return this;
    }
    if (selectedTargetFormations.length > 0) {
      let selectedTargetFormationsNames = selectedTargetFormations.map(
        formation => formatTgtFormation(formation.data.Name));
      this.saoRecordsData = this.saoRecordsData.filter(record => selectedTargetFormationsNames.indexOf(record.data.targetFormation) > -1);
    }

    return this;
  }

  private filterByActivityTemplates(selectedSaoOptions: IActivityTemplate[]): this {
    if (selectedSaoOptions === undefined) {
      return this;
    }
    if (selectedSaoOptions.length > 0) {
      let selectedOptionsIds = selectedSaoOptions.map(option => option.id);
      this.saoRecordsData = this.saoRecordsData.filter(record => record.data.assignedSaoActivities?.some(activity => selectedOptionsIds.indexOf(activity.activityTemplateId) > -1));
      this.saoFlatttenedRecordsData = this.saoFlatttenedRecordsData.filter(record => selectedSaoOptions.find(activity => activity.data.name === record.data.activityTemplateName));
    }

    return this;
  }

  private filterByAttachedFiles(filterByFiles: boolean): this {
    if (filterByFiles) {
      this.saoRecordsData = this.saoRecordsData.filter(
        record => record.data.assignedSaoActivities.every(activity => activity.datasets.length === 0));

      this.saoFlatttenedRecordsData = this.saoFlatttenedRecordsData.filter(
        record => record.data.assignedSaoActivities.every(activity => activity.datasets.length === 0));
    }

    return this;
  }

  private flattenToFilterActivities(): this {
    this.saoFlatttenedRecordsData = flattenSaoRecords(this.saoRecordsData);

    return this;
  }

  private filterByActivityTypes(activityType: string): this {
    if (activityType === undefined || activityType === '') {
      return this;
    }

    this.saoFlatttenedRecordsData
      = this.saoFlatttenedRecordsData.filter(record => record.data.activityStatus === activityType);

    this.saoRecordsData
      = this.saoRecordsData.filter(record => record.data.assignedSaoActivities
                           .some(activity => activity.activityStatus === activityType));

    return this;
  }
}
