<div class="mat-elevation-z8 records-table">
  <mat-table [dataSource]="saoRecordsDataSource" matSort aria-describedby="SA&O records search results">
    <ng-container *ngFor="let column of recordsColumns" [matColumnDef]="column.columnDef">
      <mat-header-cell *matHeaderCellDef>
        {{column.header}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{column.cell(row)}}
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedRecordsColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedRecordsColumns;" (click)="onSaoRecordsRowClick(row, ClickSourceEnum.MAP)"
      [ngClass]="{'highlight-selected' : saoRecordsTableSelection.isSelected(row)}"></mat-row>
  </mat-table>
  <mat-paginator #recordPaginator [length]="resultsLength" [pageSize]="10"></mat-paginator>
</div>
