import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
import { FetchFormationsActions, FormationsRequestFailed, FormationsRequestSuccess } from "../actions/fetch-formations.actions";
import { IAppState } from "../reducers";

@Injectable()
export class FetchFormationsEffects {
    constructor(private actions$: Actions,
        private dataService: DataService,
        private store: Store<IAppState>) { }

    public fetchFormations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FetchFormationsActions.FORMATIONS_REQUEST),
            exhaustMap(() => {
                return this.dataService.getAllGeologicalFormations().pipe(
                    map(result => {
                        return new FormationsRequestSuccess({ formations: result, formationsLoading: false, formationsRequestError: null });
                    }))
            }),
            catchError((err: Error) => {
                let error = err.message;
                this.store.dispatch(new FormationsRequestFailed({ formations: [], formationsLoading: false, formationsRequestError: error }));
                throw err;
            })
        )
    }
    );
}