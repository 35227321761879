import { Injectable } from "@angular/core"
import { HttpService } from "./http.service"
import { IDurableFunctionStatus } from "../models/durable-functions/durable-function-status.model";
import { Observable, catchError, firstValueFrom, throwError, timeout } from "rxjs";
import { IDurableFunctionResponse } from "../models/durable-functions/durable-function-response.model";

@Injectable({
  providedIn: 'root'
})
export class DurableFunctionResponseService {


  private sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  constructor(private httpService: HttpService) { }

  public async runDurableFunctionAsync(payload: any, durableFunctionUrl: string): Promise<IDurableFunctionStatus> {
    let status: string | undefined = "Pending";
    let customStatus: string | undefined = "Pending";
    let uploadResponse: IDurableFunctionStatus = <IDurableFunctionStatus>{
      instanceId: undefined,
      runtimeStatus: undefined,
      customStatus: undefined
    };
    let statusUrl: IDurableFunctionResponse = await this.getDurableFunctionResponse(payload, durableFunctionUrl);

    if (statusUrl.statusQueryGetUri !== undefined) {
      while (status === 'Pending' || status === 'Running' && customStatus != 'Failed') {
        let uploadStatus: IDurableFunctionStatus = await this.getDurableFunctionStatus(statusUrl.statusQueryGetUri);
        status = uploadStatus?.runtimeStatus;
        customStatus = uploadStatus?.customStatus;

        if (uploadStatus && (uploadStatus.runtimeStatus === 'Completed' || uploadStatus.runtimeStatus == 'Failed' || uploadStatus.customStatus == 'Failed')) {
            uploadResponse = uploadStatus;
        }

        if(status === 'Failed'){
          console.error(`${uploadStatus.instanceId}: Durable function status - ${status}`);
        } else {
          console.log(`${uploadStatus.instanceId}: Durable function status - ${status}`);
        }

        if(customStatus === 'Failed'){
          console.error(`${uploadStatus.instanceId}: Durable function custom status - ${customStatus}`);
        }

        await this.sleep(3000);
      }
    }

    return uploadResponse;
  }

  private async getDurableFunctionStatus(statusUrl: string): Promise<IDurableFunctionStatus> {
    let response = await firstValueFrom<IDurableFunctionStatus>(
      this.httpService.httpGet<IDurableFunctionStatus>(statusUrl)
        .pipe(
          timeout(30000),
          catchError(e => {
            console.error(e);
            return throwError(() => new Error(e));
          })
        )
    );

    return response;
  }

  private async getDurableFunctionResponse(payload: any, durableFunctionUrl: string): Promise<IDurableFunctionResponse> {
    let response = await firstValueFrom<IDurableFunctionResponse>(
      this.triggerDurableFunction(payload, durableFunctionUrl)
        .pipe(
          timeout(30000),
          catchError(e => {
            console.error(e);
            return throwError(() => new Error(e));
          })
        )
    );


    return response;
  }

  private triggerDurableFunction(payload: any, durableFunctionUrl: string): Observable<IDurableFunctionResponse> {
    return this.httpService.httpPost<IDurableFunctionResponse>(durableFunctionUrl, payload);
  }
}
