<div class="container">
  <div class="filter"><sao-filter (filterData)="onFilterData($event)"></sao-filter></div>
  <div class="chart"><sao-chart [recordsState]="recordsState" (reloadMap)="onReloadMap($event)"></sao-chart></div>
  <div class="map">
    <sao-azure-maps [recordsState]="recordsState" [recordData]="recordSource" (clickedWell)="onMapWellClick($event)"
      [reloadMapData]="reloadMap">
    </sao-azure-maps>
  </div>
  <div class="sao-table">
    <sao-table [recordsState]="recordsState" (recordData)="onTableRowClick($event)" [clickedWellData]="mapClickedWell">
    </sao-table>
  </div>
</div>
