import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
import { IAppState } from "../reducers";
import { AzureMapsSettingsRequestFailed, AzureMapsSettingsRequestSuccess, FetchAzureMapsSettingsActions } from "../actions/fetch-azure-maps-settings.actions";
import { IAzureMapsSettings } from "src/app/models/maps/azure-maps-settings.model";
import { environment } from "src/environments/environment";
import { IAzureMapsPayload } from "src/app/models/payloads/azure-maps-payload.model";

@Injectable()
export class FetchAzureMapsSettingsEffects {
  constructor(private actions$: Actions,
    private dataService: DataService,
    private store: Store<IAppState>) { }

  public fetchAzureMapsSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FetchAzureMapsSettingsActions.AZURE_MAPS_SETTINGS_REQUEST),
      exhaustMap(() => {
        return this.dataService.getAzureMapsSettings(<IAzureMapsPayload>{environment: environment.environmentName.split('-')[0]}).pipe(
          map(result => {
            return new AzureMapsSettingsRequestSuccess({
              azureMapsSettings: result,
              azureMapsSettingsLoading: false,
              azureMapsSettingsRequestError: null
            });
          }))
      }),
      catchError((err: Error) => {
        let error = err.message;
        this.store.dispatch(new AzureMapsSettingsRequestFailed({
          azureMapsSettings: <IAzureMapsSettings>{},
          azureMapsSettingsLoading: false,
          azureMapsSettingsRequestError: error
        }));
        throw err;
      })
    )
  });
}
