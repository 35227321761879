export enum EnvironmentNames {
    LOCAL = 'LOCAL',
    DEV = 'DEV',
    DEV1 = 'DEV1-ADME',
    TEST = 'TEST',
    TEST1 = 'TEST1-ADME',
    STAGE = 'STAGE',
    STAGE1 = 'STAGE1-ADME',
    STAGE2 = 'STAGE2-ADME',
    PROD = 'PROD',
    PROD1 = 'PROD1-ADME'
}