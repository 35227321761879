import { Injectable, OnDestroy } from "@angular/core";
import { IAppState } from "../store/reducers";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Store, select } from "@ngrx/store";
import { DatePipe } from "@angular/common";
import { ISurevillanceRecord } from "../models/osdu/sureveillance-record.model";

export interface ISaoRecordDataSource {
  records: ISurevillanceRecord[];
  loading: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SaoRecordsDataSourceService implements OnDestroy {

  private saoRecordsDataSourceSubject: BehaviorSubject<ISaoRecordDataSource>
    = new BehaviorSubject<ISaoRecordDataSource>(<ISaoRecordDataSource>{
      records: [],
      loading: true
    });

  private subscriptions: Subscription[] = [];

  constructor(private store: Store<IAppState>) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getSaoRecordDataSource(): Observable<ISaoRecordDataSource> {
    this.getSaoRecordsDataSource();
    return this.saoRecordsDataSourceSubject.asObservable();
  }

  private formatDate(dateInput: string): string | null {
    if (!isNaN(Date.parse(dateInput))) {
      return new Date(dateInput).toISOString().split('T')[0];
    };
    return null;
  }

  getSaoRecordsDataSource(): void {
    let surveillanceDataState$ = this.store.pipe(select('surveillanceDataState'));

    let sub = surveillanceDataState$.subscribe({
      next: (surveillanceDataState) => {
        if (!surveillanceDataState.dataLoading) {
          let recordsDataSource = surveillanceDataState.data.map(record => {
            return <ISurevillanceRecord>{
              ...record,
              data: {
                ...record.data,
                fracStartDate: this.formatDate(record?.data?.fracStartDate),
                targetFormation: record?.data?.targetFormation?.split(' ')[0],
                spudDate: this.formatDate(record?.data?.spudDate),
                forecastPopDate: this.formatDate(record?.data?.forecastPopDate)
              }
            }
          });
          this.saoRecordsDataSourceSubject.next(<ISaoRecordDataSource>{
            records: recordsDataSource,
            loading: surveillanceDataState.dataLoading
          });
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        console.log('SAO Records Data Source fetch completed');
      }
    });

    this.subscriptions.push(sub);
  }
}
