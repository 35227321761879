import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IMenuTab } from './components/navigation/navigation.model';
import { AppService } from './services/app.service';
import { RequestBasins } from './store/actions/fetch-basins.actions';
import { RequestFormations } from './store/actions/fetch-formations.actions';
import { RequestTargetFormations } from './store/actions/fetch-tgt-formations.actions';
import { IAppState } from './store/reducers';
import { LoginState } from './store/reducers/login.reducer';
import { RequestAzureMapsSettings } from './store/actions/fetch-azure-maps-settings.actions';
import { RequestSurveillanceData } from './store/actions/fetch-surveillance-data-action';
import { RequestActivityTemplates } from './store/actions/fetch-activity-templates.actions';

@Component({
  selector: 'sao-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  siteTitle: string = "Surveillance, Analysis and Optimization SOR";

  private loginState$: Observable<LoginState>;

  public readonly tabs: IMenuTab[] = [
    {
      activeLink: 'home',
      routerLink: ['home'],
      displayText: 'home',
      children: []
    },
    {
      activeLink: '',
      routerLink: [],
      displayText: 'records',
      children: [
        {
          activeLink: 'records',
          routerLink: ['records'],
          displayText: 'search records',
        },
        {
          activeLink: 'add',
          routerLink: ['add'],
          displayText: 'add records',
        }
      ]
    },
    {
      activeLink: 'dashboard',
      routerLink: ['dashboard'],
      displayText: 'dashboard',
      children: []
    },
    {
      activeLink: 'about',
      routerLink: ['about'],
      displayText: 'about',
      children: []
    }
  ]

  constructor(private router: Router,
    public AppService: AppService,
    private store: Store<IAppState>) {
      this.loginState$ = this.store.pipe(select('loginState'));
  }

  ngOnInit(): void {
    this.router.navigate(['home']);
    let sub = this.loginState$.subscribe(loginState => {
      if(loginState == LoginState.LOGGEDIN){
        this.store.dispatch(new RequestAzureMapsSettings());
        this.store.dispatch(new RequestBasins());
        this.store.dispatch(new RequestSurveillanceData());
        this.store.dispatch(new RequestFormations());
        this.store.dispatch(new RequestTargetFormations());
        this.store.dispatch(new RequestActivityTemplates());
      }
    })
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
