import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  ShowHeader: BehaviorSubject<boolean>;
  ShowFooter: BehaviorSubject<boolean>;

  constructor() {
    this.ShowFooter = new BehaviorSubject<boolean>(true);
    this.ShowHeader = new BehaviorSubject<boolean>(true);
   }
}
